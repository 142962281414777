import { useMemo } from 'react';
import { getOrdersTotalPriceBreakdown } from 'state/orders';
import { useAppSelector } from 'state/store';

export const useHasToPay = () => {
    const totalPriceBreakdown = useAppSelector(getOrdersTotalPriceBreakdown);

    const hasToPay = useMemo(() => {
        return !!totalPriceBreakdown && totalPriceBreakdown.totalInclTaxWithDiscount > 0;
    }, [totalPriceBreakdown]);
    return { hasToPay };
};
