import { useAppSelector } from 'state/store';
import { getAccessToken, getCustomerId } from 'state/customer/customer.slice';
import { useGetOrdersHistoryInfiniteQuery } from 'services/order/order.endpoints';
import { OmnichannelOrder } from 'services/order/order.type';
import { useMemo } from 'react';

export type OrdersHistory = {
    data?: OmnichannelOrder[];
    isLoading: boolean;
    isFetching: boolean;
    isFetchingNextPage: boolean;
    hasNextPage: boolean;
    fetchNextPage: () => void;
};

export const useGetOrdersHistory = (): OrdersHistory => {
    const accessToken = useAppSelector(getAccessToken);
    const customerId = useAppSelector(getCustomerId);

    const {
        data: infiniteData,
        isLoading,
        isFetching,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useGetOrdersHistoryInfiniteQuery(
        {
            accessToken: accessToken!,
            customerId: customerId?.toString() || '',
        },
        { skip: !accessToken || !customerId },
    );

    const data = useMemo(
        () => infiniteData?.pages.map((page) => page.omnichannelOrders).flat(),
        [infiniteData],
    );

    return { data, isLoading, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage };
};
