import { createSlice, PayloadAction, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { ConsumptionMode } from 'services/consumptionMode/consumptionMode.type';
import { RootState } from '../store';

export const consumptionModeListSliceName = 'consumptionModeList';

export type ConsumptionModeListState = Record<number, ConsumptionMode[]> | null;
const initialState: ConsumptionModeListState = null;

export const consumptionModeListSlice = createSlice<
    ConsumptionModeListState,
    SliceCaseReducers<ConsumptionModeListState>,
    string,
    SliceSelectors<ConsumptionModeListState>
>({
    name: consumptionModeListSliceName,
    initialState,
    reducers: {
        updateConsumptionModeList: (state, action: PayloadAction<ConsumptionModeListState>) => ({
            ...state,
            ...action.payload,
        }),
    },
});

export const getConsumptionModeList = (state: RootState): ConsumptionModeListState =>
    state.consumptionModeList;

export const { updateConsumptionModeList } = consumptionModeListSlice.actions;
