import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { QuantityPicker, Text } from 'components';
import { CartProduct } from 'services/order/order.type';
import { UpdateProductQuantityInCartPayload } from 'hooks/useCartHandler';
import { AppState } from 'state/app';
import {
    CartItemContainer,
    FlexContainer,
    ProductImage,
    ProductStep,
    ProductSteps,
    QuantityPickerContainer,
    Toggle,
} from './CartItem.style';
import { computeTitle } from './CartItem.utils';

const MAX_SHOWING_STEPS = 3;

export type CartItemProps = CartProduct & {
    currency: string;
    onValueChange?: (payload: UpdateProductQuantityInCartPayload) => void;
    isLoading?: boolean;
    noBlurry?: boolean;
    debouncingQuantity?: AppState['debouncingQuantity'];
    isDebouncing?: boolean;
};

export const CartItem: React.FunctionComponent<CartItemProps> = ({
    productCartId,
    name,
    price,
    unitPrice,
    quantity,
    steps,
    currency,
    onValueChange,
    imageUrl,
    restaurantId,
    menuId,
    isLoading,
    noBlurry,
    debouncingQuantity,
    isDebouncing,
}) => {
    const currentQuantity =
        debouncingQuantity && productCartId && typeof debouncingQuantity[productCartId] === 'number'
            ? debouncingQuantity[productCartId]
            : quantity;

    const flattenedSteps = steps.flatMap((step) => [
        step,
        ...(step.nestedSteps ? step.nestedSteps : []),
    ]);

    const [blurry, setBlurry] = useState(flattenedSteps.length > 3);

    const handleToggle = () => {
        flattenedSteps.length > MAX_SHOWING_STEPS && setBlurry(!blurry);
    };

    const handleChangeQuantity = (newQuantity: number) => {
        if (newQuantity === -1 || !onValueChange || !productCartId || !restaurantId || !menuId)
            return;
        onValueChange({ productCartId, quantity: newQuantity, restaurantId, menuId });
    };

    const computedTitle = computeTitle({
        name,
        unitPrice,
        quantity,
        currency,
        hasAction: !!onValueChange,
        isComposite: steps.length > 0,
    });

    return (
        <CartItemContainer data-testid="cart-item-container">
            <FlexContainer>
                <Text
                    text={computedTitle}
                    noTranslation
                    type={2}
                    weight="Bold"
                    data-testid="cart-product-name"
                />
                {imageUrl && (
                    <ProductImage
                        data-testid="product-image"
                        style={{ backgroundImage: `url("${imageUrl}")` }}
                    />
                )}
            </FlexContainer>

            {steps.length > 0 && (
                <FlexContainer onClick={handleToggle} data-testid="cart-steps-container">
                    <ProductSteps blurry={!noBlurry && blurry}>
                        {steps.map(({ value, nestedSteps }) => (
                            <ProductStep key={uuidv4()}>
                                <Text noTranslation text={value} weight="Regular" type={3} />
                                {nestedSteps && (
                                    <ProductSteps>
                                        <ProductStep key={uuidv4()}>
                                            <Text
                                                noTranslation
                                                text={nestedSteps}
                                                weight="Regular"
                                                type={3}
                                            />
                                        </ProductStep>
                                    </ProductSteps>
                                )}
                            </ProductStep>
                        ))}
                    </ProductSteps>
                    {flattenedSteps.length > MAX_SHOWING_STEPS && !noBlurry && (
                        <Toggle rotateDown={blurry} data-testid="cart-steps-toggle" />
                    )}
                </FlexContainer>
            )}

            <QuantityPickerContainer>
                {onValueChange ? (
                    <QuantityPicker
                        value={currentQuantity}
                        onValueChange={handleChangeQuantity}
                        disabled={isLoading}
                        isLoading={isLoading}
                        variant="secondary"
                        isDebouncing={isDebouncing}
                    />
                ) : (
                    <span />
                )}
                <Text
                    text={price}
                    isCurrency
                    currency={currency}
                    type={2}
                    weight="Bold"
                    data-testid="cart-product-price"
                />
            </QuantityPickerContainer>
        </CartItemContainer>
    );
};
