import styled from '@emotion/styled';
import { SvyBadge, SvyIcon } from '@innovorder/savory';
import { Text } from 'components';

export const StyledOrderListItem = styled.div`
    border: 1px solid ${({ theme }): string => theme.base.colors.secondary[300]};
    padding: ${({ theme }) => theme.base.spacing[2]};
    margin: ${({ theme }) => `${theme.base.spacing[2]} ${theme.base.spacing[3]}`};
    border-radius: ${({ theme }) => theme.base.radius.default};
    display: flex;
    justify-content: space-between;
`;

export const OrderDetailsContainer = styled.div``;

export const ActionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: min-content;
`;

export const StyledDate = styled(Text)`
    color: ${({ theme }): string => theme.base.colors.secondary[600]};
`;

export const OrderPrice = styled(SvyBadge)`
    margin-top: ${({ theme }) => theme.base.spacing[1]};
`;

export const UnavailableDetails = styled(SvyBadge)`
    font-weight: normal !important;
`;

export const StyledSvyIcon = styled(SvyIcon)`
    font-size: 24px;
`;
