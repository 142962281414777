import { v4 as uuidv4 } from 'uuid';
import { useLiveOrdersStatus } from 'hooks/useLiveOrdersStatus';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';
import { useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from 'state/store';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import {
    getByAccessor,
    getCurrentConsumptionModeType,
    getRestaurantId,
    updateAppState,
} from 'state/app';
import {
    getAskForTableNumber,
    getBrandOptionStatus,
    getWebsiteMetaTitle,
    updateBrand,
} from 'state/brand';
import { updateRestaurantSchedules } from 'state/restaurantSchedules';
import { useGetWeborderingConfigurationQuery } from 'services/brand/brand.endpoints';
import { executeNowAndEveryQuarterHour } from 'utils/refresh';
import { getOpeningDetailsByRestaurants } from 'utils/restaurant/schedule';
import { makeTheme } from 'utils/style';
import { detectIncognito } from 'detectincognitojs';
import { getRouteAttributesFromPath } from 'utils/routes';
import { useTranslation } from 'react-i18next';
import { useCustomFlush } from 'hooks/useCustomFlush';
import { ConsumptionModeType } from '@innovorder/order_detail';
import { routes } from './routes';

type DetectIncognitoType = {
    isPrivate: boolean;
    browserName: string;
};

export const useAppVM = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryTableId = searchParams.get('tableId');
    const { value: localStorage, setInLocalStorage } = useLocalStorage<{
        tableId: string;
        anonymousId: string;
    }>('webordering-v2');
    const isFoodCourt = useAppSelector(getBrandOptionStatus('FOOD_COURT_MODE'));
    const { flushCart } = useCustomFlush();

    const { pathname } = useLocation();
    const matchedRoute = getRouteAttributesFromPath(pathname, routes);
    const websiteMetaTitle = useAppSelector(getWebsiteMetaTitle);
    const titleAccessorValue = useAppSelector(getByAccessor(matchedRoute?.titleAccessor));
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const restaurantId = useAppSelector(getRestaurantId);

    const askForTableNumber = useAppSelector(getAskForTableNumber(restaurantId));

    const title = useMemo(() => {
        const titles: string[] = [];
        if (matchedRoute) {
            if (titleAccessorValue) titles.push(titleAccessorValue);
            titles.push(t(matchedRoute.name));
        }
        if (websiteMetaTitle) {
            titles.push(websiteMetaTitle);
        }
        return titles.join(' - ');
    }, [titleAccessorValue, matchedRoute, websiteMetaTitle, t]);

    const tableId = queryTableId ?? localStorage?.tableId;
    const anonymousId = localStorage?.anonymousId ?? uuidv4();

    const {
        data: weborderingConfiguration,
        isError,
        isLoading,
        error,
    } = useGetWeborderingConfigurationQuery({
        brandHash: params.brandHash ?? 'no-brand',
    });

    useEffect(() => {
        detectIncognito()
            .then(({ isPrivate: isIncognitoMode }: DetectIncognitoType) => {
                dispatch(
                    updateAppState({
                        isIncognitoMode,
                    }),
                );
            })
            .catch(() =>
                dispatch(
                    updateAppState({
                        isIncognitoMode: false,
                    }),
                ),
            );
    }, [dispatch]);

    useEffect(() => {
        dispatch(updateBrand(weborderingConfiguration || null));

        const { clearTimers } = executeNowAndEveryQuarterHour(() => {
            dispatch(
                updateRestaurantSchedules(getOpeningDetailsByRestaurants(weborderingConfiguration)),
            );
        });

        return clearTimers;
    }, [dispatch, weborderingConfiguration]);

    useEffect(() => {
        setInLocalStorage({ tableId: tableId ?? 'null', anonymousId });
        dispatch(
            updateAppState({
                tableId: tableId && tableId !== 'null' ? tableId : null,
                isFoodCourt,
                anonymousId,
            }),
        );
    }, [tableId, isFoodCourt, anonymousId, dispatch, setInLocalStorage]);

    useEffect(() => {
        if (tableId && tableId !== 'null') {
            setSearchParams(
                (queryParams) => {
                    queryParams.set('tableId', tableId);
                    return queryParams;
                },
                { replace: true },
            );
        }
    }, [searchParams, setSearchParams, tableId]);

    useEffect(() => {
        if (!!queryTableId && Number(queryTableId) !== Number(localStorage?.tableId)) {
            flushCart();
        }
    }, [queryTableId, localStorage, flushCart]);

    useEffect(() => {
        dispatch(
            updateAppState({
                needManualTableId:
                    askForTableNumber &&
                    tableId === 'null' &&
                    currentConsumptionModeType === ConsumptionModeType.MODE_SIT_IN,
            }),
        );
    }, [askForTableNumber, tableId, currentConsumptionModeType, dispatch]);

    useLiveOrdersStatus();

    const apiTheme = weborderingConfiguration?.module_style_styles.find(
        ({ is_enabled }) => is_enabled,
    );

    const { theme, themeCss } = makeTheme(apiTheme);

    return {
        title,
        theme,
        themeCss,
        error,
        isError,
        isLoading,
        isInitialized: isFoodCourt !== null,
    };
};
