import { useAuthenticate } from 'hooks/useAuthenticate/useAuthenticate';
import { TranslationKey } from 'locales/fr';
import { useState, useCallback, ChangeEvent, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { updateAppState } from 'state/app';
import { getBrandId, getBrandOptionStatus } from 'state/brand';
import { useAppDispatch, useAppSelector } from 'state/store';
import { usePutForgottenPasswordMutation } from 'services/customer/customer.endpoints';
import { convertModeFromUrl } from 'utils/consumptionModes';

const MIN_PASSWORD_LENGTH = 8;
const REGEX_SECURED_PASSWORD =
    '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\[\\]{}€£?_;.:,\\-+=\'"~<>]).{12,50}$';

export const useResetPasswordVM = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const restaurantId = searchParams.get('restaurantId');
    const currentConsumptionModeType = convertModeFromUrl(
        searchParams.get('consumptionMode') || undefined,
    );

    useEffect(() => {
        dispatch(updateAppState({ restaurantId, currentConsumptionModeType }));
    }, [restaurantId, currentConsumptionModeType, dispatch]);

    const { login } = useAuthenticate();

    const brandId = useSelector(getBrandId);
    const isAdvancedSecurityRulesActivated = useAppSelector(
        getBrandOptionStatus('ADVANCED_SECURITY_RULES'),
    );

    const [password, setPassword] = useState<string>();
    const [isPasswordTouched, setIsPasswordTouched] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const [isConfirmPasswordTouched, setIsConfirmPasswordTouched] = useState<boolean>(false);
    const [isLoginCalled, setIsLoginCalled] = useState<boolean>(false);

    const [putForgottenPassword, { data, isLoading, isSuccess, isError }] =
        usePutForgottenPasswordMutation();

    useEffect(() => {
        if (isSuccess && data && data.email && password && !isLoginCalled) {
            setIsLoginCalled(true);
            login({ username: data?.email, password });
        }
    }, [isSuccess, data, password, login, isLoginCalled]);

    const handleUpdatePassword = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
            setIsPasswordTouched(true);
        },
        [setPassword],
    );

    const handleUpdateConfirmPassword = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(event.target.value);
            setIsConfirmPasswordTouched(true);
        },
        [setConfirmPassword],
    );

    const isPasswordValid = useMemo(() => {
        if (!isPasswordTouched) return true;
        if (!password) return false;
        if (!isAdvancedSecurityRulesActivated && password.length >= MIN_PASSWORD_LENGTH) {
            return true;
        }

        if (!password.match(RegExp(REGEX_SECURED_PASSWORD))) {
            return false;
        }
        return true;
    }, [isPasswordTouched, password, isAdvancedSecurityRulesActivated]);

    const arePasswordsIdentical = useMemo(() => {
        if (!isConfirmPasswordTouched) return true;
        if (!password || !confirmPassword) return false;
        return password === confirmPassword;
    }, [isConfirmPasswordTouched, password, confirmPassword]);

    const passwordValidationError: TranslationKey = useMemo(() => {
        if (!isAdvancedSecurityRulesActivated) {
            return 'reset_password_page.error_format_8';
        }
        return 'reset_password_page.error_format_12';
    }, [isAdvancedSecurityRulesActivated]);

    const handleSubmit = useCallback(() => {
        if (
            !brandId ||
            !password ||
            !confirmPassword ||
            !params.token ||
            !params.customerId ||
            isLoading
        )
            return;

        putForgottenPassword({
            brandId,
            newPassword: password,
            token: params.token,
            customerId: params.customerId,
        });
    }, [
        brandId,
        password,
        confirmPassword,
        params.token,
        params.customerId,
        isLoading,
        putForgottenPassword,
    ]);

    return {
        password,
        confirmPassword,
        handleUpdatePassword,
        handleUpdateConfirmPassword,
        isPasswordValid,
        passwordValidationError,
        arePasswordsIdentical,
        canSubmit:
            isPasswordValid &&
            arePasswordsIdentical &&
            isPasswordTouched &&
            isConfirmPasswordTouched,
        handleSubmit,
        isLoading,
        isError,
    };
};
