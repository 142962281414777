import { worker } from 'core/test/msw/browser';
import React from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ENV } from 'utils/env';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ROOT_PATH } from 'utils/routes';
import App from './core/App';
import { persistor, store } from './state/store';

const container = document.getElementById('root')!;
const root = createRoot(container);

if (ENV.APP_IN_MEMORY) {
    worker.start();
}

const ServiceTemporaryUnavailable: React.FunctionComponent = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}
    >
        Service temporary unavailable
    </div>
);

const AppWithLDProvider = withLDProvider({
    clientSideID: ENV.LAUNCH_DARKLY_CLIENT_ID,
    context: {
        kind: 'user',
        key: 'anonymous-cat-user',
    },
    options: {
        bootstrap: 'localStorage',
    },
    reactOptions: {
        useCamelCaseFlagKeys: true,
    },
    deferInitialization: false,
})(App);

const router = createBrowserRouter([
    { path: `${ROOT_PATH}/*`, element: <AppWithLDProvider /> },
    { path: '*', element: <ServiceTemporaryUnavailable /> },
]);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <RouterProvider router={router} />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
);
