import i18n from 'core/InitialiseThirdParties/i18n';
import { MenuState } from 'state/menu';
import {
    MissingSteps,
    CompositeProduct,
    CurrentPath,
    CompositeStep,
} from 'state/currentCompositeProduct';
import { HashMappedStepProduct, ProductStepRelation } from 'services/menu/menu.type';
import { computePrice } from 'utils/price';
import { stepPriceSorting } from './ProductSteps/ProductSteps.utils';

export const UNDEFINED_PRODUCT_ID = 0; // productId is indexed from 1 so 0 is undefined.

export const parseProductId = (param?: string | null): number => {
    if (!param) {
        return UNDEFINED_PRODUCT_ID;
    }

    const parsed = parseInt(param, 10);

    return Number.isNaN(parsed) ? UNDEFINED_PRODUCT_ID : parsed;
};

export const computeProductSteps = (
    productSteps: ProductStepRelation[] | undefined,
): ProductStepRelation[] => {
    if (!productSteps) {
        return [];
    }

    return productSteps.sort((a, b) => {
        if (b.product_step.position !== null && a.product_step.position !== null) {
            return a.product_step.position - b.product_step.position;
        }

        return 0;
    });
};

const getSortedAndSelectedProduct = (
    step: CompositeStep,
    stepProducts: HashMappedStepProduct[],
    isWeightStrategyEnabled: boolean,
) => {
    const selectedProducts = Object.keys(step);

    return stepProducts
        ?.filter((p) => selectedProducts.includes(String(p.productId)))
        .sort(stepPriceSorting(isWeightStrategyEnabled));
};

// Compute step price based on excessPrice or price, depending if maxChoice step
// as been reached or not.
// https://github.com/InnovOrder/monorepo/blob/dd993eb1/packages/angularcore/assets/core/ordering/product/product.factory.js#L91-L102
const computeStepAmount = (
    selectedQuantity: number,
    exceeded: number,
    price?: number,
    excessPrice?: number,
) => {
    if (price === undefined) return 0;

    if (excessPrice && exceeded > 0) {
        if (exceeded < selectedQuantity) {
            return excessPrice * exceeded + price * (selectedQuantity - exceeded);
        }
        return excessPrice * selectedQuantity;
    }

    return price * selectedQuantity;
};

export const computeStepsAmount = (
    currentProduct: CompositeProduct | null,
    menu: MenuState,
): number => {
    let totalStepsAmount = 0;

    if (!currentProduct || !currentProduct.steps) {
        return totalStepsAmount;
    }

    Object.entries(currentProduct.steps).forEach(([stepId, step]) => {
        const menuStep = menu?.steps?.[Number(stepId)];
        if (!menuStep) return;

        const { stepProduct: stepProducts, isWeightStrategyEnabled, maxChoice } = menuStep;

        const sortedProducts = getSortedAndSelectedProduct(
            step,
            stepProducts,
            isWeightStrategyEnabled,
        );

        let quantity = 0;
        sortedProducts?.forEach((menuProduct) => {
            const product = step[menuProduct.productId];
            totalStepsAmount += computeStepsAmount(product, menu);

            const stepProduct = stepProducts?.find(
                (prod) => prod.productId === Number(menuProduct.productId),
            );

            quantity += isWeightStrategyEnabled
                ? menuProduct.step_product.weight * product.quantity
                : product.quantity;

            let quantityOverflow = maxChoice ? quantity - maxChoice : 0;
            if (isWeightStrategyEnabled) {
                quantityOverflow = Math.ceil(quantityOverflow / menuProduct.step_product.weight);
            }

            const stepAmount = computeStepAmount(
                product.quantity,
                quantityOverflow,
                stepProduct?.step_product.price,
                stepProduct?.step_product.exceedPrice,
            );
            totalStepsAmount += stepAmount;
        });
    });

    return totalStepsAmount;
};

export const computeTotalAmount = (
    price: number | undefined,
    quantity: number | undefined,
    currency: string | undefined,
    currentProduct: CompositeProduct | null,
    menu: MenuState,
): string => {
    if (!quantity || !currency || price === undefined) {
        return '';
    }

    const stepsAmount = computeStepsAmount(currentProduct, menu);

    return computePrice(i18n, (price + stepsAmount) * quantity, currency);
};

const checkIfCurrentStepHasMissingSteps = (
    currentProduct: CompositeProduct,
    productSteps: ProductStepRelation[],
): boolean => productSteps.some((step) => !!computeCurrentMissingStepsCount(step, currentProduct));

const checkIfNestedStepHaveMissingSteps = (
    missingSteps: MissingSteps | null,
    isNested: boolean,
): boolean =>
    !!(
        !isNested &&
        missingSteps &&
        Object.values(missingSteps).some((step) => Object.values(step).some((value) => value > 0))
    );

export const computeIsButtonDisabled = (
    currentProduct: CompositeProduct | null,
    productSteps: ProductStepRelation[] | undefined,
    missingSteps: MissingSteps | null,
    isNested: boolean,
): boolean => {
    if (!currentProduct || !productSteps) {
        return false;
    }

    const currentStepHasMissingSteps = checkIfCurrentStepHasMissingSteps(
        currentProduct,
        productSteps,
    );

    if (currentStepHasMissingSteps) {
        return true;
    }

    return checkIfNestedStepHaveMissingSteps(missingSteps, isNested);
};

const computeCurrentMissingStepsCount = (
    step: ProductStepRelation,
    currentProduct: CompositeProduct,
): number => {
    if (step.minChoice < 1) {
        return 0;
    }

    const currentStep = currentProduct.steps?.[step.stepId];

    if (!currentStep) {
        return step.minChoice;
    }

    const missingItemsCount = Object.values(currentStep).reduce(
        (result, { quantity }) => result + quantity,
        0,
    );

    if (missingItemsCount) {
        return Math.max(0, step.minChoice - missingItemsCount);
    }

    return 0;
};

const computeChildrenMissingStepsCount = (
    step: ProductStepRelation,
    missingSteps: MissingSteps | null,
): number => {
    const currentMissingSteps = missingSteps?.[step?.stepId];

    if (currentMissingSteps) {
        const childrenMissingItemCount = Object.values(currentMissingSteps).reduce(
            (result, count) => result + count,
            0,
        );
        return childrenMissingItemCount;
    }

    return 0;
};

export const computeMissingSteps = (
    currentPath: CurrentPath | null,
    currentProduct: CompositeProduct | null,
    productSteps: ProductStepRelation[] | undefined,
    missingSteps: MissingSteps | null,
): MissingSteps => {
    const newMissingSteps: MissingSteps = missingSteps ? { ...missingSteps } : {};

    if (!currentPath || currentPath.length < 3 || !currentProduct || !productSteps) {
        return newMissingSteps;
    }

    const parentStepId = currentPath[currentPath.length - 2];
    const parentProductId = currentPath[currentPath.length - 1];

    newMissingSteps[parentStepId] = { [parentProductId]: 0 };

    productSteps.forEach((step) => {
        newMissingSteps[parentStepId][parentProductId] += computeCurrentMissingStepsCount(
            step,
            currentProduct,
        );

        newMissingSteps[parentStepId][parentProductId] += computeChildrenMissingStepsCount(
            step,
            newMissingSteps,
        );
    });

    return newMissingSteps;
};
