import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'state/store';
import { getOrdersErrors, resetOrdersErrors } from 'state/ordersErrors/ordersErrors.slice';

import { useMenuHandler } from 'hooks/useMenuHandler';
import { TranslationKey } from 'locales/fr';
import { getStepProductsName } from 'utils/errors/errorsParser';

export const useProductErrorModalVM = (
    productId: number | undefined,
    displayStepProductName = false,
) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const ordersErrors = useAppSelector(getOrdersErrors);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [productsName, setProductsName] = useState<string>(
        t('modal_product_error.body_generic_product_name'),
    );
    const [errorMessage, setErrorMessage] = useState<TranslationKey>(
        'modal_product_error.body_removed_product',
    );

    const displayModal = useCallback(() => setIsOpen(true), []);
    const hideModal = useCallback(() => setIsOpen(false), []);

    const { refetchMenu } = useMenuHandler();

    const handleConfirmModal = useCallback(() => {
        dispatch(resetOrdersErrors(undefined));
        hideModal();
    }, [dispatch, hideModal]);

    useMemo(() => {
        if (!ordersErrors || isOpen) return;

        if (productId && displayStepProductName) {
            const productError = ordersErrors.find((e) => e.productId === productId);

            if (!productError?.errorFromSteps) return;

            const productSteps = getStepProductsName(productError);
            setProductsName(productSteps.join(', '));

            if (productSteps.length > 0)
                setErrorMessage('modal_product_error.body_not_available_one');
            else setErrorMessage('modal_product_error.body_not_available_other');
        } else {
            const productsNameArray = ordersErrors.map((ordersError) => {
                if (!ordersError.errorFromSteps) return ordersError.productName;

                const productSteps = getStepProductsName(ordersError);
                return `${productSteps.join(', ')} (${ordersError.productName})`;
            });
            setProductsName(productsNameArray.join(', '));

            if (productsNameArray.length > 1)
                setErrorMessage('modal_product_error.body_not_available_other');
            else setErrorMessage('modal_product_error.body_not_available_one');
        }

        displayModal();
    }, [ordersErrors, isOpen, productId, displayStepProductName, displayModal]);

    useEffect(() => {
        if (isOpen) refetchMenu();
    }, [isOpen, refetchMenu]);

    return { isOpen, productsName, errorMessage, handleConfirmModal };
};
