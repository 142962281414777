import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useTranslation } from 'react-i18next';
import { getIsFoodCourt } from 'state/app';
import { useAppSelector } from 'state/store';
import { getAccessToken } from 'state/customer/customer.slice';
import { getAuthenticationType } from 'state/brand';
import { computeAccountButton } from './useAccountButton.utils';

export const useAccountButton = () => {
    const accessToken = useAppSelector(getAccessToken);
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const authenticationType = useAppSelector(getAuthenticationType);

    const { t } = useTranslation();
    const { navigateToAccount } = useCustomNavigate();

    return {
        navigateToAccount,
        ...computeAccountButton({
            isFoodCourt,
            isAuthenticated: !!accessToken,
            authenticationType,
        }),
        ariaLabel: t('account_button'),
    };
};
