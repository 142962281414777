import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DrawerLayout from 'layouts/DrawerLayout';
import MobileLayout from 'layouts/MobileLayout';
import Incognito from 'core/Incognito';
import { getIsIncognitoMode } from 'state/app';
import { useAppSelector } from 'state/store';
import { routes } from './routes';
import { RoutesPath } from './routes.types';
import RedirectToNewUrl from './RedirectToNewUrl/RedirectToNewUrl';

const RootRouter: React.FunctionComponent = () => {
    const isIncognitoMode = useAppSelector(getIsIncognitoMode);

    /**
     * In private mode browser we want to prevent the customer from ordering
     * but we still allow him to get the url where he was supposed to go
     * so that he can copy it and paste it in a public mode browser.
     */
    if (isIncognitoMode) {
        return <Incognito />;
    }

    const drawerRoutes = routes.filter(({ layout }) => layout === 'drawer');
    const mobileRoutes = routes.filter(({ layout }) => layout === 'mobile');

    return (
        <Routes>
            <Route
                key={'RedirectToMenuHome'}
                path={RoutesPath.OldRestaurants}
                element={<RedirectToNewUrl newUrl={RoutesPath.Restaurants} />}
            />
            <Route
                key={'RedirectToMenuHome'}
                path={RoutesPath.OldMenuHome}
                element={<RedirectToNewUrl newUrl={RoutesPath.MenuHome} />}
            />

            <Route element={<DrawerLayout />}>
                {drawerRoutes.map(({ name, path, element }) => (
                    <Route key={name} path={path} element={element} />
                ))}
            </Route>
            <Route element={<MobileLayout />}>
                {mobileRoutes.map(({ name, path, element }) => (
                    <Route key={name} path={path} element={element} />
                ))}
            </Route>
            <Route path="*" element={<Navigate to={RoutesPath.NotFound} replace />} />
        </Routes>
    );
};

export default RootRouter;
