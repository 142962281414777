import Account from 'pages/Account/Account';
import Cart from 'pages/Cart';
import Home from 'pages/Home';
import MenuHome from 'pages/Menu/MenuHome';
import Product from 'pages/Menu/Product';
import ChoosePayment from 'pages/Payment/ChoosePayment';
import LuncheonVoucherChoice from 'pages/Payment/ChoosePayment/LuncheonVoucherChoice';
import CardPayment from 'pages/Payment/ConfirmPayment/CardPayment';
import GooglePay from 'pages/Payment/ConfirmPayment/GooglePay';
import ApplePay from 'pages/Payment/ConfirmPayment/ApplePay';
import Edenred from 'pages/Payment/ConfirmPayment/Edenred';
import Paygreen from 'pages/Payment/ConfirmPayment/Paygreen';
import Login from 'pages/Login';
import AdditionalInformations from 'pages/AdditionalInformations';
import OrderConfirmation from 'pages/Order/OrderConfirmation';
import OrderFollowUp from 'pages/Order/OrderFollowUp';
import TermsAndConditions from 'pages/TermsAndConditions';
import Restaurants from 'pages/Restaurants/Restaurants';
import CrossSelling from 'pages/CrossSelling';
import Error from 'pages/Error';
import { ErrorEnum } from 'pages/Error/Error.type';
import PaymentLaterChoice from 'pages/Payment/ChoosePayment/PaymentLaterChoice';
import ResetPassword from 'pages/Login/ResetPassword';
import ForgottenPassword from 'pages/Login/ForgottenPassword';
import OrdersHistory from 'pages/Account/OrdersHistory';
import OrderReceipt from 'pages/Account/OrderReceipt/OrderReceipt';
import { RouteDeclaration, RoutesPath } from './routes.types';

export const routes: RouteDeclaration[] = [
    {
        name: 'home',
        path: RoutesPath.Home,
        element: <Home />,
        layout: 'drawer',
        state: 'home',
    },
    {
        name: 'login',
        path: RoutesPath.Login,
        element: <Login />,
        layout: 'drawer',
        state: 'login',
    },
    {
        name: 'forgotten_password',
        path: RoutesPath.ForgottenPassword,
        element: <ForgottenPassword />,
        layout: 'drawer',
        state: 'login',
    },
    {
        name: 'reset_password',
        path: RoutesPath.ResetPassword,
        element: <ResetPassword />,
        layout: 'drawer',
        state: 'login',
    },
    {
        name: 'account',
        path: RoutesPath.Account,
        element: <Account />,
        layout: 'mobile',
    },
    {
        name: 'orders_history.title',
        path: RoutesPath.OrdersHistory,
        element: <OrdersHistory />,
        layout: 'mobile',
    },
    {
        name: 'order_receipt.title',
        path: RoutesPath.OrderReceipt,
        element: <OrderReceipt />,
        layout: 'mobile',
    },
    {
        name: 'menu_home',
        path: RoutesPath.MenuHome,
        element: <MenuHome />,
        layout: 'mobile',
        state: 'menuHome',
        titleAccessor: 'restaurantName',
    },
    {
        name: 'restaurants.title',
        path: RoutesPath.Restaurants,
        element: <Restaurants />,
        layout: 'mobile',
        state: 'restaurants',
    },
    {
        name: 'product_name_route',
        path: RoutesPath.Product,
        element: <Product />,
        layout: 'mobile',
        state: 'menuHome',
    },
    {
        name: 'payment.mode',
        path: RoutesPath.ChoosePayment,
        element: <ChoosePayment />,
        layout: 'mobile',
        state: 'payment',
    },
    {
        name: 'payment.bank_card',
        path: RoutesPath.CardPayment,
        element: <CardPayment />,
        layout: 'mobile',
        state: 'payment',
    },
    {
        name: 'payment.luncheon_voucher',
        path: RoutesPath.LuncheonVoucherChoice,
        element: <LuncheonVoucherChoice />,
        layout: 'mobile',
        state: 'payment',
    },
    {
        name: 'payment.google_pay',
        path: RoutesPath.GooglePay,
        element: <GooglePay />,
        layout: 'mobile',
        state: 'payment',
    },
    {
        name: 'payment.apple_pay',
        path: RoutesPath.ApplePay,
        element: <ApplePay />,
        layout: 'mobile',
        state: 'payment',
    },
    {
        name: 'payment.edenred',
        path: RoutesPath.Edenred,
        element: <Edenred />,
        layout: 'mobile',
        state: 'payment',
    },
    {
        name: 'payment.paygreen.label',
        path: RoutesPath.Paygreen,
        element: <Paygreen />,
        layout: 'mobile',
        state: 'payment',
    },
    {
        name: 'payment.payment_later',
        path: RoutesPath.PaymentLaterChoice,
        element: <PaymentLaterChoice />,
        layout: 'mobile',
        state: 'payment',
    },
    {
        name: 'not_found_message',
        path: RoutesPath.NotFound,
        element: <Error error={ErrorEnum.NOT_FOUND} />,
        layout: 'drawer',
        state: 'notFound',
    },
    {
        name: 'cross_selling.title',
        path: RoutesPath.CrossSelling,
        element: <CrossSelling />,
        layout: 'mobile',
        state: 'crossSelling',
    },
    {
        name: 'cart.title',
        path: RoutesPath.Cart,
        element: <Cart />,
        layout: 'mobile',
        state: 'cart',
    },
    {
        name: 'additional_informations.name',
        path: RoutesPath.AdditionalInformations,
        element: <AdditionalInformations />,
        layout: 'mobile',
        state: 'additionalInformations',
    },
    {
        name: 'order_detail',
        path: RoutesPath.OrderConfirmation,
        element: <OrderConfirmation />,
        layout: 'mobile',
        state: 'orderConfirmation',
    },
    {
        name: 'order_follow_up.route',
        path: RoutesPath.OrderFollowUp,
        element: <OrderFollowUp />,
        layout: 'mobile',
        state: 'orderFollowUp',
    },
    {
        name: 'cart_error',
        path: RoutesPath.CartError,
        element: <Error error={ErrorEnum.CART_ERROR} />,
        layout: 'drawer',
        state: 'payment',
    },
    {
        name: 'order_error',
        path: RoutesPath.OrderError,
        element: <Error error={ErrorEnum.ORDER_ERROR} />,
        layout: 'drawer',
        state: 'payment',
    },
    {
        name: 'terms_and_conditions.title',
        path: RoutesPath.TermsAndConditions,
        element: <TermsAndConditions />,
        layout: 'mobile',
        state: 'payment',
    },
];
