import { DateTime } from 'luxon';
import { OpeningDetails } from 'state/restaurantSchedules';
import { WeborderingConfiguration } from 'services/brand/brand.type';
import { WeborderingScheduleAttributes, WeekDay } from 'services/brand/orderingSchedules.type';

export const getOpeningDetailsByRestaurants = (
    weborderingConfiguration?: WeborderingConfiguration,
) => {
    if (!weborderingConfiguration) return null;

    return weborderingConfiguration.restaurants.reduce((openingDetails, restaurant) => {
        const moduleOrderingSchedule = restaurant.moduleOrderingSchedules[0];

        const schedules = moduleOrderingSchedule.isPreorderingEnabled
            ? restaurant.pickupSchedule
            : moduleOrderingSchedule.weborderingSchedules;

        const receptionSchedule = restaurant.module_ordering_flows?.[0].openAt;

        const restaurantOpeningDetails = getOpeningDetails(schedules, receptionSchedule);

        openingDetails[restaurant.restaurantId] = restaurantOpeningDetails;

        return openingDetails;
    }, {} as Record<number, OpeningDetails>);
};

export const getOpeningDetails = (
    weborderingSchedules: WeborderingScheduleAttributes[],
    receptionSchedule?: string,
): OpeningDetails => {
    const startOfToday = DateTime.now().startOf('day');
    const DAYS = Object.values(WeekDay);
    const now = DateTime.now();

    let closestOpening: DateTime | undefined;
    let isCurrentlyOpen = false;

    const customReceptionSchedule = receptionSchedule
        ? DateTime.fromISO(receptionSchedule)
        : undefined;

    weborderingSchedules.forEach((weborderingSchedule) => {
        const scheduleWeekDay = DAYS.indexOf(weborderingSchedule.day) + 1;
        // Schedule of current week
        let scheduleStart = startOfToday.set({
            weekday: scheduleWeekDay,
            minute: weborderingSchedule.start,
        });
        let scheduleEnd = startOfToday.set({
            weekday: scheduleWeekDay,
            minute: weborderingSchedule.end,
        });

        // If schedule.end is over, schedule should be next week
        if (
            scheduleEnd.diffNow().milliseconds < 0 ||
            (customReceptionSchedule && customReceptionSchedule?.diff(scheduleEnd).milliseconds > 0)
        ) {
            scheduleStart = scheduleStart.plus({ week: 1 });
            scheduleEnd = scheduleEnd.plus({ week: 1 });
        }

        if (scheduleStart.diffNow().milliseconds < 0 && scheduleEnd.diffNow().milliseconds > 0) {
            isCurrentlyOpen = true;
        }

        if (!closestOpening || scheduleStart.diff(closestOpening).milliseconds < 0) {
            closestOpening = scheduleStart;
        }

        const isClosedFromReception =
            customReceptionSchedule &&
            customReceptionSchedule.diff(now).milliseconds > 0 &&
            customReceptionSchedule.diff(now.plus({ years: 1 })).milliseconds < 0 &&
            closestOpening &&
            customReceptionSchedule.diff(closestOpening).milliseconds > 0;

        if (isClosedFromReception) {
            // If it is during the opening hours defined in the back office, we display it as is
            // Else, we use the next opening hour available
            if (
                customReceptionSchedule.diff(scheduleStart).milliseconds > 0 &&
                customReceptionSchedule.diff(scheduleEnd).milliseconds < 0
            ) {
                closestOpening = customReceptionSchedule;
                isCurrentlyOpen = false;
            } else {
                closestOpening = scheduleStart;
                isCurrentlyOpen = false;
            }
        }
    });

    return { isCurrentlyOpen, nextOpening: closestOpening?.toString() };
};
