import { createSlice, PayloadAction, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
    computeNestedProductId,
    computeCurrentStep,
    computeProductByPath,
    computeReturnToPreviousStep,
} from './currentCompositeProduct.utils';

export const currentCompositeProductSliceName = 'currentCompositeProduct';

export type CompositeProduct = {
    quantity: number;
    steps?: Record<string, CompositeStep>;
};

export type CompositeStep = Record<string, CompositeProduct>;
export type CurrentPath = string[];
export type MissingSteps = Record<string, Record<string, number>>;

export type CurrentCompositeProductState = {
    currentStep: CompositeStep | null;
    currentPath: CurrentPath | null;
    previousPath: CurrentPath | null;
    missingSteps: MissingSteps | null;
} | null;

const initialState: CurrentCompositeProductState = null;

export const currentCompositeProductSlice = createSlice<
    CurrentCompositeProductState,
    SliceCaseReducers<CurrentCompositeProductState>,
    string,
    SliceSelectors<CurrentCompositeProductState>
>({
    name: currentCompositeProductSliceName,
    initialState,
    reducers: {
        setCurrentCompositeProduct: (state, action: PayloadAction<CurrentCompositeProductState>) =>
            action.payload,
        updateCurrentStep: (
            state,
            action: PayloadAction<{
                compositeProduct: CompositeProduct;
                path: CurrentPath;
            }>,
        ) => ({
            currentStep: computeCurrentStep({
                state,
                ...action.payload,
            }),
            currentPath: state?.currentPath || null,
            previousPath: state?.previousPath || null,
            missingSteps: state?.missingSteps || null,
        }),
        updateCurrentPath: (state, action: PayloadAction<CurrentPath>) => ({
            currentStep: state?.currentStep || null,
            currentPath: action.payload,
            previousPath: state?.currentPath || null,
            missingSteps: state?.missingSteps || null,
        }),
        returnToPreviousStep: (state, action: PayloadAction<CurrentPath>) => ({
            currentStep: state?.currentStep || null,
            currentPath: computeReturnToPreviousStep(action.payload),
            previousPath: state?.currentPath || null,
            missingSteps: state?.missingSteps || null,
        }),
        updateMissingSteps: (state, action: PayloadAction<MissingSteps>) => ({
            currentStep: state?.currentStep || null,
            currentPath: state?.currentPath || null,
            previousPath: state?.previousPath || null,
            missingSteps: { ...state?.missingSteps, ...action.payload },
        }),
    },
});

export const getCurrentCompositeProduct = (state: RootState): CurrentCompositeProductState =>
    state.currentCompositeProduct;

export const getCurrentStep = (state: RootState): CompositeStep | null =>
    state.currentCompositeProduct?.currentStep || null;

export const getCurrentPath = (state: RootState): CurrentPath | null =>
    state.currentCompositeProduct?.currentPath || null;

export const getPreviousPath = (state: RootState): CurrentPath | null =>
    state.currentCompositeProduct?.previousPath || null;

export const getProductByPath =
    (path: CurrentPath) =>
    (state: RootState): CompositeProduct | null =>
        computeProductByPath({ state: state.currentCompositeProduct, path });

export const getNestedProductId = (state: RootState): string | null =>
    computeNestedProductId(state.currentCompositeProduct?.currentPath || null);

export const getMissingSteps = (state: RootState): MissingSteps | null =>
    state.currentCompositeProduct?.missingSteps || null;

export const {
    setCurrentCompositeProduct,
    updateCurrentStep,
    updateCurrentPath,
    returnToPreviousStep,
    updateMissingSteps,
} = currentCompositeProductSlice.actions;
