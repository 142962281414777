import { RootState } from 'state/store';
import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from 'utils/env';

const prepareHeaders = (
    headers: Headers,
    { getState }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>,
) => {
    /**
     * For now we always add the Io-Anonymous-Id because there is no authenticated mode.
     * Then we will add it only if there it no customerId.
     */
    const anonymousId = (getState() as RootState).app?.anonymousId;
    if (anonymousId) {
        headers.set('Io-Anonymous-Id', anonymousId);
    }

    // Header for filtering webordering-v2 requests in Elastic
    headers.set('Io-Application', 'webordering-v2');

    return headers;
};

export const innovorderApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: ENV.API_URL,
        prepareHeaders,
    }),
    endpoints: () => ({}),
    tagTypes: ['Brand'],
});
