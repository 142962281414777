import { EnrichedProduct, EnrichedStep } from '@innovorder/order_detail';
import { OrdersState } from 'state/orders';
import { ProductError, OrderStepError } from 'state/ordersErrors/ordersErrors.slice';
import { MenuHashMap } from 'services/menu/menu.type';
import {
    FormattedMultiOrderError,
    FormattedSingleOrderError,
    ValidationCart,
    ValidationProduct,
    ValidationStep,
    isSingleOrderError,
} from 'services/order/order.type';
import { BasicError } from 'services/providers/innovorder.types';

const flattenErrors = (
    errors: { extraData: ValidationCart }[],
    restaurantId: number,
): { restaurantId: number; product: ValidationProduct }[] => {
    return errors
        .map((error) => error.extraData.products)
        .flat()
        .map((product) => ({ product, restaurantId }));
};

const stringifyReasons = (reasons: BasicError[]): string => {
    return reasons.map((reason) => reason.message).join();
};

const getNameFromEnrichedStepOrProduct = (
    id: number,
    items: EnrichedStep[] | EnrichedProduct[],
): string | null => {
    for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if ('stepId' in item) {
            if (item.stepId === id) return item.name;

            const name = getNameFromEnrichedStepOrProduct(id, item.products);
            if (name) return name;
        } else {
            if (item.productId === id) return item.name;

            const name = getNameFromEnrichedStepOrProduct(id, item.steps);
            if (name) return name;
        }
    }
    return null;
};

export const getNameFromOrders = (
    stepOrProduct: ValidationStep | ValidationProduct,
    orders: OrdersState,
): string | null => {
    if (!orders) return null;

    const stepOrProductId =
        'stepId' in stepOrProduct ? stepOrProduct.stepId : stepOrProduct.productId;

    const restaurantIds = Object.keys(orders).map(Number);

    for (let i = 0; i < restaurantIds.length; i++) {
        const { cart } = orders[restaurantIds[i]];

        const name = getNameFromEnrichedStepOrProduct(stepOrProductId, cart);
        if (name) return name;
    }

    return null;
};

const getNameFromMenu = (
    stepOrProduct: ValidationStep | ValidationProduct,
    menuHashMap?: MenuHashMap,
): string | null => {
    if (!menuHashMap) return null;

    if ('stepId' in stepOrProduct) {
        return menuHashMap.steps[stepOrProduct.stepId]?.name || null;
    }

    return menuHashMap.products[stepOrProduct.productId]?.name || null;
};

const invalidStepsOrProductsFinder = (
    productsOrSteps: (ValidationStep | ValidationProduct)[],
    orders: OrdersState,
    menu?: MenuHashMap,
) => {
    return productsOrSteps.reduce<OrderStepError[]>((acc, productOrStep) => {
        if (productOrStep.status === 'VALID') return acc;

        if (productOrStep.reasons.length !== 0) {
            acc.push({
                name:
                    getNameFromOrders(productOrStep, orders) ??
                    getNameFromMenu(productOrStep, menu),
                errorMessage: stringifyReasons(productOrStep.reasons),
            });
            return acc;
        }

        if ('stepId' in productOrStep) {
            acc.push(...invalidStepsOrProductsFinder(productOrStep.products, orders, menu));
        } else {
            acc.push(...invalidStepsOrProductsFinder(productOrStep.steps, orders, menu));
        }

        return acc;
    }, []);
};

export const errorsParser = (
    extraData: FormattedSingleOrderError['extraData'] | FormattedMultiOrderError['extraData'],
    lastOrders: OrdersState,
    menu?: MenuHashMap,
) => {
    const dataToParse = isSingleOrderError(extraData)
        ? flattenErrors(extraData.errors, extraData.restaurantId)
        : extraData.errors
              .map((error) => flattenErrors(error.extraData.errors, error.extraData.restaurantId))
              .flat();

    return dataToParse.reduce<ProductError[]>((acc, { product, restaurantId }) => {
        if (product.status === 'VALID') return acc;

        acc.push({
            productName: getNameFromOrders(product, lastOrders) ?? getNameFromMenu(product, menu),
            productId: product.productId,
            productCartId: product.productCartId,
            errorFromSteps: product.reasons.length === 0,
            productStepsInError: invalidStepsOrProductsFinder(product.steps, lastOrders, menu),
            errorMessage: stringifyReasons(product.reasons),
            restaurantId,
        });

        return acc;
    }, []);
};

export const isProductInError = (
    parsedErrors: ProductError[],
    productOrProductCartId: string | number,
) => {
    if (typeof productOrProductCartId === 'string') {
        return parsedErrors.some(({ productCartId }) => productOrProductCartId === productCartId);
    }

    return parsedErrors.some(({ productId }) => productOrProductCartId === productId);
};

export const getStepProductsName = (parsedError: ProductError) => {
    if (!parsedError.errorFromSteps || !parsedError.productStepsInError) return [];

    return parsedError.productStepsInError.map(({ name }) => name);
};
