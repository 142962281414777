import { ConsumptionModeType } from '@innovorder/order_detail';
import { getCurrentConsumptionModeType, getTableId } from 'state/app';
import { useAppSelector } from 'state/store';

export const useTableNumber = () => {
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const tableId = useAppSelector(getTableId);
    const tableNumber =
        currentConsumptionModeType === ConsumptionModeType.MODE_SIT_IN ? tableId : null;

    return { tableNumber };
};
