import { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useStripe } from '@stripe/react-stripe-js';
import { getRequiredAction } from 'state/requiredAction';
import { useAppDispatch, useAppSelector } from 'state/store';
import { isStripeIntentError, isStripeIntentSuccess } from 'utils/stripe';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useTranslation } from 'react-i18next';
import { useCreateOrder } from 'hooks/useCreateOrder';
import { getIsPaymentConfirmed, updateAppState } from 'state/app';

export const useStripeConfirmPaymentVM = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const stripe = useStripe();
    const requiredAction = useAppSelector(getRequiredAction);
    const isPaymentConfirmed = useAppSelector(getIsPaymentConfirmed);
    const { goCurrentOrder, triggerError } = useWorkflowStateMachine();
    const { navigateToPaymentWithError } = useCustomNavigate();
    const [done, setDone] = useState<boolean>(false);
    const [displayThreeDSErrorMessage, setDisplayThreeDSErrorMessage] = useState(false);

    const { resetIdempotencyKeyAndNavigateToOrderError } = useCreateOrder();

    const handleStripeConfirmError = useCallback(() => {
        triggerError();
        resetIdempotencyKeyAndNavigateToOrderError();
    }, [triggerError, resetIdempotencyKeyAndNavigateToOrderError]);

    const handleThreeDSError = useCallback(() => {
        navigateToPaymentWithError('threeDS');
    }, [navigateToPaymentWithError]);

    useEffect(() => {
        const stripeConfirmOrderPayment = async () => {
            try {
                if (
                    stripe &&
                    requiredAction &&
                    'intentId' in requiredAction &&
                    'orderId' in requiredAction &&
                    !done &&
                    !isPaymentConfirmed
                ) {
                    setDone(false);

                    const paymentIntentResult = await stripe.handleCardAction(
                        requiredAction.payment_intent_client_secret,
                    );

                    if (isStripeIntentError(paymentIntentResult)) {
                        const sentryPayload = {
                            type: paymentIntentResult.error?.type,
                            code: paymentIntentResult.error?.code,
                            decline_code: paymentIntentResult.error?.decline_code,
                        };
                        handleStripeConfirmError();
                        Sentry.captureMessage(
                            `[handleCardActionError][order] ${JSON.stringify(sentryPayload)}`,
                            'debug',
                        );
                        setDone(true);
                    }

                    if (isStripeIntentSuccess(paymentIntentResult)) {
                        dispatch(
                            updateAppState({
                                isPaymentConfirmed: true,
                            }),
                        );
                        goCurrentOrder(requiredAction.orderUuid);
                        setDone(true);
                    }
                }
            } catch (err) {
                Sentry.captureMessage(
                    `[stripeConfirmOrderPayment] ${JSON.stringify(err)}`,
                    'debug',
                );
                handleStripeConfirmError();
            }
        };
        stripeConfirmOrderPayment();
    }, [
        stripe,
        requiredAction,
        done,
        isPaymentConfirmed,
        dispatch,
        goCurrentOrder,
        handleStripeConfirmError,
    ]);

    useEffect(() => {
        const stripeConfirmMultiOrderPayment = async () => {
            try {
                if (
                    stripe &&
                    requiredAction &&
                    'multiOrderId' in requiredAction &&
                    !done &&
                    !isPaymentConfirmed
                ) {
                    setDone(false);

                    const paymentIntentResult = await stripe.handleCardAction(
                        requiredAction.payment_intent_client_secret,
                    );

                    if (isStripeIntentError(paymentIntentResult)) {
                        const sentryPayload = {
                            type: paymentIntentResult.error?.type,
                            code: paymentIntentResult.error?.code,
                            decline_code: paymentIntentResult.error?.decline_code,
                        };
                        handleStripeConfirmError();
                        Sentry.captureMessage(
                            `[handleCardActionError][multiOrder] ${JSON.stringify(sentryPayload)}`,
                            'debug',
                        );
                        setDone(true);
                    }

                    if (isStripeIntentSuccess(paymentIntentResult)) {
                        dispatch(
                            updateAppState({
                                isPaymentConfirmed: true,
                            }),
                        );
                        goCurrentOrder(requiredAction.multiOrderId);
                        setDone(true);
                    }
                }
            } catch (err) {
                Sentry.captureMessage(
                    `[stripeConfirmMultiOrderPayment] ${JSON.stringify(err)}`,
                    'debug',
                );
                handleStripeConfirmError();
            }
        };
        stripeConfirmMultiOrderPayment();
    }, [
        stripe,
        requiredAction,
        done,
        isPaymentConfirmed,
        dispatch,
        goCurrentOrder,
        handleStripeConfirmError,
    ]);

    useEffect(() => {
        const TIME_BEFORE_DISPLAYING_3DS_ERROR_MESSAGE = 30000;

        const timer = setTimeout(() => {
            setDisplayThreeDSErrorMessage(true);
        }, TIME_BEFORE_DISPLAYING_3DS_ERROR_MESSAGE);

        return () => clearTimeout(timer);
    }, []);

    return {
        done,
        displayThreeDSErrorMessage,
        buttonText: t('order_error_3ds.button'),
        handleThreeDSError,
    };
};
