import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state/store';
import { useAuthenticate } from 'hooks/useAuthenticate/useAuthenticate';
import {
    getAccessToken,
    updateCustomer,
    getCustomerBrandId,
    resetCustomer,
} from 'state/customer/customer.slice';
import { useAutoLoginJWTQuery, useMeQuery } from 'services/authentication/authentication.endpoint';
import { useCartHandler } from 'hooks/useCartHandler/useCartHandler';
import { getOrders } from 'state/orders/orders.slice';
import { computeCartPayload } from 'utils/cart';
import { getBrandId } from 'state/brand';
import { useSearchParams } from 'react-router-dom';

const CustomerAuthGate: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const accessToken = useAppSelector(getAccessToken);
    const [searchParams] = useSearchParams();
    const autoLoginJWT = searchParams.get('autoLoginJWT');
    const { logout } = useAuthenticate();
    const { handlePreview } = useCartHandler();
    const orders = useAppSelector(getOrders);
    const [hasLoggedOut, setHasLoggedOut] = useState(false);
    const [hasCheckedAccessToken, setHasCheckedAccessToken] = useState(false);
    const [hasRefreshedCart, setHasRefreshedCart] = useState(false);
    const customerBrandId = useAppSelector(getCustomerBrandId);
    const brandId = useAppSelector(getBrandId);

    /**
     * RESET CUSTOMER
     */
    useEffect(() => {
        if (brandId && customerBrandId && customerBrandId !== brandId) {
            dispatch(resetCustomer(undefined));
        }
    }, [customerBrandId, brandId, dispatch]);

    /**
     * AUTO LOGIN
     */
    const { data: autoLoginJWTData } = useAutoLoginJWTQuery(
        { autoLoginJWT: autoLoginJWT ?? '' },
        { skip: !autoLoginJWT || !!accessToken },
    );

    useEffect(() => {
        if (autoLoginJWTData) {
            const { customer, accessToken: accessTokenFromAutoLogin } = autoLoginJWTData;
            dispatch(
                updateCustomer({
                    brandId: customer.brandId,
                    accessToken: accessTokenFromAutoLogin,
                    customerId: customer.customerId,
                    email: customer.email,
                    phone: customer.phone,
                    customerName: `${customer.firstName} ${customer.lastName}`,
                    accountPaymentType: customer.student?.accountPaymentType,
                    ewalletOverdraftAmount: customer.ewalletOverdraftAmount,
                }),
            );
        }
    }, [autoLoginJWTData, dispatch, accessToken]);

    /**
     * GET CUSTOMER
     */
    const {
        data: customerData,
        isError: isCustomerError,
        isLoading: isCustomerLoading,
    } = useMeQuery({ token: accessToken ?? undefined }, { skip: !accessToken });

    useEffect(() => {
        if (isCustomerError && !hasLoggedOut) {
            setHasLoggedOut(true);
            logout();
        }
    }, [isCustomerError, logout, hasLoggedOut]);

    useEffect(() => {
        if (hasCheckedAccessToken) return;

        if (customerData && !isCustomerError && !isCustomerLoading && brandId) {
            dispatch(
                updateCustomer({
                    brandId,
                    customerId: customerData.customerId,
                    email: customerData.email,
                    phone: customerData.phone,
                    customerName: `${customerData.firstName} ${customerData.lastName}`,
                    accountPaymentType: customerData.student?.accountPaymentType,
                    ewalletOverdraftAmount: customerData.ewalletOverdraftAmount,
                }),
            );
        }
    }, [
        hasCheckedAccessToken,
        isCustomerError,
        isCustomerLoading,
        customerData,
        dispatch,
        brandId,
    ]);

    /**
     * REFRESH CART
     */
    useEffect(() => {
        if (!hasCheckedAccessToken || hasRefreshedCart) return;

        if (accessToken && orders) {
            const payload = Object.values(orders).map((order) => ({
                updatedCart: computeCartPayload(order.cart),
                restaurantId: order.restaurantId,
                menuId: order.menuId,
            }));

            handlePreview(payload);
        }

        setHasRefreshedCart(true);
    }, [hasCheckedAccessToken, hasRefreshedCart, orders, handlePreview, accessToken]);

    /**
     * AUTHENT LISTENER
     *
     * If user just authenticated, we need to refresh the cart
     */
    useEffect(() => {
        if (accessToken) {
            setHasCheckedAccessToken(true);
            setHasRefreshedCart(false);
        }
    }, [accessToken]);

    return null;
};

export default CustomerAuthGate;
