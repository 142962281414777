import { useAppSelector } from 'state/store';
import { getOrders } from 'state/orders';
import { getConsumptionModeList } from 'state/consumptionModeList';
import { PaymentMethodCode } from 'services/consumptionMode/consumptionMode.type';

export const useStripePayment = () => {
    const currentOrders = useAppSelector(getOrders);
    const consumptionModeList = useAppSelector(getConsumptionModeList);

    if (!currentOrders || !consumptionModeList) {
        return {
            hasStripe: false,
        };
    }

    return Object.values(currentOrders).reduce(
        (result, { restaurantId, consumptionMode }) => {
            const orderConsumptionMode = consumptionModeList[restaurantId];

            if (!orderConsumptionMode) {
                return {
                    hasStripe: false,
                };
            }

            const selectedConsumptionMode = orderConsumptionMode.find(
                ({ type }) => type === consumptionMode,
            );

            const hasStripeActive = !!selectedConsumptionMode?.paymentMethods.find(
                ({ code, active }) => active && code === PaymentMethodCode.Stripe,
            );

            return {
                hasStripe: result.hasStripe && hasStripeActive,
            };
        },
        {
            hasStripe: true,
        },
    );
};
