import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    getCurrentConsumptionModeType,
    getDebouncingQuantity,
    getIsFoodCourt,
    getRestaurantId,
    updateAppState,
} from 'state/app';
import { getMenuState } from 'state/menu';
import {
    OrdersState,
    getOrderComment,
    getOrderPromocode,
    getOrders,
    getOrderCart,
    getOrder,
} from 'state/orders';
import { useAppDispatch, useAppSelector } from 'state/store';
import {
    computeCartPayload,
    computeRestaurantCarts,
    updateProductQuantityInCart,
} from 'utils/cart';
import { usePreviewOrderMutation } from 'services/order/order.endpoints';
import {
    Cart,
    ChannelId,
    MultiOrderErrorPayload,
    OrderErrorPayload,
} from 'services/order/order.type';
import { getBrand } from 'state/brand';
import {
    getCurrentPath,
    getProductByPath,
    setCurrentCompositeProduct,
} from 'state/currentCompositeProduct';
import { getAccessToken, getCustomerId } from 'state/customer/customer.slice';
import { usePreviewMultiOrderMutation } from 'services/multi-order/multi-order.endpoint';
import { EnrichedProduct, VerifiedOrder } from '@innovorder/order_detail';
import { useUpdateOrder } from 'hooks/useUpdateOrder/useUpdateOrder';
import { computeOrderToVerify } from 'pages/Cart/Cart.utils';
import { extractErrorsCode } from 'utils/errors';
import { errorsParser, isProductInError } from 'utils/errors/errorsParser';
import debounce from 'lodash.debounce';
import { addOrdersErrors, getOrdersErrors } from 'state/ordersErrors/ordersErrors.slice';
import { computeRectifiedCarts, computeStepsFromState } from './useCartHandler.utils';

export type ProductCartHandler = {
    isCartOperationInProgress: boolean;
    setIsCartOperationInProgress: React.Dispatch<React.SetStateAction<boolean>>;
    handleQuantityChange: (payload: UpdateProductQuantityInCartPayload) => void;
    handlePreview: (updates: { updatedCart: Cart; restaurantId: number; menuId: number }[]) => void;
    handleAddProductToCart: (payload: AddProductToCartPayload) => void;
    handleUpdateProductQuantityInCart: (payload: UpdateProductQuantityInCartPayload) => void;
    handlePromocodeInCart: (payload: UpdatePromocodeInCartPayload) => void;
    data: OrdersState;
    isLoading: boolean;
    isError: boolean;
};

export type UpdateProductQuantityInCartPayload = {
    productCartId: string;
    quantity: number;
    restaurantId: number;
    menuId: number;
    rectifiedCarts?: RectifiedCart[];
};

export type UpdatePromocodeInCartPayload = {
    order: VerifiedOrder;
    promocode: string;
    rectifiedCart?: EnrichedProduct[];
};

export type RectifiedCart = {
    cart: EnrichedProduct[];
    restaurantId: number;
    menuId: number;
};

export type PreviewPayload = {
    updatedCart: Cart;
    restaurantId: number;
    menuId: number;
};

type AddProductToCartPayload = {
    productId: number;
    quantity: number;
    restaurantId: number;
    menuId: number;
    rectifiedCarts?: RectifiedCart[];
};

type LastActionState =
    | {
          type: 'UPDATE_PRODUCT_QUANTITY';
          payload: UpdateProductQuantityInCartPayload;
      }
    | {
          type: 'UPDATE_PROMOCODE';
          payload: UpdatePromocodeInCartPayload;
      }
    | {
          type: 'ADD_PRODUCT';
          payload: AddProductToCartPayload;
      };

export const useCartHandler = (): ProductCartHandler => {
    const dispatch = useAppDispatch();
    const { updateOrders } = useUpdateOrder();

    const brand = useAppSelector(getBrand);
    const stateRestaurantId = useAppSelector(getRestaurantId);
    const accessToken = useAppSelector(getAccessToken);
    const customerId = useAppSelector(getCustomerId);
    const orders = useAppSelector(getOrders);
    const cart = useAppSelector(getOrderCart(stateRestaurantId));
    const stateOrder = useAppSelector(getOrder(stateRestaurantId));
    const menu = useAppSelector(getMenuState);
    const currentPath = useAppSelector(getCurrentPath);
    const currentProduct = useAppSelector(getProductByPath(currentPath || []));
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const orderComment = useAppSelector(getOrderComment(stateRestaurantId));
    const ordersErrors = useAppSelector(getOrdersErrors);
    const orderPromocode = useAppSelector(getOrderPromocode(stateRestaurantId));
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const debouncingQuantity = useAppSelector(getDebouncingQuantity);

    const [lastAction, setLastAction] = useState<LastActionState | null>(null);
    const [formattedOrder, setFormattedOrder] = useState<OrdersState>(null);
    const [isCartOperationInProgress, setIsCartOperationInProgress] = useState<boolean>(false);

    const [
        previewOrder,
        {
            data: singleOrder,
            isLoading: previewOrderIsLoading,
            error: previewOrderError,
            isError: isPreviewOrderError,
        },
    ] = usePreviewOrderMutation();

    const [
        previewMultiOrder,
        {
            data: multiOrders,
            isLoading: previewMultiOrderIsLoading,
            error: previewMultiOrderError,
            isError: isPreviewMultiOrderError,
        },
    ] = usePreviewMultiOrderMutation();

    const handlePreview = useCallback(
        async (updates: { updatedCart: Cart; restaurantId: number; menuId: number }[]) => {
            const authenticatedPayload = {
                ...(accessToken ? { accessToken } : {}),
                ...(customerId ? { customerId } : {}),
            };

            if (!isFoodCourt && currentConsumptionModeType) {
                const { updatedCart, restaurantId, menuId } = updates[0];
                await previewOrder({
                    restaurantId,
                    channelId: ChannelId.WEB,
                    consumptionMode: currentConsumptionModeType,
                    menuId,
                    cart: updatedCart,
                    comment: orderComment,
                    promocode: orderPromocode,
                    ...authenticatedPayload,
                });
            }

            if (isFoodCourt && brand && currentConsumptionModeType) {
                const ordersToUpdate = updates.map(({ restaurantId, menuId, updatedCart }) => ({
                    restaurantId,
                    menuId,
                    cart: updatedCart,
                }));
                const restaurantCarts = computeRestaurantCarts({
                    orders,
                    ordersToUpdate,
                });

                await previewMultiOrder({
                    channelId: ChannelId.WEB,
                    brandId: brand.brandId,
                    consumptionMode: currentConsumptionModeType,
                    restaurantCarts,
                    ...authenticatedPayload,
                });
            }

            dispatch(
                updateAppState({
                    idempotencyKey: uuidv4(),
                }),
            );
        },
        [
            orderComment,
            orderPromocode,
            brand,
            isFoodCourt,
            currentConsumptionModeType,
            orders,
            previewOrder,
            previewMultiOrder,
            accessToken,
            customerId,
            dispatch,
        ],
    );

    const handleUpdateProductQuantityInCart = useCallback(
        async ({
            productCartId,
            quantity,
            restaurantId,
            menuId,
            rectifiedCarts,
        }: UpdateProductQuantityInCartPayload) => {
            let cartToPreview: PreviewPayload[] = [];

            dispatch(
                updateAppState({
                    debouncingQuantity: { [productCartId]: quantity },
                }),
            );

            if (rectifiedCarts) {
                const addAndRectifySameBasket = !!rectifiedCarts.find(
                    ({ restaurantId: r }) => restaurantId === r,
                );

                cartToPreview = rectifiedCarts.map(
                    ({ cart: rectCart, restaurantId: rectRestaurantId, menuId: rectMenuId }) => {
                        const computedAddCart = computeCartPayload(rectCart ?? []);

                        const updatedCart =
                            rectRestaurantId === restaurantId
                                ? updateProductQuantityInCart(
                                      computedAddCart,
                                      productCartId,
                                      quantity,
                                      undefined,
                                  )
                                : computedAddCart;

                        return {
                            restaurantId: rectRestaurantId,
                            updatedCart,
                            menuId: rectMenuId,
                        };
                    },
                );

                if (!addAndRectifySameBasket) {
                    // comme la modif n'est pas dans le rectify, on a besoin de créer un autre basket pour envoyer la modif
                    const computedAddCart = computeCartPayload(orders?.[restaurantId]?.cart ?? []);
                    const updatedCart = updateProductQuantityInCart(
                        computedAddCart,
                        productCartId,
                        quantity,
                        undefined,
                    );

                    setFormattedOrder(null);
                    setLastAction({
                        type: 'UPDATE_PRODUCT_QUANTITY',
                        payload: { productCartId, quantity, restaurantId, menuId },
                    });

                    cartToPreview.unshift({ updatedCart, restaurantId, menuId });
                }
            } else if (restaurantId && menuId) {
                const computedCart = computeCartPayload(orders?.[restaurantId]?.cart ?? []);

                const updatedCart = updateProductQuantityInCart(
                    computedCart,
                    productCartId,
                    quantity,
                    undefined,
                );

                cartToPreview.unshift({ updatedCart, restaurantId, menuId });
            }

            setFormattedOrder(null);
            setLastAction({
                type: 'UPDATE_PRODUCT_QUANTITY',
                payload: {
                    productCartId,
                    quantity,
                    restaurantId,
                    menuId,
                },
            });

            handlePreview(cartToPreview);
        },
        [dispatch, orders, handlePreview],
    );

    const handleAddProductToCart = useCallback(
        async ({
            productId,
            quantity,
            restaurantId,
            menuId,
            rectifiedCarts,
        }: AddProductToCartPayload) => {
            const productCartId = uuidv4();
            let cartToPreview: PreviewPayload[] = [];
            const steps = computeStepsFromState(productId, currentProduct, menu);
            if (rectifiedCarts) {
                const addAndRectifySameBasket = !!rectifiedCarts.find(
                    ({ restaurantId: r }) => restaurantId === r,
                );

                cartToPreview = rectifiedCarts.map(
                    ({ cart: rectCart, restaurantId: rectRestaurantId, menuId: rectMenuId }) => {
                        const computedAddCart = computeCartPayload(rectCart ?? []);

                        const updatedCart =
                            rectRestaurantId === restaurantId
                                ? updateProductQuantityInCart(
                                      computedAddCart,
                                      productCartId,
                                      quantity,
                                      productId,
                                      steps,
                                  )
                                : computedAddCart;

                        return {
                            restaurantId: rectRestaurantId,
                            updatedCart,
                            menuId: rectMenuId,
                        };
                    },
                );

                if (!addAndRectifySameBasket) {
                    const computedAddCart = computeCartPayload(orders?.[restaurantId]?.cart ?? []);
                    const updatedCart = updateProductQuantityInCart(
                        computedAddCart,
                        productCartId,
                        quantity,
                        productId,
                        steps,
                    );
                    cartToPreview.unshift({ updatedCart, restaurantId, menuId });
                }
            } else if (menuId && restaurantId) {
                const computedCart = computeCartPayload(orders?.[restaurantId]?.cart ?? []);
                const updatedCart = updateProductQuantityInCart(
                    computedCart,
                    productCartId,
                    quantity,
                    productId,
                    steps,
                );

                cartToPreview.unshift({ updatedCart, restaurantId, menuId });
            }

            setFormattedOrder(null);
            setLastAction({
                type: 'ADD_PRODUCT',
                payload: { productId, quantity, restaurantId, menuId },
            });

            handlePreview(cartToPreview);
        },
        [menu, currentProduct, orders, handlePreview],
    );

    const handlePromocodeInCart = useCallback(
        async ({ order, promocode, rectifiedCart }: UpdatePromocodeInCartPayload) => {
            const authenticatedPayload = {
                ...(accessToken ? { accessToken } : {}),
                ...(customerId ? { customerId } : {}),
            };

            const orderToVerify = rectifiedCart
                ? computeOrderToVerify({
                      ...order,
                      cart: rectifiedCart,
                  })
                : computeOrderToVerify(order);

            setFormattedOrder(null);
            setLastAction({ type: 'UPDATE_PROMOCODE', payload: { order, promocode } });
            dispatch(
                updateAppState({
                    idempotencyKey: uuidv4(),
                }),
            );
            return previewOrder({ ...orderToVerify, promocode, ...authenticatedPayload });
        },
        [dispatch, previewOrder, accessToken, customerId],
    );

    const debouncedUpdateQuantity = useMemo(() => {
        return debounce((payload: UpdateProductQuantityInCartPayload) => {
            handleUpdateProductQuantityInCart(payload);
        }, 1000);
    }, [handleUpdateProductQuantityInCart]);

    const handleQuantityChange = useCallback(
        (payload: UpdateProductQuantityInCartPayload) => {
            setIsCartOperationInProgress(true);
            const newDebouncingQuantity = {
                ...debouncingQuantity,
                [payload.productCartId]: payload.quantity,
            };
            dispatch(updateAppState({ debouncingQuantity: newDebouncingQuantity }));
            debouncedUpdateQuantity(payload);
        },
        [debouncedUpdateQuantity, debouncingQuantity, dispatch],
    );

    useEffect(() => {
        if (
            menu &&
            orders &&
            !ordersErrors &&
            (previewOrderError || previewMultiOrderError) &&
            !previewMultiOrderIsLoading
        ) {
            const error =
                (previewOrderError as OrderErrorPayload) ??
                (previewMultiOrderError as MultiOrderErrorPayload);
            const errorCodes = extractErrorsCode(error);

            if (errorCodes.includes('invalid_cart')) {
                const parsedErrors = errorsParser(error.data.extraData, orders, menu);
                dispatch(addOrdersErrors(parsedErrors));
                const rectifiedCarts = computeRectifiedCarts(parsedErrors, orders, brand);

                switch (lastAction?.type) {
                    case 'ADD_PRODUCT': {
                        if (!isProductInError(parsedErrors, lastAction.payload.productId)) {
                            handleAddProductToCart({
                                ...lastAction.payload,
                                rectifiedCarts,
                            });
                        } else {
                            handlePreview(
                                rectifiedCarts.map((rectifiedCart) => ({
                                    ...rectifiedCart,
                                    updatedCart: computeCartPayload(rectifiedCart.cart),
                                })),
                            );
                        }
                        break;
                    }
                    case 'UPDATE_PRODUCT_QUANTITY':
                        handleUpdateProductQuantityInCart({
                            ...lastAction.payload,
                            rectifiedCarts,
                        });
                        break;
                    case 'UPDATE_PROMOCODE':
                        handlePreview(
                            rectifiedCarts.map((c) => ({
                                ...c,
                                updatedCart: computeCartPayload(c.cart),
                            })),
                        );
                        break;
                    default:
                        break;
                }
            } else if (errorCodes.includes('promocode_not_found')) {
                if (stateOrder) {
                    handlePromocodeInCart({
                        order: stateOrder,
                        promocode: '',
                    });
                }
            }
        }
    }, [
        previewOrderError,
        previewMultiOrderError,
        ordersErrors,
        orders,
        menu,
        dispatch,
        lastAction,
        cart,
        handleAddProductToCart,
        handlePreview,
        handleUpdateProductQuantityInCart,
        handlePromocodeInCart,
        stateOrder,
        brand,
        previewMultiOrderIsLoading,
    ]);

    useEffect(() => {
        if (singleOrder) {
            setLastAction(null);
            setFormattedOrder({ [singleOrder.restaurantId]: singleOrder });
        }
    }, [singleOrder, previewOrderError, dispatch, lastAction]);

    useEffect(() => {
        if (multiOrders) {
            const multiOrderFormatted: OrdersState = {};
            multiOrders.verifiedOrders.forEach((currentOrder) => {
                if (currentOrder.cart.length) {
                    multiOrderFormatted[currentOrder.restaurantId] = currentOrder;
                }
            });

            setLastAction(null);
            setFormattedOrder(multiOrderFormatted);
        }
    }, [multiOrders, previewMultiOrderError, dispatch]);

    const isLoading = useMemo(() => {
        return previewOrderIsLoading || previewMultiOrderIsLoading;
    }, [previewOrderIsLoading, previewMultiOrderIsLoading]);

    useEffect(() => {
        if (!isLoading && formattedOrder) {
            updateOrders(formattedOrder);
            dispatch(setCurrentCompositeProduct(null));
        }
    }, [isLoading, formattedOrder, dispatch, ordersErrors, updateOrders]);

    useEffect(() => {
        return () => {
            debouncedUpdateQuantity.cancel();
        };
    }, [debouncedUpdateQuantity]);

    return {
        isCartOperationInProgress,
        setIsCartOperationInProgress,
        handleQuantityChange,
        handlePreview,
        handleAddProductToCart,
        handleUpdateProductQuantityInCart,
        handlePromocodeInCart,
        data: formattedOrder,
        isLoading,
        isError: isPreviewOrderError || isPreviewMultiOrderError,
    };
};
