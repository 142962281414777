import {
    DiscountRecap,
    EnrichedStep,
    EnrichedProduct,
    DISCOUNT_TYPE,
    VerifiedOrder,
} from '@innovorder/order_detail';
import {
    OrderToVerify,
    Discount,
    OrderRecap,
    CartProduct,
    CartStep,
    Grant,
    EntranceFee,
} from 'services/order/order.type';
import { computePrice } from 'utils/price';
import i18n from 'core/InitialiseThirdParties/i18n';
import { computeCartPayload } from 'utils/cart';
import { OrdersState } from 'state/orders';

export const computeOrderToVerify = (order: VerifiedOrder): OrderToVerify => {
    const { recap: _, ...orderToVerify } = order;
    return { ...orderToVerify, cart: computeCartPayload(orderToVerify.cart) };
};

const filterByPromocode = ({ strategy }: DiscountRecap) =>
    strategy.type === DISCOUNT_TYPE.PROMOCODE;

const filterOutPromocodes = ({ strategy }: DiscountRecap) =>
    strategy.type !== DISCOUNT_TYPE.PROMOCODE;

const formatToDiscount = ({ label, amount }: DiscountRecap): Discount => ({
    name: label,
    value: amount,
});

export const computePromoCodeDiscountFromOrderRecap = (
    recap: OrderRecap | undefined,
): Discount[] => {
    if (!recap) {
        return [];
    }

    const promocodes = recap.discounts.filter(filterByPromocode);

    if (!promocodes.length) {
        return [];
    }

    return promocodes.map(formatToDiscount);
};

export const computeEntranceFeesFromOrderRecap = (recap: OrderRecap | undefined): EntranceFee[] => {
    if (!recap) {
        return [];
    }

    if (!recap.fees) {
        return [];
    }

    return recap.fees.map((fee) => ({
        labelTicket: fee.label,
        amount: fee.totalPrice.totalInclTax,
    }));
};

export const computeGrantsFromOrderRecap = (recap: OrderRecap | undefined): Grant[] => {
    if (!recap) {
        return [];
    }

    const grants = recap.discounts.filter(filterOutPromocodes);

    if (!grants) {
        return [];
    }

    return grants.map((grant) => ({
        labelTicket: grant.label,
        amount: grant.amount,
    }));
};

const computeStepsForCartValue = (products: EnrichedProduct[], currency: string): string => {
    const values = products.map(({ name, unitPrice, quantity }) => {
        const formattedQuantityToDisplay = quantity > 1 ? `${quantity}x ` : '';
        return unitPrice > 0
            ? `${formattedQuantityToDisplay}${name} (+${computePrice(i18n, unitPrice, currency)})`
            : `${formattedQuantityToDisplay}${name}`;
    });

    return values.join(', ');
};

const computeNestedStepsForCart = (
    steps: EnrichedStep[] | undefined,
    currency: string,
): string[] => {
    if (!steps) {
        return [];
    }

    return steps.flatMap((step) => {
        const nestedSteps = step.products.flatMap((product) =>
            computeNestedStepsForCart(product.steps, currency),
        );

        return [computeStepsForCartValue(step.products, currency), ...nestedSteps];
    });
};

export const computeStepsForCart = (
    steps: EnrichedStep[] | undefined,
    currency: string,
): CartStep[] => {
    if (!steps) {
        return [];
    }

    const cartSteps: CartStep[] = steps.flatMap((step) => {
        return step.products.flatMap((product) => {
            const nestedSteps = computeNestedStepsForCart(product.steps, currency);

            return {
                value: computeStepsForCartValue([product], currency),
                nestedSteps: nestedSteps.join(', '),
            };
        });
    });

    return cartSteps.filter(({ value }) => !!value);
};

export const computeProductsForCart = (
    cart: EnrichedProduct[],
    currency: string,
    restaurantId?: number,
    menuId?: number,
): CartProduct[] => {
    return cart.map((cartProduct) => ({
        ...cartProduct,
        steps: computeStepsForCart(cartProduct.steps, currency),
        restaurantId,
        menuId,
    }));
};

export const computeOrderProductsForCart = (
    order: VerifiedOrder | undefined | null,
): CartProduct[] => {
    if (!order?.cart || !order.recap) {
        return [];
    }

    return computeProductsForCart(
        order.cart,
        order.recap.currency,
        order.restaurantId,
        order.menuId,
    );
};

export const computeShouldFlushCart = (orders: OrdersState): boolean => {
    if (!orders) {
        return true;
    }

    return !Object.values(orders).reduce((acc, order) => acc + order.recap.products.length, 0);
};
