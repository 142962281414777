import { useActor } from '@xstate/react';
import { useCallback, useContext } from 'react';
import { useAppSelector } from 'state/store';
import { getCrossSellingAlreadyTriggered } from 'state/app';
import { WorkflowStateMachineContext } from './WorkflowStateMachineContext';
import { WorkflowStateStep } from './types';

export const useWorkflowStateMachine = () => {
    const { workflowService } = useContext(WorkflowStateMachineContext);
    const [state, send] = useActor(workflowService);

    const crossSellingAlreadyTriggered = useAppSelector(getCrossSellingAlreadyTriggered);

    const goPrevious = useCallback(() => send({ type: 'PREVIOUS' }), [send]);
    const goNext = useCallback(
        () => send({ type: 'NEXT', crossSellingAlreadyTriggered }),
        [send, crossSellingAlreadyTriggered],
    );
    const reset = useCallback(() => send({ type: 'RESET' }), [send]);
    const goCurrentOrder = useCallback(
        (currentOrderId: string) => send({ type: 'CURRENT_ORDER', currentOrderId }),
        [send],
    );
    const goCart = useCallback(() => send({ type: 'CART' }), [send]);
    const triggerError = useCallback(() => send({ type: 'ERROR' }), [send]);
    const goHome = useCallback(() => send({ type: 'GO_HOME' }), [send]);
    const goLogin = useCallback(
        ({ redirectToState }: { redirectToState?: WorkflowStateStep }) =>
            send({ type: 'LOGIN', redirectToState }),
        [send],
    );
    const goRedirectOrNext = useCallback(() => {
        if (state.event?.redirectToState) {
            send({ type: 'REDIRECT', redirectToState: state.event.redirectToState });
        } else {
            send({ type: 'NEXT' });
        }
    }, [send, state]);

    return {
        state,
        goPrevious,
        goNext,
        reset,
        goCurrentOrder,
        goCart,
        triggerError,
        goHome,
        goLogin,
        goRedirectOrNext,
    };
};
