import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCurrentConsumptionModeType, updateAppState } from 'state/app';
import { resetOrders } from 'state/orders';
import { useAppDispatch, useAppSelector } from 'state/store';
import { convertModeFromUrl } from 'utils/consumptionModes';

export const useConsumptionModeHandler = () => {
    const dispatch = useAppDispatch();
    const paramsConsumptionMode = convertModeFromUrl(useParams().mode);
    const stateConsumptionMode = useAppSelector(getCurrentConsumptionModeType);

    useEffect(() => {
        if (paramsConsumptionMode && stateConsumptionMode !== paramsConsumptionMode) {
            dispatch(resetOrders(undefined));
            dispatch(updateAppState({ currentConsumptionModeType: paramsConsumptionMode }));
        }
    }, [paramsConsumptionMode, stateConsumptionMode, dispatch]);
};
