import { StepperWorkflowStates } from './useCartStepper.types';

export const computeSvyStepperSteps = (
    isFoodCourt: boolean | null,
    isOrderFollowUpEnabled: boolean | undefined,
    isAuthenticated: boolean,
    needManualTableId: boolean,
): StepperWorkflowStates => {
    const steps = [
        { stateValue: 'cart', translation: 'cart.stepper.cart' },
        {
            stateValue: 'additionalInformations',
            translation: 'cart.stepper.additional_informations',
        },
        { stateValue: 'payment', translation: 'cart.stepper.payment' },
        {
            stateValue:
                isFoodCourt || isOrderFollowUpEnabled ? 'orderFollowUp' : 'orderConfirmation',
            translation:
                isFoodCourt || isOrderFollowUpEnabled
                    ? 'cart.stepper.preparation'
                    : 'cart.stepper.summary',
        },
    ];

    const filteredSteps =
        isAuthenticated && !needManualTableId
            ? steps.filter((step) => step.stateValue !== 'additionalInformations')
            : steps;

    return filteredSteps.map((step, index) => ({
        index,
        ...step,
    })) as StepperWorkflowStates;
};
