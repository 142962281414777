import { ApiResponse } from '../providers/innovorder.types';
import { innovorderApi } from '../index';
import {
    Customer,
    GetCustomerBalancePayload,
    PostCustomerForgottenPasswordPayload,
    PutCustomerForgottenPasswordPayload,
} from './customer.type';

const customerApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getCustomerBalance: build.query<{ customerBalance: number }, GetCustomerBalancePayload>({
            query: ({ customerId, token }) => ({
                url: `/customers/${customerId}/balance`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<{ customerBalance: number }>) =>
                response.data,
            extraOptions: { maxRetries: 3 },
        }),
        postForgottenPassword: build.mutation<
            ApiResponse<void>,
            PostCustomerForgottenPasswordPayload
        >({
            query: (payload) => ({
                url: `/customers/forgottenPassword`,
                method: 'POST',
                body: payload,
            }),
            transformResponse: (response: ApiResponse<void>) => response,
            extraOptions: { maxRetries: 3 },
        }),
        putForgottenPassword: build.mutation<Customer, PutCustomerForgottenPasswordPayload>({
            query: (payload) => ({
                url: `/customers/forgottenPassword/${payload.token}/${payload.customerId}`,
                method: 'PUT',
                body: payload,
            }),
            transformResponse: (response: ApiResponse<Customer>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetCustomerBalanceQuery,
    usePostForgottenPasswordMutation,
    usePutForgottenPasswordMutation,
} = customerApi;
