import {
    FormattedMultiOrderError,
    FormattedSingleOrderError,
    OrderInformation,
    OrderVerified,
} from 'services/order/order.type';

export const orderMock = {
    orderId: 135916,
    orderUuid: 'd148bcc4-a67e-43aa-b80a-50f4f78896b3',
    brandId: 10,
    restaurantId: 11,
    channelId: 2,
    menuId: 1796,
    promocodeId: null,
    customerId: null,
    userId: null,
    userName: null,
    addressRawData: null,
    addressId: null,
    cardId: null,
    paymentMean: null,
    ticketNumber: '10_11_2022-09-08_0',
    offlineTicketNumber: null,
    comment: null,
    currency: 'EUR',
    locale: 'fr',
    expectedDate: '2022-09-08T23:00:00.000Z',
    expectedDateEnd: null,
    mainStatus: 8,
    driveStatus: null,
    deliveryStatus: null,
    paymentStatus: null,
    consommationMode: 2,
    forceOnlinePayment: true,
    deliveryFee: null,
    minimumAmountDelivery: null,
    minimumAmountForFreeDelivery: null,
    ttcTotalPrice: 1000,
    vatTotalPrice: 91,
    ttcUpselling: null,
    ttcEntranceFee: 0,
    ttcServiceFee: 0,
    ttcDeliveryFee: 0,
    ttcPromocodeReduction: 0,
    ttcGrantReduction: 0,
    takeAwayReduction: 0,
    ttcTakeAwayReduction: 0,
    ttcRoyaltyReduction: 0,
    ttcLuncheonEligibleAmount: 1000,
    emittedRoyalties: 800,
    ewalletBalanceBeforeOrder: null,
    ewalletBalanceAfterOrder: null,
    entranceFeeRawData: null,
    grantRawData: null,
    pricingRuleRawData: null,
    cartRawData:
        '{"enrichedCart":[{"productId":869209,"quantity":10,"steps":[],"name":"Cheddar","originalUnitPrice":100,"price":1000,"unitPrice":100,"vat":1000,"externalReferences":{},"tags":{"4933":{"tagId":4933,"name":"Extra Cheddar"}},"servingCount":0,"isEligibleLuncheon":true,"isProducible":false}],"version":"enriched-cart-1"}',
    orderRecapRawData:
        '{"currency":"EUR","products":[{"label":"Cheddar","id":869209,"taxRate":1000,"totalPrice":{"totalInclTax":1000,"totalExclTax":909,"totalTax":91,"totalInclTaxWithDiscount":1000,"totalExclTaxWithDiscount":909,"totalTaxWithDiscount":91,"totalInclTaxWithRemise":1000,"totalExclTaxWithRemise":909,"totalTaxWithRemise":91},"unitPrice":{"totalInclTax":100,"totalExclTax":91,"totalTax":9,"totalInclTaxWithDiscount":100,"totalExclTaxWithDiscount":91,"totalTaxWithDiscount":9,"totalInclTaxWithRemise":100,"totalExclTaxWithRemise":91,"totalTaxWithRemise":9},"depth":0,"quantity":10,"additionalData":{"amountWithoutUpsell":"0"},"purchasePrice":null}],"fees":[],"discounts":[],"taxRatesBreakdown":[{"taxRate":1000,"totalPrice":{"totalInclTax":1000,"totalExclTax":909,"totalTax":91,"totalInclTaxWithDiscount":1000,"totalExclTaxWithDiscount":909,"totalTaxWithDiscount":91,"totalInclTaxWithRemise":1000,"totalExclTaxWithRemise":909,"totalTaxWithRemise":91}}],"totalPrice":{"totalInclTax":1000,"totalExclTax":909,"totalTax":91,"totalInclTaxWithDiscount":1000,"totalExclTaxWithDiscount":909,"totalTaxWithDiscount":91,"totalInclTaxWithRemise":1000,"totalExclTaxWithRemise":909,"totalTaxWithRemise":91},"totalLuncheonEligibleAmount":1000,"globalDiscount":{"discountRate":0,"promocode":null},"entranceFeeAmount":0,"grantAmount":0,"turnover":1000,"turnoverWithTaxExcluded":909,"turnoverTotalTax":91,"offTurnover":0}',
    deliveryType: null,
    servingCount: 1,
    cancellationReason: null,
    cancellationReasonDescription: null,
    externalPosOrderId: null,
    ttcOnlinePaymentDiscount: 0,
    onlinePaymentDiscount: 0,
    tableName: null,
    requestIdempotencyKey: '833d72e6-a9e9-4447-8137-07181b0f3fae',
    areaUuid: null,
    metadata: null,
    created_at: '2022-09-07T19:37:21.000Z',
    updated_at: '2022-09-07T19:37:21.000Z',
    orderPayments: [],
    customer: null,
    restaurant: {
        restaurantId: 11,
        name: 'Resto Produit Innovorder',
        delayTakeAway: 20,
        phoneIndicator: null,
        contactPhone: '0442090904',
        contactName: 'bob',
        contactEmail: 'no-reply@innovorder.fr',
        brandId: 10,
        addressId: 480,
        locale: 'fr',
        kitchenDisplayId: 2,
        currency: 'EUR',
        created_at: '2017-01-31T16:28:02.000Z',
        updated_at: '2022-03-25T15:39:34.000Z',
        is_enabled: true,
        legalId: 17,
        ewalletMinimumForScanTray: 0,
        moduleStripeId: 55,
        modulePaygreenId: 10,
        moduleSplioId: 30,
        moduleApetizPlusId: 1,
        externalId: 'restaurantEatNDrinkkCDPF2',
        moduleNeptingId: 3,
        lastShiftClosureHour: null,
        canOrderAsAnonymous: true,
        address: {
            addressId: 480,
            lat: 48.8689168,
            lng: 2.3527634,
            timeZoneId: 'Europe/Paris',
            locality: 'Paris',
            route: 'Rue Blondel',
            postalCode: '75002',
            streetNumber: '30',
            administrativeAreaLevel1: 'Île-de-France',
            administrativeAreaLevel2: 'Département de Paris',
            country: 'France',
            googlePlaceId: 'ChIJ3e6Ykgdu5kcRJXtvh-3JyLU',
            providerId: 'ChIJkzIxGRFu5kcRliBS_bQYIts',
            phone: null,
            additionalInfo: null,
            digiCode: null,
            apartmentNumber: null,
            iconIdentifier: null,
            customerId: null,
            name: '30 Rue Blondel, 75002 Paris, France',
            created_at: '2017-01-31T16:28:02.000Z',
            updated_at: '2020-11-14T06:14:20.000Z',
            deleted_at: null,
        },
    },
    card: null,
    address: null,
    user: null,
    promocode: null,
    stuartJob: [],
    requiredAction: null,
    cart: [{ productId: 1 }],
} as unknown as OrderVerified;

export const getOrder = {
    customerId: null,
    restaurantId: 44,
    brandId: 10,
    status: 'PAID',
    dailyOrderId: 3,
    startDate: new Date().toISOString(),
    lastUpdateDate: '2022-12-08T14:17:37.000Z',
    seller: null,
    deviceId: 'IO_WEB',
    deviceName: 'WEB',
    numberOfGuests: 1,
    consumptionModeId: 3,
    channelId: '2',
    omnichannelOrderId: '138705',
    mainStatus: 1,
    totalAmountFree: 0,
    totalItemFree: 0,
    totalPriceDiscountedWithTaxIncluded: 3400,
    totalDiscount: 0,
    currency: 'EUR',
    totalTax: 309,
    totalPriceDiscountedWithTaxExcluded: 3091,
    businessYear: 2022,
    businessMonth: 12,
    businessDay: 8,
    tableName: null,
    ticketNumber: null,
    comment:
        'Sans coriandre s’il vous plait, et pas très épicé, car je ne supporte pas les ingrédients blabla. Et je veux aussi une petite sauce soja',
    marketplaceStoreName: null,
    service: 1,
    discountEmployeeName: null,
    cancellationReason: null,
    cancellationReasonDescription: null,
    totalAmountCancelled: 0,
    totalItemCancelled: 0,
    turnover: 3400,
    reportOrderId: 157861,
    taxes: [
        {
            taxRate: 1000,
            totalTax: 309,
            totalInclTax: 3400,
            totalExclTax: 3091,
        },
    ],
    pricingRule: null,
    items: [
        {
            itemId: 426818,
            name: 'Cookies Caramel',
            type: 'Product',
            status: 'PAID',
            quantity: 3,
            totalPriceWithTaxIncluded: 900,
            itemType: 'PRODUCT',
            parentItemId: null,
            vatValue: 1000,
            skuValue: null,
            free: false,
            purchasePrice: null,
            billingCode: null,
            discounted: false,
            reduced: false,
            service: 1,
            totalItemDiscounted: 0,
            totalPriceDiscounted: 0,
            cancelReason: null,
            deviceName: 'WEB',
            deviceId: 'IO_WEB',
            tags: [],
        },
        {
            itemId: 426819,
            name: 'EDITION LIMITEE - Crème Vanille de Noël',
            type: 'Product',
            status: 'PAID',
            quantity: 2,
            totalPriceWithTaxIncluded: 1000,
            itemType: 'PRODUCT',
            parentItemId: null,
            vatValue: 1000,
            skuValue: null,
            free: false,
            purchasePrice: null,
            billingCode: null,
            discounted: false,
            reduced: false,
            service: 1,
            totalItemDiscounted: 0,
            totalPriceDiscounted: 0,
            cancelReason: null,
            deviceName: 'WEB',
            deviceId: 'IO_WEB',
            tags: [],
        },
        {
            itemId: 426820,
            name: 'Cake à la Banane',
            type: 'Product',
            status: 'PAID',
            quantity: 2,
            totalPriceWithTaxIncluded: 1000,
            itemType: 'PRODUCT',
            parentItemId: null,
            vatValue: 1000,
            skuValue: null,
            free: false,
            purchasePrice: null,
            billingCode: null,
            discounted: false,
            reduced: false,
            service: 1,
            totalItemDiscounted: 0,
            totalPriceDiscounted: 0,
            cancelReason: null,
            deviceName: 'WEB',
            deviceId: 'IO_WEB',
            tags: [],
        },
        {
            itemId: 426821,
            name: 'Flan Patissier',
            type: 'Product',
            status: 'PAID',
            quantity: 1,
            totalPriceWithTaxIncluded: 500,
            itemType: 'PRODUCT',
            parentItemId: null,
            vatValue: 1000,
            skuValue: null,
            free: false,
            purchasePrice: null,
            billingCode: null,
            discounted: false,
            reduced: false,
            service: 1,
            totalItemDiscounted: 0,
            totalPriceDiscounted: 0,
            cancelReason: null,
            deviceName: 'WEB',
            deviceId: 'IO_WEB',
            tags: [],
        },
    ],
    payments: [
        {
            type: 'Unknown',
            quantity: 1,
            value: 3400,
            luncheonCode: null,
            transactionId: null,
        },
    ],
    customer: null,
    restaurant: {
        name: 'Big Fernandd',
        address: {
            addressId: 993,
            lat: 48.8640152,
            lng: 2.3503204,
            timeZoneId: 'Europe/Paris',
            locality: 'Paris',
            route: 'Rue de Turbigo',
            postalCode: '75002',
            streetNumber: '18',
            administrativeAreaLevel1: 'Île-de-France',
            administrativeAreaLevel2: 'Département de Paris',
            country: 'France',
            googlePlaceId: 'ChIJwRNqpwBy5kcR3MbG0F3jLNQ',
            providerId: 'ChIJrZ0ShBlu5kcRLFkv82Aoea8',
            phone: null,
            additionalInfo: null,
            digiCode: null,
            apartmentNumber: null,
            iconIdentifier: null,
            customerId: null,
            name: '18 R. de Turbigo, 75002 Paris, France',
            created_at: '2017-10-04T13:41:10.000Z',
            updated_at: '2022-06-09T13:08:17.000Z',
            deleted_at: null,
        },
    },
    grants: [],
    entranceFees: [],
    fees: [],
    discounts: [],
    loyaltyPrograms: [],
    webPaymentStatus: 'PAYMENT_OK',
    expectedDate: '2022-12-08T14:22:31.000Z',
    orderUuid: '1a701ef2-0259-4263-a4b2-2418577d2577',
    cartRawData:
        '{"enrichedCart":[{"productId":1260227,"quantity":3,"steps":[],"name":"Cookies Caramel","originalUnitPrice":300,"price":900,"unitPrice":300,"vat":1000,"externalReferences":{},"tags":{},"servingCount":0,"isEligibleLuncheon":true,"isProducible":false},{"productId":1260215,"quantity":2,"steps":[],"name":"EDITION LIMITEE - Crème Vanille de Noël","originalUnitPrice":500,"price":1000,"unitPrice":500,"vat":1000,"externalReferences":{},"tags":{},"servingCount":0,"isEligibleLuncheon":true,"isProducible":false},{"productId":1260223,"quantity":2,"steps":[],"name":"Cake à la Banane","originalUnitPrice":500,"price":1000,"unitPrice":500,"vat":1000,"externalReferences":{},"tags":{},"servingCount":0,"isEligibleLuncheon":true,"isProducible":false},{"productId":1260224,"quantity":1,"steps":[],"name":"Flan Patissier","originalUnitPrice":500,"price":500,"unitPrice":500,"vat":1000,"externalReferences":{},"tags":{},"servingCount":0,"isEligibleLuncheon":true,"isProducible":false}],"version":"enriched-cart-1"}',
    isCancellable: true,
    isRefundable: true,
} as unknown as OrderInformation;

export const singleOrderCartErrorPayload: FormattedSingleOrderError = {
    status: 400,
    code: 'invalid_order_payload',
    message:
        "Le payload de création de commande n'est pas valide. Regardez les données ci-jointes pour en savoir plus.",
    extraData: {
        'trace.id': 'a8889193bc364e6e1d6e1c94d44786e3',
        'transaction.id': '21dba54f4893dfe7',
        errors: [
            {
                status: 400,
                code: 'invalid_cart',
                message:
                    "Le panier n'est pas valide. Regardez les données ci-jointes pour en savoir plus.",
                extraData: {
                    'trace.id': 'a8889193bc364e6e1d6e1c94d44786e3',
                    'transaction.id': '21dba54f4893dfe7',
                    status: 'INVALID',
                    products: [
                        {
                            productId: 1,
                            productCartId: 'abc-123',
                            quantity: 1,
                            steps: [],
                            status: 'INVALID',
                            reasons: [
                                {
                                    status: 400,
                                    code: 'product_not_available_on_web',
                                    message:
                                        'Le produit ne peut pas être commandé depuis la commande en ligne.',
                                    extraData: {
                                        'trace.id': 'a8889193bc364e6e1d6e1c94d44786e3',
                                        'transaction.id': '21dba54f4893dfe7',
                                        productId: 6755058528289265,
                                    },
                                },
                            ],
                        },
                        {
                            productId: 1000,
                            productCartId: 'fc9612f7-18bc-4982-bccf-8e196bcdb195',
                            quantity: 1,
                            steps: [
                                {
                                    stepId: 1477946,
                                    products: [
                                        {
                                            productId: 6755058528288892,
                                            productCartId: '568ab8ae-6149-4c51-bd14-27b7ad8d09e1',
                                            quantity: 1,
                                            steps: [
                                                {
                                                    stepId: 1477947,
                                                    products: [
                                                        {
                                                            productId: 6755058528288913,
                                                            productCartId:
                                                                '5d2eaa06-7675-4157-ae74-cffdd3d34473',
                                                            quantity: 1,
                                                            steps: [],
                                                            status: 'VALID',
                                                            reasons: [],
                                                        },
                                                    ],
                                                    status: 'VALID',
                                                    reasons: [],
                                                },
                                                {
                                                    stepId: 1477948,
                                                    products: [],
                                                    status: 'VALID',
                                                    reasons: [],
                                                },
                                            ],
                                            status: 'VALID',
                                            reasons: [],
                                        },
                                    ],
                                    status: 'VALID',
                                    reasons: [],
                                },
                                {
                                    stepId: 1477949,
                                    products: [
                                        {
                                            productId: 6755058528289166,
                                            productCartId: '765620d8-7f86-461e-9110-7434e9425ab9',
                                            quantity: 1,
                                            steps: [],
                                            status: 'VALID',
                                            reasons: [],
                                        },
                                    ],
                                    status: 'VALID',
                                    reasons: [],
                                },
                                {
                                    stepId: 1477952,
                                    products: [
                                        {
                                            productId: 6755058528289193,
                                            productCartId: '9ff8a954-c28a-4120-8d5b-1c818bc1df2f',
                                            quantity: 1,
                                            steps: [],
                                            status: 'VALID',
                                            reasons: [],
                                        },
                                    ],
                                    status: 'VALID',
                                    reasons: [],
                                },
                                {
                                    stepId: 1477953,
                                    products: [
                                        {
                                            productId: 6755058528289213,
                                            productCartId: '87f38b79-62a8-4d7b-a6b2-b9d68271468c',
                                            quantity: 1,
                                            steps: [],
                                            status: 'VALID',
                                            reasons: [],
                                        },
                                    ],
                                    status: 'VALID',
                                    reasons: [],
                                },
                                {
                                    stepId: 1477950,
                                    products: [],
                                    status: 'VALID',
                                    reasons: [],
                                },
                                {
                                    stepId: 1477947,
                                    products: [
                                        {
                                            productId: 6755058528288901,
                                            productCartId: '6875217d-822c-497e-9829-b9b19980aa7e',
                                            quantity: 1,
                                            steps: [],
                                            status: 'VALID',
                                            reasons: [],
                                        },
                                    ],
                                    status: 'VALID',
                                    reasons: [],
                                },
                            ],
                            status: 'VALID',
                            reasons: [],
                        },
                    ],
                },
            },
        ],
        restaurantId: 1,
    },
};

export const singleSimpleOrderCartErrorPayload: FormattedSingleOrderError = {
    status: 400,
    code: 'invalid_order_payload',
    message:
        "Le payload de création de commande n'est pas valide. Regardez les données ci-jointes pour en savoir plus.",
    extraData: {
        'trace.id': 'a8889193bc364e6e1d6e1c94d44786e3',
        'transaction.id': '21dba54f4893dfe7',
        errors: [
            {
                status: 400,
                code: 'invalid_cart',
                message:
                    "Le panier n'est pas valide. Regardez les données ci-jointes pour en savoir plus.",
                extraData: {
                    'trace.id': 'a8889193bc364e6e1d6e1c94d44786e3',
                    'transaction.id': '21dba54f4893dfe7',
                    status: 'INVALID',
                    products: [
                        {
                            productId: 1,
                            productCartId: 'abc-123',
                            quantity: 1,
                            steps: [],
                            status: 'INVALID',
                            reasons: [
                                {
                                    status: 400,
                                    code: 'product_not_available_on_web',
                                    message:
                                        'Le produit ne peut pas être commandé depuis la commande en ligne.',
                                    extraData: {
                                        'trace.id': 'a8889193bc364e6e1d6e1c94d44786e3',
                                        'transaction.id': '21dba54f4893dfe7',
                                        productId: 6755058528289265,
                                    },
                                },
                            ],
                        },
                    ],
                },
            },
        ],
        restaurantId: 5,
    },
};

export const multiOrderCartErrorPayload: FormattedMultiOrderError = {
    status: 400,
    code: 'invalid_order_payload',
    message:
        'Le payload de création de commande multi-restaurant est pas invalide. Regardez les données ci-jointes pour en savoir plus.',
    extraData: {
        'trace.id': 'c18bc9ad591d123f837695879e8f13b3',
        'transaction.id': '33244a404e5d9fa7',
        errors: [
            {
                isBasicError: true,
                CONSTANT_ERROR: {
                    VALUE: 'invalid_order_payload',
                    STATUS: 400,
                    MESSAGE: {
                        en: 'Something is wrong with this payload of order. Consult the extraData to know what.',
                        fr: "Le payload de création de commande n'est pas valide. Regardez les données ci-jointes pour en savoir plus.",
                    },
                },
                context: {},
                extraMessage: '',
                extraData: {
                    'trace.id': 'c18bc9ad591d123f837695879e8f13b3',
                    'transaction.id': '33244a404e5d9fa7',
                    errors: [
                        {
                            status: 400,
                            code: 'invalid_cart',
                            message:
                                "Le panier n'est pas valide. Regardez les données ci-jointes pour en savoir plus.",
                            extraData: {
                                'trace.id': 'c18bc9ad591d123f837695879e8f13b3',
                                'transaction.id': '33244a404e5d9fa7',
                                status: 'INVALID',
                                products: [
                                    {
                                        productId: 1000,
                                        productCartId: 'b4cc0b7f-b245-4dbd-8c80-f28894b7f14c',
                                        quantity: 1,
                                        steps: [],
                                        status: 'VALID',
                                        reasons: [],
                                    },
                                    {
                                        productId: 1,
                                        productCartId: 'abc-123',
                                        quantity: 1,
                                        steps: [
                                            {
                                                stepId: 1458586,
                                                products: [
                                                    {
                                                        productId: 6755058528119017,
                                                        productCartId:
                                                            'a3c14353-68b5-49c0-bd6e-1ac4d177820f',
                                                        quantity: 1,
                                                        steps: [
                                                            {
                                                                stepId: 1458587,
                                                                products: [
                                                                    {
                                                                        productId: 2001,
                                                                        productCartId:
                                                                            'bea14f2d-c342-4da5-b5f5-2d3d53cd7b45',
                                                                        quantity: 1,
                                                                        steps: [],
                                                                        status: 'INVALID',
                                                                        reasons: [
                                                                            {
                                                                                status: 400,
                                                                                code: 'product_not_available_on_web',
                                                                                message:
                                                                                    'Le produit ne peut pas être commandé depuis la commande en ligne.',
                                                                                extraData: {
                                                                                    'trace.id':
                                                                                        'c18bc9ad591d123f837695879e8f13b3',
                                                                                    'transaction.id':
                                                                                        '33244a404e5d9fa7',
                                                                                    productId: 2001,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                ],
                                                                status: 'INVALID',
                                                                reasons: [],
                                                            },
                                                            {
                                                                stepId: 1458588,
                                                                products: [],
                                                                status: 'VALID',
                                                                reasons: [],
                                                            },
                                                        ],
                                                        status: 'INVALID',
                                                        reasons: [],
                                                    },
                                                ],
                                                status: 'INVALID',
                                                reasons: [],
                                            },
                                            {
                                                stepId: 1458589,
                                                products: [
                                                    {
                                                        productId: 6755058528119025,
                                                        productCartId:
                                                            '3e83a149-8d88-4a02-ae3a-a4bee0159de5',
                                                        quantity: 1,
                                                        steps: [
                                                            {
                                                                stepId: 1458590,
                                                                products: [
                                                                    {
                                                                        productId: 6755058528119028,
                                                                        productCartId:
                                                                            '238aa01e-7486-4605-bbfc-8d7c2ee23547',
                                                                        quantity: 1,
                                                                        steps: [],
                                                                        status: 'VALID',
                                                                        reasons: [],
                                                                    },
                                                                ],
                                                                status: 'VALID',
                                                                reasons: [],
                                                            },
                                                        ],
                                                        status: 'VALID',
                                                        reasons: [],
                                                    },
                                                ],
                                                status: 'VALID',
                                                reasons: [],
                                            },
                                            {
                                                stepId: 1458591,
                                                products: [
                                                    {
                                                        productId: 6755058528119034,
                                                        productCartId:
                                                            '5f11af40-88f2-4640-9265-14cc7a67bbde',
                                                        quantity: 1,
                                                        steps: [],
                                                        status: 'VALID',
                                                        reasons: [],
                                                    },
                                                ],
                                                status: 'VALID',
                                                reasons: [],
                                            },
                                            {
                                                stepId: 1458590,
                                                products: [],
                                                status: 'VALID',
                                                reasons: [],
                                            },
                                        ],
                                        status: 'INVALID',
                                        reasons: [],
                                    },
                                ],
                            },
                        },
                    ],
                    restaurantId: 1,
                },
                name: 'BasicError',
            },
        ],
    },
};

export const mutiOrderErrorWithMultiBasket: FormattedMultiOrderError = {
    status: 400,
    code: 'invalid_order_payload',
    message:
        'Something is wrong with this payload of the multi-order. Consult the extraData to know what.',
    extraData: {
        'trace.id': '245fb9b37625630f2bad6d9835f63da7',
        'transaction.id': '4729894bdd85b65a',
        errors: [
            {
                isBasicError: true,
                CONSTANT_ERROR: {
                    VALUE: 'invalid_order_payload',
                    STATUS: 400,
                    MESSAGE: {
                        en: 'Something is wrong with this payload of order. Consult the extraData to know what.',
                        fr: "Le payload de création de commande n'est pas valide. Regardez les données ci-jointes pour en savoir plus.",
                    },
                },
                context: {},
                extraMessage: '',
                extraData: {
                    'trace.id': '245fb9b37625630f2bad6d9835f63da7',
                    'transaction.id': '4729894bdd85b65a',
                    errors: [
                        {
                            status: 400,
                            code: 'invalid_cart',
                            message:
                                'Something is wrong with this cart. Consult the extraData to know what.',
                            extraData: {
                                'trace.id': '245fb9b37625630f2bad6d9835f63da7',
                                'transaction.id': '4729894bdd85b65a',
                                status: 'INVALID',
                                products: [
                                    {
                                        productId: 2,
                                        productCartId: '2',
                                        quantity: 1,
                                        steps: [],
                                        status: 'INVALID',
                                        reasons: [
                                            {
                                                status: 400,
                                                code: 'product_not_available_on_web',
                                                message:
                                                    'The product cannot be ordered from web ordering.',
                                                extraData: {
                                                    'trace.id': '245fb9b37625630f2bad6d9835f63da7',
                                                    'transaction.id': '4729894bdd85b65a',
                                                    productId: 2,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                    ],
                    restaurantId: 2,
                },
                name: 'BasicError',
            },
        ],
    },
};
export const mutiOrderErrorWith3Baskets: FormattedMultiOrderError = {
    status: 400,
    code: 'invalid_order_payload',
    message:
        'Something is wrong with this payload of the multi-order. Consult the extraData to know what.',
    extraData: {
        'trace.id': '787080ca08de0083af7362fd79d8296b',
        'transaction.id': '8a8a437c5688eec3',
        errors: [
            {
                isBasicError: true,
                CONSTANT_ERROR: {
                    VALUE: 'invalid_order_payload',
                    STATUS: 400,
                    MESSAGE: {
                        en: 'Something is wrong with this payload of order. Consult the extraData to know what.',
                        fr: "Le payload de création de commande n'est pas valide. Regardez les données ci-jointes pour en savoir plus.",
                    },
                },
                context: {},
                extraMessage: '',
                extraData: {
                    'trace.id': '787080ca08de0083af7362fd79d8296b',
                    'transaction.id': '8a8a437c5688eec3',
                    errors: [
                        {
                            status: 400,
                            code: 'invalid_cart',
                            message:
                                'Something is wrong with this cart. Consult the extraData to know what.',
                            extraData: {
                                'trace.id': '787080ca08de0083af7362fd79d8296b',
                                'transaction.id': '8a8a437c5688eec3',
                                status: 'INVALID',
                                products: [
                                    {
                                        productId: 2,
                                        productCartId: '2',
                                        quantity: 1,
                                        steps: [],
                                        status: 'INVALID',
                                        reasons: [
                                            {
                                                status: 400,
                                                code: 'product_not_available_on_web',
                                                message:
                                                    'The product cannot be ordered from web ordering.',
                                                extraData: {
                                                    'trace.id': '787080ca08de0083af7362fd79d8296b',
                                                    'transaction.id': '8a8a437c5688eec3',
                                                    productId: 6755058528119017,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                    ],
                    restaurantId: 2,
                },
                name: 'BasicError',
            },
            {
                isBasicError: true,
                CONSTANT_ERROR: {
                    VALUE: 'invalid_order_payload',
                    STATUS: 400,
                    MESSAGE: {
                        en: 'Something is wrong with this payload of order. Consult the extraData to know what.',
                        fr: "Le payload de création de commande n'est pas valide. Regardez les données ci-jointes pour en savoir plus.",
                    },
                },
                context: {},
                extraMessage: '',
                extraData: {
                    'trace.id': '787080ca08de0083af7362fd79d8296b',
                    'transaction.id': '8a8a437c5688eec3',
                    errors: [
                        {
                            status: 400,
                            code: 'invalid_cart',
                            message:
                                'Something is wrong with this cart. Consult the extraData to know what.',
                            extraData: {
                                'trace.id': '787080ca08de0083af7362fd79d8296b',
                                'transaction.id': '8a8a437c5688eec3',
                                status: 'INVALID',
                                products: [
                                    {
                                        productId: 3,
                                        productCartId: '3',
                                        quantity: 1,
                                        steps: [],
                                        status: 'INVALID',
                                        reasons: [
                                            {
                                                status: 400,
                                                code: 'product_not_available_on_web',
                                                message:
                                                    'The product cannot be ordered from web ordering.',
                                                extraData: {
                                                    'trace.id': '787080ca08de0083af7362fd79d8296b',
                                                    'transaction.id': '8a8a437c5688eec3',
                                                    productId: 6755058528041981,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                    ],
                    restaurantId: 3,
                },
                name: 'BasicError',
            },
        ],
    },
};
