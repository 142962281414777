import styled from '@emotion/styled';
import { SvyBadge } from '@innovorder/savory';

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: ${({ theme }) => theme.base.spacing[2]};
`;

export const OrderNumber = styled.div`
    display: flex;
    justify-content: space-between;
    margin: ${({ theme }) => theme.base.spacing[2]} 0;
    align-items: center;
`;

export const StyledBadgeOrderNumber = styled(SvyBadge)`
    && {
        ${({ theme }) => theme.base.typography['Body/Bold/Text3']}
    }
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 80vh;
`;
