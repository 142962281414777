import { routes } from 'core/routes';
import { WorkflowStateContext, WorkflowStateEvent } from 'hooks/useWorkflowStateMachine/types';
import { assign, createMachine } from 'xstate';

type WorkflowMachineProps = {
    isFoodCourt: boolean | null;
    isOrderFollowUpEnabled: boolean | undefined;
    canAuthenticate: boolean;
    hasToAuthenticate: boolean;
};

export const resetHasRedirectOnLogin = assign({
    hasRedirectOnLogin: () => false,
});

export const computeWorkflowStateMachine = ({
    isFoodCourt,
    isOrderFollowUpEnabled,
    canAuthenticate,
    hasToAuthenticate,
}: WorkflowMachineProps) =>
    createMachine<WorkflowStateContext, WorkflowStateEvent>({
        initial: 'home',
        predictableActionArguments: true,
        context: {
            isAuthenticated: false,
            hasRedirectOnLogin: false,
            shouldDisplayAdditionalInformations: false,
        },
        on: {
            INIT: routes.map(({ state }) => ({
                target: state,
                cond: (_context: WorkflowStateContext, event: WorkflowStateEvent) =>
                    event.state === state,
            })),
            RESET: {
                target: isFoodCourt ? 'restaurants' : 'menuHome',
                actions: isFoodCourt ? ['navigateToRestaurants'] : ['navigateToMenuHome'],
            },
            CURRENT_ORDER: {
                target:
                    isFoodCourt || isOrderFollowUpEnabled ? 'orderFollowUp' : 'orderConfirmation',
                actions:
                    isFoodCourt || isOrderFollowUpEnabled
                        ? ['navigateToOrderFollowUp']
                        : ['navigateToOrderConfirmation'],
            },
            CART: {
                target: 'cart',
                actions: ['navigateToCart'],
            },
            UPDATE_AUTH: {
                actions: assign({
                    isAuthenticated: (_context, event) => !!event.isAuthenticated,
                }),
            },
            UPDATE_ADDITIONAL_INFORMATIONS: {
                actions: assign({
                    shouldDisplayAdditionalInformations: (_context, event) =>
                        !!event.shouldDisplayAdditionalInformations,
                }),
            },
            GO_HOME: {
                target: 'home',
                actions: ['navigateToHome'],
            },
            LOGIN: {
                target: 'login',
                actions: [
                    assign({
                        hasRedirectOnLogin: (_context, event) => !!event.redirectToState,
                    }),
                    'navigateToLogin',
                ],
            },
            REDIRECT: {
                actions: ['navigateToState'],
            },
        },
        states: {
            home: {
                on: {
                    NEXT: isFoodCourt
                        ? [
                              {
                                  target: 'login',
                                  actions: ['navigateToLogin'],
                                  cond: (context, _event) =>
                                      !!canAuthenticate && !context.isAuthenticated,
                              },
                              {
                                  target: 'restaurants',
                                  actions: ['navigateToRestaurants'],
                                  cond: (context, _event) =>
                                      !canAuthenticate || context.isAuthenticated,
                              },
                          ]
                        : [
                              {
                                  target: 'login',
                                  actions: ['navigateToLogin'],
                                  cond: (context, _event) =>
                                      !!canAuthenticate && !context.isAuthenticated,
                              },
                              {
                                  target: 'menuHome',
                                  actions: ['navigateToMenuHome'],
                                  cond: (context, _event) =>
                                      !canAuthenticate || context.isAuthenticated,
                              },
                          ],
                },
            },
            login: {
                always: [
                    {
                        target: 'restaurants',
                        actions: ['navigateToRestaurants'],
                        cond: (context) =>
                            !!isFoodCourt &&
                            (!!context.isAuthenticated || !canAuthenticate) &&
                            /** check if there is no existing redirection before triggering the "always" one */
                            !context.hasRedirectOnLogin,
                    },
                    {
                        target: 'menuHome',
                        actions: ['navigateToMenuHome'],
                        cond: (context) =>
                            !isFoodCourt &&
                            (!!context.isAuthenticated || !canAuthenticate) &&
                            /** check if there is no existing redirection before triggering the "always" one */
                            !context.hasRedirectOnLogin,
                    },
                ],
                on: {
                    PREVIOUS: {
                        target: 'home',
                        actions: ['navigateToHome'],
                    },
                    NEXT: {
                        target: isFoodCourt ? 'restaurants' : 'menuHome',
                        actions: isFoodCourt ? ['navigateToRestaurants'] : ['navigateToMenuHome'],
                    },
                },
            },
            restaurants: {
                entry: ['resetHasRedirectOnLogin'],
                on: {
                    PREVIOUS: {
                        target: 'home',
                        actions: ['navigateToHome'],
                    },
                    NEXT: {
                        target: 'menuHome',
                        actions: ['navigateToMenuHome'],
                    },
                },
            },
            menuHome: {
                entry: ['resetHasRedirectOnLogin'],
                on: {
                    PREVIOUS: {
                        target: isFoodCourt ? 'restaurants' : 'home',
                        actions: isFoodCourt ? ['navigateToRestaurants'] : ['navigateToHome'],
                    },
                    NEXT: {
                        target: 'cart',
                        actions: ['navigateToCart'],
                    },
                },
            },
            cart: {
                entry: ['resetHasRedirectOnLogin'],
                on: {
                    PREVIOUS: {
                        target: 'menuHome',
                        actions: ['navigateToMenuHome'],
                    },
                    NEXT: isFoodCourt
                        ? [
                              {
                                  target: 'crossSelling',
                                  actions: ['navigateToCrossSelling'],
                                  cond: (_context, event) => !event.crossSellingAlreadyTriggered,
                              },
                              {
                                  target: 'additionalInformations',
                                  actions: ['navigateToAdditionalInformations'],
                                  cond: (context, event) =>
                                      !!event.crossSellingAlreadyTriggered &&
                                      (!canAuthenticate ||
                                          !context.isAuthenticated ||
                                          context.shouldDisplayAdditionalInformations),
                              },

                              {
                                  target: 'payment',
                                  actions: ['navigateToChoosePayment'],
                                  cond: (context, event) =>
                                      !!event.crossSellingAlreadyTriggered &&
                                      !!canAuthenticate &&
                                      context.isAuthenticated,
                              },
                          ]
                        : [
                              {
                                  target: 'additionalInformations',
                                  actions: ['navigateToAdditionalInformations'],
                                  cond: (context, _event) =>
                                      !canAuthenticate ||
                                      !context.isAuthenticated ||
                                      context.shouldDisplayAdditionalInformations,
                              },
                              {
                                  target: 'payment',
                                  actions: ['navigateToChoosePayment'],
                                  cond: (context, _event) =>
                                      !!canAuthenticate &&
                                      context.isAuthenticated &&
                                      !context.shouldDisplayAdditionalInformations,
                              },
                          ],
                },
            },
            crossSelling: {
                entry: ['resetHasRedirectOnLogin'],
                on: {
                    NEXT: [
                        {
                            target: 'additionalInformations',
                            actions: ['navigateToAdditionalInformations'],
                            cond: (context, _event) =>
                                !canAuthenticate ||
                                !context.isAuthenticated ||
                                context.shouldDisplayAdditionalInformations,
                        },
                        {
                            target: 'payment',
                            actions: ['navigateToChoosePayment'],
                            cond: (context, _event) =>
                                !!canAuthenticate &&
                                context.isAuthenticated &&
                                !context.shouldDisplayAdditionalInformations,
                        },
                    ],
                },
            },
            additionalInformations: {
                entry: ['resetHasRedirectOnLogin'],
                always: [
                    {
                        target: 'payment',
                        actions: ['navigateToChoosePayment'],
                        cond: (context) =>
                            !!canAuthenticate &&
                            context.isAuthenticated &&
                            !context.shouldDisplayAdditionalInformations,
                    },
                ],
                on: {
                    PREVIOUS: {
                        target: 'cart',
                        actions: ['navigateToCart'],
                    },
                    NEXT: {
                        target: 'payment',
                        actions: ['navigateToChoosePayment'],
                    },
                },
            },
            payment: {
                entry: ['resetHasRedirectOnLogin'],
                always: [
                    {
                        target: 'home',
                        actions: ['navigateToHome'],
                        cond: (context) => !!hasToAuthenticate && !context.isAuthenticated,
                    },
                ],
                on: {
                    PREVIOUS: [
                        {
                            target: 'additionalInformations',
                            actions: ['navigateToAdditionalInformations'],
                            cond: (context, _event) =>
                                !canAuthenticate ||
                                !context.isAuthenticated ||
                                context.shouldDisplayAdditionalInformations,
                        },
                        {
                            target: 'cart',
                            actions: ['navigateToCart'],
                            cond: (context, _event) =>
                                !!canAuthenticate &&
                                context.isAuthenticated &&
                                !context.shouldDisplayAdditionalInformations,
                        },
                    ],
                    NEXT: {
                        target:
                            isFoodCourt || isOrderFollowUpEnabled
                                ? 'orderFollowUp'
                                : 'orderConfirmation',
                        actions:
                            isFoodCourt || isOrderFollowUpEnabled
                                ? ['navigateToOrderFollowUp']
                                : ['navigateToOrderConfirmation'],
                    },
                },
            },
            orderConfirmation: {
                always: [
                    {
                        target: 'home',
                        actions: ['navigateToHome'],
                        cond: (context) => !!hasToAuthenticate && !context.isAuthenticated,
                    },
                ],
                on: {
                    ERROR: {
                        target: 'payment',
                    },
                },
            },
            orderFollowUp: {
                always: [
                    {
                        target: 'home',
                        actions: ['navigateToHome'],
                        cond: (context) => !!hasToAuthenticate && !context.isAuthenticated,
                    },
                ],
                on: {
                    ERROR: {
                        target: 'payment',
                    },
                },
            },
            notFound: {},
        },
    });
