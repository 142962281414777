import { WithHeader, Loader, Title } from 'components';
import { LoaderContainer } from 'components/Loader/Loader.style';
import { useOrdersHistoryVM } from './useOrdersHistoryVM';
import { StyledList } from './OrdersHistory.style';
import { OrderItem } from './OrderItem';

const OrdersHistory: React.FunctionComponent = () => {
    const { goBack, orders, isLoading, hasNextPage, nextPageRef } = useOrdersHistoryVM();

    return (
        <WithHeader
            isSticky={true}
            headerContent={<Title type={5} text={'orders_history.title'} />}
            onClickBack={goBack}
        >
            {orders && orders.length > 0 && (
                <StyledList>
                    {orders.map((order) => (
                        <OrderItem key={order.omnichannelOrderId} order={order} />
                    ))}
                </StyledList>
            )}
            {isLoading && !orders && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            <div ref={nextPageRef}>
                {hasNextPage && (
                    <LoaderContainer>
                        <Loader />
                    </LoaderContainer>
                )}
            </div>
        </WithHeader>
    );
};

export default OrdersHistory;
