import { CartItemDisplayerProps } from 'components/CartItemDisplayer/CartItemDisplayer';
import { TranslationKey } from 'locales/fr';
import { OrdersStatusState } from 'state/ordersStatus/ordersStatus.slice';
import { MultiOrderInformations } from 'services/multi-order/multi-order.type';
import { FollowUpStatus, OrderMainStatus } from 'services/order/order.type';

export type BadgeStatusType = 'neutral' | 'destructive' | 'success' | 'warning' | 'primary';

export type OrdersStatusInformation = {
    [orderId: string]: {
        badgeType: BadgeStatusType;
        text: TranslationKey;
    };
};

export const computeOrdersStatusInformation = (
    restaurantOrders: CartItemDisplayerProps['restaurantOrders'],
    ordersStatus: OrdersStatusState,
): OrdersStatusInformation => {
    return Object.fromEntries(
        restaurantOrders.map(({ orderId, mainStatus }) => {
            if (!ordersStatus || !orderId || !ordersStatus[orderId]) {
                if (
                    mainStatus === OrderMainStatus.ORDER_CANCELLED ||
                    mainStatus === OrderMainStatus.ORDER_REFUSED
                ) {
                    return [orderId, { badgeType: 'neutral', text: 'ticket_status_cancelled' }];
                }

                // orders already processed and collected
                return [orderId, undefined];
            }

            const { status } = ordersStatus[orderId];

            if (status === 'LOADING') {
                return [orderId, undefined];
            }

            const translationKey: TranslationKey = `ticket_status_${status
                .toString()
                .toLocaleLowerCase()}` as TranslationKey;

            return [
                orderId,
                {
                    badgeType: mapTicketStatusToBadgeType(status),
                    text: translationKey,
                },
            ];
        }),
    );
};

export const mapTicketStatusToBadgeType = (ticketStatus: FollowUpStatus): BadgeStatusType => {
    switch (ticketStatus) {
        case 'COLLECTED':
            return 'primary';
        case 'READY':
            return 'success';
        case 'PROCESSING':
            return 'warning';
        case 'WAITING':
            return 'destructive';
        case 'CANCELLED':
            return 'neutral';
        default:
            return 'neutral';
    }
};

export const isOrderOlderThan24hours = (orderDateString: string) => {
    const orderCreationDate = new Date(orderDateString);
    const now = new Date();
    return now.getTime() - orderCreationDate.getTime() >= 1000 * 60 * 60 * 24;
};

export const isMultiOrderAlreadyProcessed = (multiOrder: MultiOrderInformations) => {
    return multiOrder.orders.every(({ mainStatus }) =>
        [
            OrderMainStatus.ORDER_PROCESSED,
            OrderMainStatus.ORDER_CANCELLED,
            OrderMainStatus.ORDER_REFUSED,
        ].includes(mainStatus),
    );
};
