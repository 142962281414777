import { SvyPassword } from '@innovorder/savory';
import { useTranslation } from 'react-i18next';
import { MargedTitle, MargedText, ButtonSubmit, MargedInputContainer } from '../Login.style';
import { useResetPasswordVM } from './useResetPasswordVM';

const ResetPassword: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        password,
        confirmPassword,
        handleUpdatePassword,
        handleUpdateConfirmPassword,
        isPasswordValid,
        passwordValidationError,
        arePasswordsIdentical,
        handleSubmit,
        isLoading,
        isError,
        canSubmit,
    } = useResetPasswordVM();

    return (
        <>
            <MargedTitle text={'reset_password_page.title'} type={4}></MargedTitle>
            <MargedText
                text={'reset_password_page.description'}
                type={2}
                color="secondaryTextColor"
            />

            <MargedInputContainer hasError={!isPasswordValid}>
                <SvyPassword
                    value={password}
                    hasError={!isPasswordValid}
                    onChange={handleUpdatePassword}
                    label={t('reset_password_page.new_password')}
                    labelDetail="*"
                    placeholder={t('reset_password_page.placeholder')}
                    required
                    data-testid="password-input"
                    helperText={
                        !isPasswordValid && passwordValidationError
                            ? t(passwordValidationError)
                            : undefined
                    }
                />
            </MargedInputContainer>

            <MargedInputContainer hasError={!arePasswordsIdentical}>
                <SvyPassword
                    value={confirmPassword}
                    hasError={!arePasswordsIdentical}
                    onChange={handleUpdateConfirmPassword}
                    label={t('reset_password_page.confirm_new_password')}
                    labelDetail="*"
                    placeholder={t('reset_password_page.placeholder')}
                    required
                    data-testid="confirm-password-input"
                    helperText={
                        !arePasswordsIdentical
                            ? t('reset_password_page.error_not_identical')
                            : undefined
                    }
                />
            </MargedInputContainer>

            {isError && (
                <MargedText
                    type={3}
                    color="errorColor"
                    text={'forgotten_password_page.error_generic'}
                />
            )}

            <ButtonSubmit
                onClick={handleSubmit}
                isLoading={isLoading}
                disabled={!canSubmit}
                data-testid="confirm-btn"
                size="m"
            >
                {t('reset_password_page.confirm')}
            </ButtonSubmit>
        </>
    );
};

export default ResetPassword;
