import React from 'react';
import { Button, Text, Title, BottomBar, Loader } from 'components';
import { SVGIcon, SVGIcons } from 'components/SvgIcon/SVGIcon';
import { LoaderContainer } from 'components/Loader/Loader.style';
import {
    ErrorContainer,
    Container,
    CardContent,
    TitleContainer,
    SuccessContainer,
    BalanceContainer,
} from './EdenredAuth.style';
import { useEdenredAuthVM } from './useEdenredAuthVM';

export type EdenredAuthProps = {
    handleEdenredPaymentSubmit: () => void;
    isLoading?: boolean;
};

const EdenredAuth: React.FunctionComponent<EdenredAuthProps> = ({
    handleEdenredPaymentSubmit,
    isLoading,
}) => {
    const {
        balanceWithCurrency,
        buttonText,
        isLoadingAuthUrl,
        isLoadingBalance,
        authenticateToEdenred,
        error,
        isAuthenticated,
    } = useEdenredAuthVM();

    return (
        <Container>
            <CardContent>
                <TitleContainer>
                    <SVGIcon icon={SVGIcons.EDENRED} />
                    <Title text={'edenred.authentication.title'} type={5} />
                </TitleContainer>
                {!isAuthenticated &&
                    (isLoadingBalance ? (
                        <LoaderContainer>
                            <Loader />
                        </LoaderContainer>
                    ) : (
                        <>
                            <Text text={'edenred.authentication.description'} />
                            <Button onClick={authenticateToEdenred} isLoading={isLoadingAuthUrl}>
                                {buttonText.link}
                            </Button>
                        </>
                    ))}
                {isAuthenticated && (
                    <div>
                        <SuccessContainer>
                            <Text text={'edenred.authentication.success_message'} />
                            <SVGIcon icon={SVGIcons.CHECK} />
                        </SuccessContainer>
                        {balanceWithCurrency && (
                            <BalanceContainer>
                                <Text text={'edenred.authentication.there_is'} />
                                <Text noTranslation text={balanceWithCurrency} weight="Bold" />
                                <Text text={'edenred.authentication.on_your_daily_balance'} />
                            </BalanceContainer>
                        )}
                    </div>
                )}
                {error && (
                    <ErrorContainer>
                        <Text text={'edenred.authentication.error'} />
                    </ErrorContainer>
                )}
            </CardContent>

            <BottomBar withPadding>
                <Button
                    fullWidth
                    onClick={handleEdenredPaymentSubmit}
                    disabled={!isAuthenticated}
                    isLoading={isLoading}
                    size="l"
                >
                    {buttonText.confirm}
                </Button>
            </BottomBar>
        </Container>
    );
};

export default EdenredAuth;
