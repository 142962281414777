// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker/locale/fr';
import {
    CartProduct,
    ChannelId,
    FormattedMultiOrderError,
    FormattedSingleOrderError,
    Item,
    OrderCreated,
    OrderInformation,
    OrderItemType,
    OrderToVerify,
    OrderVerified,
    ProductDTO,
    ProductRecap,
    ReportItemStatus,
    ReportItemType,
    StepDTO,
} from 'services/order/order.type';
import {
    orderRecapFixtureFactory,
    priceBreakdownFixtureFactory,
} from '@innovorder/order_detail/dist/fixture_factory/order_recap.fixture_factory';
import {
    ConsumptionModeType,
    DISCOUNTABLE_TYPE,
    DiscountRecap,
    EnrichedProduct,
    EnrichedStep,
    VerifiedOrder,
} from '@innovorder/order_detail';
import { getOrder, multiOrderCartErrorPayload, singleOrderCartErrorPayload } from './mock';

export {
    orderRecapFixtureFactory,
    priceBreakdownFixtureFactory,
} from '@innovorder/order_detail/dist/fixture_factory/order_recap.fixture_factory';

faker.locale = 'fr';

export const orderStepFixtureFactory = (params: Partial<StepDTO> = {}): StepDTO => {
    const defaultValues: StepDTO = {
        stepId: 1,
        products: [],
    };

    return { ...defaultValues, ...params };
};

export const orderProductFixtureFactory = (params: Partial<ProductDTO> = {}): ProductDTO => {
    const defaultValues: ProductDTO = {
        productId: 1,
        productCartId: 'abc-123',
        quantity: ChannelId.WEB,
        steps: [],
    };

    return { ...defaultValues, ...params };
};

export const enrichedStepFixtureFactory = (params: Partial<EnrichedStep> = {}): EnrichedStep => {
    const defaultValues: EnrichedStep = {
        stepId: 1,
        name: faker.random.alphaNumeric(10),
        products: [],
        ticketLabel: 'ticket-label',
    };

    return { ...defaultValues, ...params };
};

export const enrichedProductFixtureFactory = (
    params: Partial<EnrichedProduct> = {},
): EnrichedProduct => {
    const defaultValues: EnrichedProduct = {
        productId: 1,
        productCartId: 'abc-123',
        name: faker.random.alphaNumeric(10),
        quantity: 1,
        price: 1,
        originalUnitPrice: 1,
        unitPrice: 1,
        vat: 100,
        steps: [],
        tags: [],
        externalReferences: {},
        servingCount: 10,
        isEligibleLuncheon: true,
        isProducible: true,
    };

    return { ...defaultValues, ...params };
};

export const cartProductFixtureFactory = (params: Partial<CartProduct> = {}): CartProduct => {
    const defaultValues: CartProduct = {
        productId: 1,
        productCartId: 'abc-123',
        name: faker.random.alphaNumeric(10),
        quantity: 1,
        price: 1,
        unitPrice: 1,
        steps: [],
        imageUrl: undefined,
        restaurantId: undefined,
        menuId: undefined,
    };

    return { ...defaultValues, ...params };
};

export const orderProductRecapFixtureFactory = (
    params: Partial<ProductRecap> = {},
): ProductRecap => {
    const defaultValues: ProductRecap = {
        label: 'product 1',
        id: 1,
        productCartId: 'abc-123',
        taxRate: 1000,
        totalPrice: priceBreakdownFixtureFactory(params.totalPrice),
        unitPrice: priceBreakdownFixtureFactory(params.unitPrice),
        depth: 0,
        quantity: 1,
        tags: [],
        orderItemType: DISCOUNTABLE_TYPE.PRODUCT,
    };

    return { ...defaultValues, ...params };
};

export const orderToVerifyFixtureFactory = (params: Partial<OrderToVerify> = {}): OrderToVerify => {
    const defaultValues: OrderToVerify = {
        restaurantId: 1,
        channelId: ChannelId.WEB,
        consumptionMode: ConsumptionModeType.MODE_SIT_IN,
        menuId: 1,
        cart: [],
        promocode: '',
        comment: '',
    };

    return { ...defaultValues, ...params };
};

export const orderVerifiedFixtureFactory = (
    params: Partial<OrderVerified> = {},
    discountRecapParams: Partial<DiscountRecap>[] | undefined = undefined,
): VerifiedOrder => {
    const defaultValues: VerifiedOrder = {
        restaurantId: 1,
        channelId: ChannelId.WEB,
        consumptionMode: ConsumptionModeType.MODE_SIT_IN,
        menuId: 1,
        cart: [],
        promocode: '',
        comment: '',
        recap: orderRecapFixtureFactory({
            discountRecapParams,
        }),
        restaurantAttributes: {
            restaurantId: 1,
            name: 'restaurant 1',
        },
    };

    return { ...defaultValues, ...params };
};

export const orderCreatedFixtureFactory = (params: Partial<OrderCreated> = {}): OrderCreated => {
    const defaultValues: OrderCreated = {
        orderId: 1,
        orderUuid: 'abc-123',
        requiredAction: null,
        mainStatus: null,
        ...orderVerifiedFixtureFactory(params),
    };

    return { ...defaultValues, ...params };
};

export const reportItemFixtureFactory = (params: Partial<Item> = {}): Item => {
    const parameters: Item = {
        itemId: faker.datatype.number(),
        parentItemId: faker.datatype.number(),
        name: faker.random.alphaNumeric(5),
        type: OrderItemType.PRODUCT,
        itemType: ReportItemType.PRODUCT,
        status: ReportItemStatus.PAID,
        tags: [],
        quantity: 1,
        cancelReason: null,
        free: false,
        discounted: false,
        totalItemDiscounted: 0,
        totalPriceDiscounted: 0,
        reduced: false,
        vatValue: faker.datatype.number(100),
        service: faker.datatype.number(3),
        deviceId: faker.random.alphaNumeric(10),
        deviceName: faker.commerce.department(),
        totalPriceWithTaxIncluded: 0,
        skuValue: faker.random.alphaNumeric(5),
        purchasePrice: faker.datatype.number({ min: 1, max: 12 }),
        billingCode: faker.random.alphaNumeric(5),
        ...params,
    };

    return parameters;
};

export const omnicanalOrderFixtureFactory = (
    params: Partial<OrderInformation> = {},
): OrderInformation => {
    const parameters: OrderInformation = {
        ...({ ...getOrder } as OrderInformation),
        orderUuid: faker.datatype.uuid(),
        dailyOrderId: faker.datatype.number(),
        totalPriceDiscountedWithTaxExcluded: faker.datatype.number(),
        totalPriceDiscountedWithTaxIncluded: faker.datatype.number(),
        totalTax: faker.datatype.number(1000),
        ...params,
    };

    return parameters;
};

export const singleOrderCartErrorFixtureFactory = (
    params?: Partial<FormattedSingleOrderError['extraData']>,
): FormattedSingleOrderError['extraData'] => {
    return {
        ...JSON.parse(JSON.stringify(singleOrderCartErrorPayload.extraData)),
        ...params,
    };
};

export const multiOrderCartErrorFixtureFactory = (
    params?: Partial<FormattedMultiOrderError['extraData']>,
): FormattedMultiOrderError['extraData'] => {
    return {
        ...JSON.parse(JSON.stringify(multiOrderCartErrorPayload.extraData)),
        ...params,
    };
};
