import { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'state/store';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { hasExternalPricingRulesCustomerId, getAuthenticationType } from 'state/brand/brand.slice';
import { getNeedManualTableId, getTableId, updateAppState } from 'state/app';
import { getAccessToken, getCustomerName, updateCustomer } from 'state/customer/customer.slice';

export const useAdditionalInformationsVM = () => {
    const CUSTOMER_NAME_LENGTH_MIN = 2;
    const dispatch = useDispatch();
    const customerName = useAppSelector(getCustomerName);
    const { goNext } = useWorkflowStateMachine();
    const hasExternalCustomer = useAppSelector(hasExternalPricingRulesCustomerId);
    const authenticationType = useAppSelector(getAuthenticationType);
    const tableNumber = useAppSelector(getTableId);
    const needManualTableId = useAppSelector(getNeedManualTableId);
    const isAuthenticated = useAppSelector(getAccessToken);

    const shouldDisplayExternalCustomer =
        hasExternalCustomer && authenticationType === 'Hybrid' && !isAuthenticated;

    const { t } = useTranslation();

    const handleUpdateCustomerName = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(
                updateCustomer({
                    customerName: event.target.value,
                }),
            );
        },
        [dispatch],
    );

    const handleUpdateTableNumber = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(
                updateAppState({
                    tableId: event.target.value !== '' ? parseInt(event.target.value, 10) : null,
                }),
            );
        },
        [dispatch],
    );

    return {
        buttonText: t('go_to_payment'),
        customerName,
        tableNumber,
        handleUpdateCustomerName,
        handleUpdateTableNumber,
        needManualTableId,
        goNext,
        CUSTOMER_NAME_LENGTH_MIN,
        shouldDisplayExternalCustomer,
        isAuthenticated,
    };
};
