import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSvyBadge } from './TableNumberBadge.style';

type TableNumberBadgeProps = {
    tableNumber: number;
};

export const TableNumberBadge: React.FunctionComponent<TableNumberBadgeProps> = ({
    tableNumber,
}) => {
    const { t } = useTranslation();

    return (
        <StyledSvyBadge
            label={t('menu_home_table_number', {
                tableNumber,
            })}
            data-testid="table-number"
        ></StyledSvyBadge>
    );
};
