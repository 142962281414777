import { useAppSelector } from 'state/store';
import { getIsFoodCourt, getRestaurantId } from 'state/app';
import { getStripeUserId } from 'state/brand';
import { useState, useEffect } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { ENV } from 'utils/env';
import { getOrders } from 'state/orders';

export const useStripeRequireActionVM = () => {
    const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
    const stateRestaurantId = useAppSelector(getRestaurantId);
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const orders = useAppSelector(getOrders);
    const firstOrdersRestaurantId = orders && Number(Object.keys(orders)[0]);

    /**
     * In mono restaurant we just get the restaurantId in the app state
     * In food court mode we get the restaurantId of the 1st order
     * Why ? Because we just need one restaurantId and all the restaurants are configured with the same stripe (for now...)
     * @TODO clean the restaurantId logic
     */
    const restaurantId = isFoodCourt ? firstOrdersRestaurantId : stateRestaurantId;
    const stripeUserId = useAppSelector(getStripeUserId(restaurantId as number));

    useEffect(() => {
        const initStripePromise = async () => {
            if (stripeUserId) {
                const stripeLoaded = await loadStripe(ENV.STRIPE_PUBLIC_KEY, {
                    stripeAccount: stripeUserId,
                });
                setStripePromise(stripeLoaded);
            }
        };
        initStripePromise();
    }, [stripeUserId]);

    return { stripePromise };
};
