import { createSlice, PayloadAction, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { MenuHashMap, ProductAttributesWithRelations } from 'services/menu/menu.type';
import { RootState } from '../store';

export const menuSliceName = 'menu';

export type MenuState = MenuHashMap | null;

const initialState: MenuState = null;

export const menuSlice = createSlice<
    MenuState,
    SliceCaseReducers<MenuState>,
    string,
    SliceSelectors<MenuState>
>({
    name: menuSliceName,
    initialState,
    reducers: {
        updateMenuState: (state, action: PayloadAction<MenuState>) => action.payload,
    },
});

export const getMenuState = (state: RootState): MenuState => state.menu;
export const getProductById =
    (productId: number) =>
    (state: RootState): ProductAttributesWithRelations | undefined =>
        getMenuState(state)?.products[productId];

export const { updateMenuState } = menuSlice.actions;
