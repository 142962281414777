import { Input } from 'components';
import { useTranslation } from 'react-i18next';
import { useForgottenPasswordVM } from './useForgottenPasswordVM';
import {
    MargedTitle,
    MargedText,
    MargedInputContainer,
    ButtonSubmit,
    BottomButton,
} from '../Login.style';

const ForgottenPassword: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        email,
        handleUpdateEmail,
        isEmailValid,
        areInputsValid,
        handleSubmit,
        handleGoBack,
        isLoading,
        isSuccess,
        isError,
    } = useForgottenPasswordVM();
    return (
        <>
            <MargedTitle
                text={
                    isSuccess
                        ? 'forgotten_password_page.title_success'
                        : 'forgotten_password_page.title'
                }
                type={4}
            ></MargedTitle>
            <MargedText
                text={
                    isSuccess
                        ? 'forgotten_password_page.description_success'
                        : 'forgotten_password_page.description'
                }
                type={2}
                color="secondaryTextColor"
            />

            {!isSuccess && (
                <>
                    <MargedInputContainer hasError={!isEmailValid}>
                        <Input
                            type="email"
                            value={email}
                            hasError={!isEmailValid}
                            onChange={handleUpdateEmail}
                            label={t('forgotten_password_page.email')}
                            labelDetail="*"
                            placeholder="forgotten_password_page.placeholder"
                            autoFocus
                            error={
                                !isEmailValid
                                    ? t('forgotten_password_page.error_format')
                                    : undefined
                            }
                            required
                            data-testid="email-input"
                        />
                    </MargedInputContainer>

                    {isError && (
                        <MargedText
                            type={3}
                            color="errorColor"
                            text={'forgotten_password_page.error_generic'}
                        />
                    )}

                    <ButtonSubmit
                        onClick={handleSubmit}
                        isLoading={isLoading}
                        disabled={!areInputsValid}
                        data-testid="reinit-btn"
                        size="m"
                    >
                        {t('forgotten_password_page.reinit_password')}
                    </ButtonSubmit>
                </>
            )}

            <BottomButton
                onClick={handleGoBack}
                isLoading={false}
                data-testid="back-btn"
                size="m"
                variant={isSuccess ? 'default' : 'text'}
            >
                {t('back')}
            </BottomButton>
        </>
    );
};

export default ForgottenPassword;
