import styled from '@emotion/styled';
import { SvyBadge } from '@innovorder/savory';
import { Separator } from 'pages/Menu/Product/ProductSteps/ProductSteps.style';

export const ProductListContainer = styled.ul`
    padding: ${({ theme }) => theme.base.spacing[2]};
    padding-bottom: 0;
`;

export const ListContainer = styled.ul`
    max-width: 100%;
`;

export const CategorySeparator = styled(Separator)`
    height: 4px;
`;

export const StyledSvyBadge = styled(SvyBadge)`
    font-size: 14px !important;
`;

export const TableNumberContainer = styled.div`
    padding-left: 16px;
`;

export const ProductListTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
`;

export const ProductListTitleContent = styled.div`
    flex: 1;
    width: fit-content;
    overflow: hidden;
`;
