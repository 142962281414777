import i18n from 'core/InitialiseThirdParties/i18n';
import { useGetOrderReceipt } from 'hooks/useGetOrderReceipt';
import { computeProductsForCart } from 'pages/Cart/Cart.utils';
import { formatOrderDate } from 'pages/Order/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BrandRestaurant } from 'services/brand/brand.type';
import {
    CartProduct,
    Discount,
    EntranceFee,
    Grant,
    OrderInformation,
    PriceBreakdown,
} from 'services/order/order.type';

export type OrderReceipt = {
    datetime: string;
    restaurant: BrandRestaurant;
    ticketNumber: string;
    products: CartProduct[];
    currency: string;
    priceBreakdown: PriceBreakdown;
    grants: Grant[];
    fees: EntranceFee[];
    discounts: Discount[];
};

export const useOrderReceiptVM = () => {
    const { orderId } = useParams();
    const { i18n: i18nInstance } = useTranslation();
    const { data, isLoading, error } = useGetOrderReceipt(orderId ?? '');
    const orderReceipt = data ? toOrderReceipt(data, i18nInstance) : null;

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return { orderReceipt, isLoading, goBack, error };
};

function toOrderReceipt(information: OrderInformation, i18nInstance: typeof i18n): OrderReceipt {
    const rawData = JSON.parse(information.cartRawData);
    const products = computeProductsForCart(
        // If enrichedCart is undefined, it's a legacy order (webordering v1)
        rawData.enrichedCart ? rawData.enrichedCart : rawData.products,
        information.currency,
    );

    return {
        datetime: formatOrderDate(i18nInstance, information.startDate),
        restaurant: information.restaurant,
        ticketNumber: information.ticketNumber ?? '',
        products,
        currency: information.currency,
        priceBreakdown: computePriceBreakdown(information),
        grants: information.grants,
        fees: information.entranceFees,
        discounts: information.discounts,
    };
}

function computePriceBreakdown(information: OrderInformation) {
    const priceBreakdown = {
        totalExclTax: information.totalPriceWithTaxExcluded,
        totalInclTaxWithDiscount: information.totalPriceDiscountedWithTaxIncluded,
        totalInclTax: information.totalPriceWithTaxIncluded,
        totalExclTaxWithDiscount: information.totalPriceWithTaxExcluded,
        totalTax: information.totalPriceWithTaxIncluded - information.totalPriceWithTaxExcluded,
        totalTaxWithDiscount:
            information.totalPriceDiscountedWithTaxIncluded - information.totalPriceWithTaxExcluded,
        totalInclTaxWithRemise: information.totalPriceWithTaxIncluded,
        totalExclTaxWithRemise: information.totalPriceWithTaxExcluded,
        totalTaxWithRemise:
            information.totalPriceWithTaxIncluded - information.totalPriceWithTaxExcluded,
    };

    return priceBreakdown;
}
