import { useEffect, useMemo, useState, useCallback } from 'react';
import { ENV } from 'utils/env';
import { useAppSelector } from 'state/store';
import { EdenredPaymentCheckoutBuilder } from '@innovorder/payment';
import { useTranslation } from 'react-i18next';
import { getBrandCurrency } from 'state/brand';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';
import { computePrice } from 'utils/price';
import { getAccessToken } from 'state/customer/customer.slice';

export const useEdenredBalance = () => {
    const currency = useAppSelector(getBrandCurrency);
    const customerAccessToken = useAppSelector(getAccessToken);
    const { value: edenredAccessToken, setInLocalStorage } = useLocalStorage('edenredAccessToken');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [balance, setBalance] = useState<number>(0);
    const { i18n } = useTranslation();
    const paymentCheckout = useMemo(
        () =>
            new EdenredPaymentCheckoutBuilder()
                .configureCheckout({
                    backendRootUrl: ENV.PAYMENT_SERVICE_URL,
                    locale: i18n.language,
                })
                .build(),
        [i18n.language],
    );

    const getEdenredBalance = useCallback(() => {
        setError(undefined);
        setIsLoading(true);
        setIsSuccess(false);
        const getBalancePromise = edenredAccessToken
            ? paymentCheckout.getAnonymousCustomerBalance(edenredAccessToken as string)
            : paymentCheckout.getCustomerBalance(customerAccessToken!);
        getBalancePromise
            .then((edenredBalance) => {
                setBalance(edenredBalance);
                setIsSuccess(true);
            })
            .catch((edenredError) => {
                setError(edenredError);
                setInLocalStorage(null);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [paymentCheckout, setInLocalStorage, customerAccessToken, edenredAccessToken]);

    useEffect(() => {
        if (edenredAccessToken || customerAccessToken) getEdenredBalance();
    }, [getEdenredBalance, customerAccessToken, edenredAccessToken]);

    return {
        balance,
        currency,
        balanceWithCurrency:
            currency && Number.isInteger(balance) ? computePrice(i18n, balance, currency) : '',
        isLoading,
        isSuccess,
        error,
    };
};
