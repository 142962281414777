import { useAppSelector } from 'state/store';
import { getCurrentConsumptionModeType } from 'state/app';
import { ProductAttributesFromCategories } from 'services/menu/menu.type';
import { useTableNumber } from 'hooks/useTableNumber/useTableNumber';
import { computeProductList } from './ProductList.utils';

export const useProductListVM = (productList: ProductAttributesFromCategories[]) => {
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const computedProductList = computeProductList(productList, currentConsumptionModeType);

    const { tableNumber } = useTableNumber();

    return {
        computedProductList,
        tableNumber,
    };
};
