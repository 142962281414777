import { CartItemDisplayerProps } from 'components/CartItemDisplayer/CartItemDisplayer';
import { useState, useEffect, useCallback } from 'react';
import { getStatuses } from 'state/ordersStatus/ordersStatus.slice';
import {
    getOrderStatusAcknowledged,
    updateOrdersStatusAcknowledged,
} from 'state/ordersStatusAcknowledged/ordersStatusAcknowledged.slice';
import { useAppDispatch, useAppSelector } from 'state/store';
import { computeRestaurantNameWithDailyOrderNumber } from 'utils/order';

type OrderToNotify = {
    orderId: number;
    restaurantName: string;
};

export const useOrderNotificationModalVM = (
    restaurantOrders: CartItemDisplayerProps['restaurantOrders'],
) => {
    const dispatch = useAppDispatch();
    const ordersStatus = useAppSelector(getStatuses);
    const ordersStatusAcknowledged = useAppSelector(getOrderStatusAcknowledged);

    const [showOrderNotificationModal, setShowOrderNotificationModal] = useState(false);

    const ordersToNotify = restaurantOrders.reduce<OrderToNotify[]>(
        (result, { orderId, restaurantName, dailyOrderNumber }) => {
            if (!orderId || !restaurantName) return result;
            if (!ordersStatus || !ordersStatus?.[orderId]) return result;

            const { status } = ordersStatus[orderId];

            if (status === 'READY' && !ordersStatusAcknowledged?.[orderId]) {
                return [
                    ...result,
                    {
                        orderId,
                        restaurantName: computeRestaurantNameWithDailyOrderNumber(
                            restaurantName,
                            dailyOrderNumber,
                        ),
                    },
                ];
            }

            return result;
        },
        [],
    );

    const handleAcknowledge = useCallback(() => {
        if (!ordersStatus) return;

        const ordersToAcknowledge = Object.fromEntries(
            ordersToNotify.map(({ orderId }) => [orderId, true]),
        );

        dispatch(updateOrdersStatusAcknowledged(ordersToAcknowledge));
        setShowOrderNotificationModal(false);
    }, [dispatch, ordersToNotify, ordersStatus]);

    useEffect(() => {
        if (!ordersToNotify.length) {
            setShowOrderNotificationModal(false);
            return;
        }

        setShowOrderNotificationModal(true);
    }, [ordersToNotify]);

    return {
        ordersToNotify,
        showOrderNotificationModal,
        handleAcknowledge,
    };
};
