import { VerifiedOrder } from '@innovorder/order_detail';
import { useCallback } from 'react';
import { resetOrders, updateOrders as updateOrdersFromSlice } from 'state/orders';
import { useAppDispatch } from 'state/store';
import { isOrderStateEmpty } from './useUpdateOrder.utils';

export const useUpdateOrder = () => {
    const dispatch = useAppDispatch();

    const updateOrders = useCallback(
        (orders: Record<number, VerifiedOrder>) => {
            if (isOrderStateEmpty(orders)) {
                dispatch(resetOrders(undefined));
                return;
            }

            dispatch(updateOrdersFromSlice(orders));
        },
        [dispatch],
    );

    return {
        updateOrders,
    };
};
