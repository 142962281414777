/* eslint-disable import/no-extraneous-dependencies */
import {
    BrandRestaurant,
    ModuleOrderingFlow,
    WeborderingConfiguration,
} from 'services/brand/brand.type';
import { WeborderingScheduleAttributes, WeekDay } from 'services/brand/orderingSchedules.type';
import { faker } from '@faker-js/faker/locale/fr';
import { weborderingConfigurationMock } from './mock';
import { restaurantMock } from './mockRestaurant';

export const weborderingConfigurationFixtureFactory = (
    weborderingConfigurationParams: Partial<WeborderingConfiguration> = {},
): WeborderingConfiguration => {
    return {
        ...JSON.parse(JSON.stringify(weborderingConfigurationMock)),
        ...weborderingConfigurationParams,
    };
};

export const brandRestaurantFixtureFactory = (
    params: Partial<BrandRestaurant> = {},
): BrandRestaurant => {
    return {
        ...restaurantMock,
        restaurantId: faker.datatype.number(),
        ...params,
    };
};

export const moduleOrderingFlowFixtureFactory = (
    params: Partial<ModuleOrderingFlow> = {},
): ModuleOrderingFlow => {
    return {
        restaurantId: 1,
        rushDelay: 0,
        isOrderFollowUpEnabled: false,
        openAt: '2024-01-01T06:00:00.000Z',
        askForTableNumber: false,
        ...params,
    };
};

export const weborderingScheduleFixtureFactory = (
    weborderingScheduleParams: Partial<WeborderingScheduleAttributes> = {},
): WeborderingScheduleAttributes => {
    return {
        day: faker.helpers.arrayElement(Object.values(WeekDay)),
        start: faker.datatype.number({ max: 770 }),
        end: faker.datatype.number({ min: 771, max: 1440 }),
        weborderingScheduleId: faker.datatype.number(),
        moduleOrderingScheduleId: faker.datatype.number(),
        is_enabled: faker.datatype.boolean(),
        created_at: faker.date.past(),
        updated_at: faker.date.past(),
        ...weborderingScheduleParams,
    };
};
