import { Customer } from 'services/customer/customer.type';
import { innovorderApi } from '../index';
import { ApiResponse, TokenRequest } from '../providers/innovorder.types';
import {
    LoginResponse,
    LoginPayload,
    LogoutPayload,
    AutoLoginPayload,
} from './authentication.type';

const authenticationApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<LoginResponse, LoginPayload>({
            query: (body) => {
                return {
                    url: `/oauth/login`,
                    method: 'POST',
                    body: {
                        ...body,
                        grant_type: 'password',
                        rememberMe: false,
                    },
                };
            },
            invalidatesTags: [],
            transformResponse: (response: ApiResponse<LoginResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        autoLoginJWT: build.query<LoginResponse, AutoLoginPayload>({
            query: (body) => {
                return {
                    url: `/oauth/login`,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: ApiResponse<LoginResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        me: build.query<Customer, TokenRequest>({
            query: ({ token }) => ({
                url: `/oauth/me`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<Customer>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        logout: build.mutation<ApiResponse<undefined>, LogoutPayload>({
            query: ({ token }) => ({
                url: `/oauth/logout`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<undefined>) => response,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useLoginMutation, useMeQuery, useLogoutMutation, useAutoLoginJWTQuery } =
    authenticationApi;
