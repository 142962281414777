import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useCallback, useEffect, useRef } from 'react';
import { useAppSelector } from 'state/store';
import { getAccessToken } from 'state/customer/customer.slice';
import { useNavigate } from 'react-router-dom';
import { useGetOrdersHistory } from 'hooks/useGetOrdersHistory';
import { useIsVisible } from 'hooks/useIsVisible';

export const useOrdersHistoryVM = () => {
    const { goLogin } = useWorkflowStateMachine();
    const accessToken = useAppSelector(getAccessToken);
    const {
        data: orders,
        isLoading,
        isFetching,
        hasNextPage,
        fetchNextPage,
    } = useGetOrdersHistory();

    const nextPageRef = useRef(null);
    const isVisible = useIsVisible(nextPageRef);

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleNextPage = useCallback(async () => {
        await fetchNextPage();
    }, [fetchNextPage]);

    useEffect(() => {
        if (!isFetching && isVisible) handleNextPage();
    }, [handleNextPage, isFetching, isVisible, isLoading]);

    useEffect(() => {
        const isAuthenticated = !!accessToken;
        if (!isAuthenticated && goLogin) goLogin({});
    }, [accessToken, goLogin]);

    return {
        goBack: handleGoBack,
        orders,
        isLoading,
        isFetching,
        hasNextPage,
        handleNextPage,
        nextPageRef,
    };
};
