import { ProductItem, Text, Title } from 'components';
import React from 'react';
import { ProductAttributesFromCategories } from 'services/menu/menu.type';
import { TableNumberBadge } from 'components/TableNumberBadge';
import {
    CategorySeparator,
    ListContainer,
    ProductListContainer,
    ProductListTitleContainer,
    ProductListTitleContent,
} from './ProductList.style';
import { useProductListVM } from './useProductListVM';

type Props = {
    categoryId: number;
    productList: ProductAttributesFromCategories[];
    title: string;
    currency: string;
    onProductClick?: (id: number) => void;
    shouldDisplayTableNumber?: boolean;
};

const ProductList: React.FunctionComponent<Props> = ({
    categoryId,
    productList,
    currency,
    title,
    onProductClick,
    shouldDisplayTableNumber = false,
}) => {
    const { computedProductList, tableNumber } = useProductListVM(productList);

    // Don't display the category if there are products but none are available
    if (productList.length && computedProductList.length === 0) {
        return null;
    }

    return (
        <ProductListContainer>
            <ProductListTitleContainer>
                <ProductListTitleContent>
                    <Title text={title} noTranslation type={4} isUnderlined={true} />
                </ProductListTitleContent>
                {shouldDisplayTableNumber && tableNumber && (
                    <TableNumberBadge tableNumber={tableNumber} />
                )}
            </ProductListTitleContainer>
            <ListContainer data-testid="list-container" id={`category-${String(categoryId)}`}>
                {computedProductList.length ? (
                    computedProductList.map((product) => (
                        <ProductItem
                            {...product}
                            key={product.productId}
                            currency={currency}
                            onClick={onProductClick}
                        />
                    ))
                ) : (
                    <Text text={'empty_product_list'} />
                )}
            </ListContainer>
            <CategorySeparator />
        </ProductListContainer>
    );
};

export default ProductList;
