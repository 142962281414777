import { MutableRefObject, useEffect, useState } from 'react';

// https://rasilbaidar.medium.com/trigger-event-when-element-enters-viewport-the-react-way-168509da2e23
// Many examples exist on the Internet
// See also: src/pages/Menu/MenuHome/CategoryDisplayer/useCategoryDisplayerVM.ts

// In react-testing-library, one must mock `IntersectionObserver`, therefore
// it was decided to not unit-test this.

export const useIsVisible = (element: MutableRefObject<null>, rootMargin = '0px') => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const current = element?.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            },
            { rootMargin },
        );
        current && observer?.observe(current);

        return () => {
            current && observer.unobserve(current);
        };
    }, [element, rootMargin]);

    return isVisible;
};
