import { useEffect, useState } from 'react';
import { PaymentRequest } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import { useAppSelector } from 'state/store';
import { getBrand, getBrandCurrency } from 'state/brand';
import {
    getOrderRecap,
    getOrdersTotalInclTaxWithDiscount,
    getOrdersTotalPriceBreakdown,
} from 'state/orders';
import { useConfirmPayment } from 'hooks/useConfirmPayment/useConfirmPayment';
import { getRestaurantId } from 'state/app';
import { useCustomNavigate } from 'hooks/useCustomNavigate';

export const useStripeIntegrationForm = () => {
    const { navigateBackToChoosePayment, navigateToPaymentWithError } = useCustomNavigate();

    const restaurantId = useAppSelector(getRestaurantId);
    const totalInclTaxWithDiscount = useAppSelector(getOrdersTotalInclTaxWithDiscount);
    const brand = useAppSelector(getBrand);
    const totalPriceBreakdown = useAppSelector(getOrdersTotalPriceBreakdown);
    const currency = useAppSelector(getBrandCurrency);
    const recap = useAppSelector(getOrderRecap(restaurantId));

    const stripe = useStripe();
    const { handlePaymentSubmit, isLoadingCreateOrder } = useConfirmPayment('stripe');

    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

    useEffect(() => {
        (async () => {
            if (!paymentRequest && stripe && brand) {
                const pr = stripe.paymentRequest({
                    country: 'FR',
                    currency: brand.currency.toLocaleLowerCase(),
                    total: {
                        label: brand.name,
                        amount: totalInclTaxWithDiscount,
                    },
                });

                const result = await pr.canMakePayment();
                if (result) {
                    setPaymentRequest(pr);

                    pr.once('paymentmethod', async (ev) => {
                        const paymentMethodId = ev.paymentMethod.id;
                        await handlePaymentSubmit({ stripePaymentMethodId: paymentMethodId });
                        ev.complete('success');
                    });

                    pr.on('cancel', async () => {
                        navigateBackToChoosePayment();
                    });
                } else {
                    navigateToPaymentWithError('googleOrApplePay');
                }
            }
        })();
    }, [
        paymentRequest,
        stripe,
        brand,
        totalInclTaxWithDiscount,
        handlePaymentSubmit,
        navigateBackToChoosePayment,
        navigateToPaymentWithError,
    ]);

    return { paymentRequest, isLoadingCreateOrder, currency, totalPriceBreakdown, recap };
};
