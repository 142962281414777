import { WithHeader } from 'components/WithHeader';
import { Title } from 'components/Title';
import { Loader } from 'components/Loader';
import { LoaderContainer } from 'components/Loader/Loader.style';
import { CartItem, PriceBreakDownSummary, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { ErrorEnum } from 'pages/Error/Error.type';
import Error from 'pages/Error';
import { SvyBadge } from '@innovorder/savory';
import {
    Container,
    ErrorContainer,
    OrderNumber,
    StyledBadgeOrderNumber,
    StyledHeader,
} from './OrderReceipt.style';
import { useOrderReceiptVM } from './useOrderReceiptVM';

const OrderReceipt: React.FunctionComponent = () => {
    const { goBack, orderReceipt, isLoading, error } = useOrderReceiptVM();
    const { t } = useTranslation();

    if (isLoading) {
        return (
            <WithHeader
                isSticky={true}
                onClickBack={goBack}
                headerContent={
                    <StyledHeader>
                        <Title type={5} text={'order_receipt.title'} />
                    </StyledHeader>
                }
            >
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            </WithHeader>
        );
    }

    if (!orderReceipt || error) {
        return (
            <WithHeader
                isSticky={true}
                onClickBack={goBack}
                headerContent={
                    <StyledHeader>
                        <Title type={5} text={'order_receipt.title'} />
                    </StyledHeader>
                }
            >
                <ErrorContainer>
                    <Error error={ErrorEnum.ORDER_RECEIPT_ERROR} />
                </ErrorContainer>
            </WithHeader>
        );
    }

    return (
        <WithHeader
            isSticky={true}
            headerContent={
                <StyledHeader>
                    <Title type={5} text={'order_receipt.title'} />
                    <Text
                        data-testid="order-datetime"
                        weight={'Bold'}
                        type={3}
                        text={orderReceipt.datetime}
                        noTranslation
                    />
                </StyledHeader>
            }
            onClickBack={goBack}
        >
            <Container>
                <Title type={5} text={orderReceipt.restaurant.name} noTranslation />

                <OrderNumber>
                    <SvyBadge color="primary" label={t('order_confirmation_title')} />

                    <StyledBadgeOrderNumber
                        color="primary"
                        label={t('order_number_short', {
                            number: orderReceipt.ticketNumber,
                        })}
                    />
                </OrderNumber>

                {orderReceipt.products.map((product) => (
                    <CartItem
                        key={product.productId}
                        {...product}
                        currency={orderReceipt.currency}
                    />
                ))}

                <PriceBreakDownSummary
                    {...orderReceipt.priceBreakdown}
                    currency={orderReceipt.currency}
                    entranceFees={orderReceipt.fees}
                    grants={orderReceipt.grants}
                    discounts={orderReceipt.discounts}
                />
            </Container>
        </WithHeader>
    );
};

export default OrderReceipt;
