import { useCallback } from 'react';
import { updateAppState } from 'state/app';
import { resetOrders } from 'state/orders';
import { resetStatuses } from 'state/ordersStatus/ordersStatus.slice';
import { updateRequiredAction } from 'state/requiredAction';
import { useAppDispatch } from 'state/store';

export const useCustomFlush = () => {
    const dispatch = useAppDispatch();

    const flushCart = useCallback(() => dispatch(resetOrders(undefined)), [dispatch]);
    const flushRequiredAction = useCallback(() => dispatch(updateRequiredAction(null)), [dispatch]);
    const flushOrderStatuses = useCallback(() => dispatch(resetStatuses(undefined)), [dispatch]);
    const flushIsPaymentConfirmed = useCallback(
        () => dispatch(updateAppState({ isPaymentConfirmed: false })),
        [dispatch],
    );

    return {
        flushCart,
        flushRequiredAction,
        flushOrderStatuses,
        flushIsPaymentConfirmed,
    };
};
