import styled from '@emotion/styled';
import { GrowingContainer } from 'components';

export const AdditionalInformationsContentContainer = styled(GrowingContainer)`
    padding: 0 ${({ theme }) => theme.base.spacing[2]};
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;

export const InputContainer = styled.div<{ smallTopSpacing?: boolean }>`
    display: flex;
    flex-direction: column;
    margin-top: ${({ theme, smallTopSpacing }) =>
        smallTopSpacing ? theme.base.spacing[1] : theme.base.spacing[2]};

    /**
    To avoid iOS auto zooming in Input
    https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming#bootstrap-default-input-font-sizing-1rem
    */
    * {
        font-size: 16px;
    }
`;

export const SpacedInputContainer = styled(InputContainer)`
    margin: ${({ theme }) => theme.base.spacing[1]} 0;
`;

export const ExternalCustomerContainer = styled.div`
    display: flex;
    align-items: center;
    gap: calc(${({ theme }) => theme.base.spacing[1]} / 2);
    color: ${({ theme }) => theme.base.colors.info[500]};
    margin: ${({ theme }) => theme.base.spacing[1]} 0;
`;
