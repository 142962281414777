import { getConsumptionModeList } from 'state/consumptionModeList';
import { getOrders } from 'state/orders';
import { useAppSelector } from 'state/store';
import { useLuncheonVoucherEligibilityRules } from 'hooks/useLuncheonVoucherEligibilityRules/useLuncheonVoucherEligibilityRules';
import { PaymentMethodCode } from 'services/consumptionMode/consumptionMode.type';
import { useFeatureFlag } from 'hooks/useFeatureFlag/useFeatureFlag';

export const usePaygreen = () => {
    const consumptionModeList = useAppSelector(getConsumptionModeList);
    const currentOrders = useAppSelector(getOrders);
    const { cartContainsOnlyEligibleProducts } = useLuncheonVoucherEligibilityRules();

    const { usePaygreenPayments } = useFeatureFlag();

    if (!currentOrders || !consumptionModeList || !usePaygreenPayments) {
        return {
            hasPaygreenPaymentMethod: false,
        };
    }

    const hasPaygreenPaymentMethod = Object.values(currentOrders).reduce((result, currentOrder) => {
        const currentConsumptionMode = currentOrder.consumptionMode;
        const orderConsumptionMode = consumptionModeList[currentOrder.restaurantId];

        // TODO: To fix multi-order-paiements hydrate consumptionModeList for allRestaurants at once
        if (!orderConsumptionMode) {
            return false;
        }

        const selectedConsumptionMode = orderConsumptionMode.find(
            ({ type }) => type === currentConsumptionMode,
        );

        const isPaygreenActive = !!selectedConsumptionMode?.paymentMethods.find(
            ({ code, active }) => active && code === PaymentMethodCode.Paygreen,
        );

        return result && isPaygreenActive;
    }, true);

    return {
        hasPaygreenPaymentMethod,
        cartContainsOnlyEligibleProducts,
    };
};
