import { produce } from 'immer';
import {
    CompositeProduct,
    CurrentCompositeProductState,
    CurrentPath,
    CompositeStep,
} from './currentCompositeProduct.slice';

type ComputeCurrentStepPayload = {
    state: CurrentCompositeProductState;
    compositeProduct: CompositeProduct;
    path: CurrentPath;
};

type ComputeProductByPathPayload = {
    state: CurrentCompositeProductState;
    path: CurrentPath;
};

type ProductOrStep = CompositeProduct | CompositeStep;

export const isProduct = (productOrStep: ProductOrStep): productOrStep is CompositeProduct =>
    Boolean((productOrStep as CompositeProduct).steps);

export const isStep = (productOrStep: ProductOrStep): productOrStep is CompositeStep =>
    Boolean(!(productOrStep as CompositeProduct).steps);

export const computeCurrentStep = ({
    state,
    compositeProduct,
    path,
}: ComputeCurrentStepPayload): CompositeStep | null => {
    const nextCurrentProduct = produce(state, (draft) => {
        if (draft && draft.currentStep && path && path.length > 0) {
            path.reduce<ProductOrStep | undefined>((result, current, level) => {
                if (result && isStep(result) && level === path.length - 1) {
                    result[current] = compositeProduct;
                    return compositeProduct;
                }

                if (result && isProduct(result)) {
                    return result?.steps?.[current];
                }

                return result?.[current];
            }, draft.currentStep);
        }
    });

    return nextCurrentProduct?.currentStep || null;
};

export const computeProductByPath = ({
    state,
    path,
}: ComputeProductByPathPayload): CompositeProduct | null => {
    if (!(state && state.currentStep && path.length > 0)) {
        return null;
    }

    const product = path.reduce<ProductOrStep | undefined>((result, current) => {
        if (result && isStep(result)) {
            return result?.[current];
        }

        if (result && isProduct(result)) {
            return result?.steps?.[current];
        }

        return undefined;
    }, state.currentStep);

    return (product as CompositeProduct) || null;
};

export const computeNestedProductId = (path: CurrentPath | null): string | null => {
    if (!path || path.length <= 1) {
        return null;
    }

    return path[path.length - 1];
};

export const computeReturnToPreviousStep = (path: CurrentPath | null): CurrentPath | null => {
    if (!path || path.length < 3) {
        return path;
    }
    /**
     * -2 because currentPath is always finishing with a productId
     * and has a stepId between every productId
     *
     * ex: ["prod1"] or ["prod1", "step1", "prod2"] ...
     *  */
    return [...path.slice(0, path.length - 2)];
};
