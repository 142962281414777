import { MenuState } from 'state/menu';
import { CategoryAttributes, ProductAttributesFromCategories } from 'services/menu/menu.type';

export const computeCategories = (menu: MenuState): CategoryAttributes[] => {
    if (!menu?.categories) {
        return [];
    }

    const parentCategories = Object.values(menu.categories)
        .filter(({ parentCategoryId, webAvailability }) => webAvailability && !parentCategoryId)
        .sort(({ position: positionA }, { position: positionB }) => positionA - positionB);

    return parentCategories.flatMap((parentCategory) => {
        if (checkIfCategoryHasAvailableProducts(Object.values(parentCategory.products))) {
            return parentCategory;
        }

        return Object.values(menu.categories)
            .filter(
                ({ parentCategoryId, webAvailability, products }) =>
                    webAvailability &&
                    checkIfCategoryHasAvailableProducts(Object.values(products)) &&
                    parentCategoryId === parentCategory.categoryId,
            )
            .sort(({ position: positionA }, { position: positionB }) => positionA - positionB);
    });
};

export const checkIfCategoryHasAvailableProducts = (
    products: ProductAttributesFromCategories[],
): boolean => {
    if (!products.length) {
        return false;
    }

    return products.some(({ webAvailability }) => !!webAvailability);
};
