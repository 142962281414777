import { HorizontalScrollMenu } from 'components';
import React from 'react';
import { CategoryScrollerContainer } from './CategoryDisplayer.style';
import { useCategoryDisplayerVM } from './useCategoryDisplayerVM';
import ProductList from '../ProductList';

const CategoryDisplayer: React.FunctionComponent = () => {
    const {
        categories,
        menuCategoryList,
        selectedMenuCategoryId,
        currency,
        navigateToProduct,
        handleChangeMenuCategory,
    } = useCategoryDisplayerVM();

    return (
        <>
            {menuCategoryList && menuCategoryList.length > 0 && (
                <CategoryScrollerContainer>
                    <HorizontalScrollMenu
                        items={menuCategoryList}
                        selectedItemId={selectedMenuCategoryId}
                        onChange={handleChangeMenuCategory}
                    />
                </CategoryScrollerContainer>
            )}
            <div>
                {categories.map(({ categoryId, products, name }, index) => (
                    <ProductList
                        key={categoryId}
                        categoryId={categoryId}
                        productList={Object.values(products)}
                        title={name}
                        currency={currency}
                        onProductClick={navigateToProduct}
                        shouldDisplayTableNumber={index === 0}
                    />
                ))}
            </div>
        </>
    );
};

export default CategoryDisplayer;
