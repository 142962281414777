import { AuthenticationType } from 'services/brand/brand.type';

export const computeAccountButton = ({
    isFoodCourt,
    isAuthenticated,
    authenticationType,
}: {
    isFoodCourt: boolean | null;
    isAuthenticated: boolean;
    authenticationType: AuthenticationType | undefined;
}):
    | { shouldDisplayButton: false; buttonIcon: null }
    | { shouldDisplayButton: true; buttonIcon: string } => {
    if (isFoodCourt || authenticationType === 'Guest') {
        return {
            shouldDisplayButton: false,
            buttonIcon: null,
        };
    }

    if (isAuthenticated) {
        return {
            shouldDisplayButton: true,
            buttonIcon: 'user-follow-line',
        };
    }

    return {
        shouldDisplayButton: true,
        buttonIcon: 'user-line',
    };
};
