import { useAuthenticate } from 'hooks/useAuthenticate/useAuthenticate';
import { useNavigate } from 'react-router-dom';
import { getAuthenticationType, getBrandCurrency, getBrandId } from 'state/brand/brand.slice';
import { useAppSelector } from 'state/store';
import {
    getAccessToken,
    getAccountPaymentType,
    getCustomerId,
    getEwalletOverdraftAmount,
    getCustomerName,
} from 'state/customer/customer.slice';
import { useGetCustomerBalanceQuery } from 'services/customer/customer.endpoints';
import { ENV } from 'utils/env';
import { TranslationKey } from 'locales/fr';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useEffect } from 'react';
import { WorkflowStateStep } from 'hooks/useWorkflowStateMachine/types';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { computeCustomerBalance } from './Account.utils';

export const useAccountVM = () => {
    const customerName = useAppSelector(getCustomerName);
    const accessToken = useAppSelector(getAccessToken);
    const customerId = useAppSelector(getCustomerId);
    const accountPaymentType = useAppSelector(getAccountPaymentType);
    const currency = useAppSelector(getBrandCurrency);
    const brandId = useAppSelector(getBrandId);
    const ewalletOverdraftAmount = useAppSelector(getEwalletOverdraftAmount);
    const authenticationType = useAppSelector(getAuthenticationType);

    const { navigateToOrdersHistory } = useCustomNavigate();
    const { goLogin, goHome, state } = useWorkflowStateMachine();

    const shouldDisplayEwalletBalance = accountPaymentType !== 'CASH';
    const shouldDisplayEwalletReloader = accountPaymentType === 'PREPAYMENT';
    const textBalance: TranslationKey =
        accountPaymentType === 'POSTPAYMENT'
            ? 'account_page.ewallet_authorized_balance'
            : 'account_page.ewallet_balance';

    const { data: customerBalanceData, isLoading: isCustomerBalanceLoading } =
        useGetCustomerBalanceQuery(
            { customerId: customerId ?? 0, token: accessToken ?? '' },
            { skip: !accessToken || !customerId, refetchOnMountOrArgChange: true },
        );

    const { logout, isLogoutLoading } = useAuthenticate();
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleGoToOrdersHistory = () => {
        navigateToOrdersHistory();
    };

    const handleGoToEwallet = () => {
        if (accessToken) {
            const ewalletUrl = `${ENV.EWALLET_URL}${brandId}/home?from=wov2&autoLoginByAccessToken=${accessToken}`;
            window.open(ewalletUrl, '_blank');
        }
    };

    const handleGoToLogin = () => {
        /**
         * historyValue is the state of the previous page
         */
        const historyState = state.historyValue?.current;
        /**
         * historyState === 'home' means that the user is coming from the home page or the account page
         */
        const redirectToState = (
            historyState === 'home' || !historyState ? undefined : historyState
        ) as WorkflowStateStep;

        goLogin({
            redirectToState,
        });
    };

    /** Account page should not be available in guest mode */
    useEffect(() => {
        if (authenticationType === 'Guest') {
            goHome();
        }
    }, [goHome, authenticationType]);

    return {
        isAuthenticated: !!accessToken,
        customerName: customerName ?? '',
        customerBalance: computeCustomerBalance(
            customerBalanceData?.customerBalance ?? 0,
            ewalletOverdraftAmount,
            currency,
            accountPaymentType,
        ),
        shouldDisplayEwalletBalance,
        shouldDisplayEwalletReloader,
        goBack: handleGoBack,
        handleGoToEwallet,
        logout,
        isLogoutLoading,
        isCustomerBalanceLoading: shouldDisplayEwalletBalance ? isCustomerBalanceLoading : false,
        textBalance,
        handleGoToOrdersHistory,
        handleGoToLogin,
    };
};
