import { useTranslation } from 'react-i18next';
import { Title, WithHeader, Loader, Text } from 'components';
import { LoaderContainer } from 'components/Loader/Loader.style';
import { SvyIcon } from '@innovorder/savory';
import { useAccountVM } from './useAccountVM';
import {
    AccountContainer,
    StyledButton,
    EwalletContent,
    EwalletReloaderButton,
    Separator,
    SizedSvyIcon,
    WaitingText,
    StyledList,
    StyledItem,
    IconContainer,
    Filler,
} from './Account.style';

const Account: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        customerName,
        goBack,
        handleGoToEwallet,
        logout,
        isLogoutLoading,
        shouldDisplayEwalletBalance,
        shouldDisplayEwalletReloader,
        customerBalance,
        isCustomerBalanceLoading,
        textBalance,
        isAuthenticated,
        handleGoToLogin,
        handleGoToOrdersHistory,
    } = useAccountVM();

    if (!isAuthenticated) {
        return (
            <>
                <WithHeader
                    isSticky={true}
                    headerContent={
                        <Title data-testid="welcome" type={5} text={'account_page.welcome'} />
                    }
                    onClickBack={goBack}
                >
                    <AccountContainer>
                        <Title text="🍽️" noTranslation type={1} />
                        <WaitingText type={2} text={'account_page.click_on_login'} />
                    </AccountContainer>

                    <Separator />

                    <StyledButton size="m" onClick={handleGoToLogin} data-testid="login-btn">
                        {t('login')}
                    </StyledButton>
                </WithHeader>
            </>
        );
    }

    return (
        <>
            <WithHeader
                isSticky={true}
                headerContent={
                    <Title
                        data-testid="customer-name"
                        type={5}
                        text={'account_page.hello'}
                        values={{ customerName }}
                    />
                }
                onClickBack={goBack}
            >
                {(shouldDisplayEwalletBalance || shouldDisplayEwalletReloader) && (
                    <EwalletContent>
                        {isCustomerBalanceLoading ? (
                            <LoaderContainer>
                                <Loader />
                            </LoaderContainer>
                        ) : (
                            <>
                                {shouldDisplayEwalletBalance && customerBalance !== undefined && (
                                    <Text
                                        data-testid="customer-balance"
                                        text={textBalance}
                                        values={{ customerBalance }}
                                    />
                                )}
                                {shouldDisplayEwalletReloader && (
                                    <EwalletReloaderButton
                                        size="m"
                                        onClick={handleGoToEwallet}
                                        data-testid="reload-btn"
                                    >
                                        {t('account_page.ewallet_reload')}
                                    </EwalletReloaderButton>
                                )}
                            </>
                        )}
                    </EwalletContent>
                )}

                <AccountContainer>
                    <StyledList>
                        <li>
                            <StyledItem
                                role="link"
                                onClick={() => handleGoToOrdersHistory()}
                                data-testid="orders-history"
                            >
                                <IconContainer>
                                    <SvyIcon icon="restaurant-line" />
                                </IconContainer>
                                <Text text={'account_page.orders'} type={2} weight="Bold" />
                                <Filler />
                                <SizedSvyIcon icon="arrow-right-s-line" />
                            </StyledItem>
                        </li>
                    </StyledList>
                </AccountContainer>

                <Separator />

                <StyledButton
                    variant="text"
                    size="m"
                    onClick={logout}
                    disabled={isLogoutLoading}
                    elementLeft={<SizedSvyIcon icon="logout-circle-r-line" />}
                    data-testid="logout-btn"
                >
                    {t('account_page.logout')}
                </StyledButton>
            </WithHeader>
        </>
    );
};

export default Account;
