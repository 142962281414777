import { getCurrentMenuId } from 'state/app';
import { getBrandCurrency } from 'state/brand';
import { useAppDispatch, useAppSelector } from 'state/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getMenuState } from 'state/menu';
import { MenuItem } from 'components/HorizontalScrollMenu/HorizontalScrollMenu';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { computeCategories } from './CategoryDisplayer.utils';

export const useCategoryDisplayerVM = () => {
    const dispatch = useAppDispatch();
    const { navigateToProduct } = useCustomNavigate();

    const currentMenuId = useAppSelector(getCurrentMenuId);
    const currency = useAppSelector(getBrandCurrency);
    const menu = useAppSelector(getMenuState);

    const [menuCategoryList, setMenuCategoryList] = useState<MenuItem[]>([]);
    const [selectedMenuCategoryId, setSelectedMenuCategoryId] = useState<number | null>(null);
    const [observerMenuCategoryId, setObserverMenuCategoryId] = useState<number | null>(null);
    const [observer, setObserver] = useState<IntersectionObserver | null>(null);
    const [isScrolling, setIsScrolling] = useState<boolean>(false);
    const [canObserve, setCanObserve] = useState<boolean>(false);

    const sortedAndAvailableMenu = useMemo(() => computeCategories(menu), [menu]);

    const handleChangeMenuCategory = useCallback(
        (id: number) => {
            setIsScrolling(true);
            setCanObserve(false);
            setSelectedMenuCategoryId(id);
        },
        [setIsScrolling, setSelectedMenuCategoryId],
    );

    useEffect(() => {
        let scrollTimeout: string | number | NodeJS.Timeout | undefined;

        const handleOnScrollEnd = () => {
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(function () {
                isScrolling && setIsScrolling(false);
                !isScrolling && setCanObserve(true);
            }, 100);
        };

        window.addEventListener('scroll', handleOnScrollEnd);

        return () => {
            window.removeEventListener('scroll', handleOnScrollEnd);
        };
    }, [isScrolling]);

    useEffect(() => {
        if (sortedAndAvailableMenu) {
            const categoryListForMenu = sortedAndAvailableMenu.map(({ categoryId, name }) => ({
                id: categoryId,
                name,
            }));

            setMenuCategoryList(categoryListForMenu);

            if (categoryListForMenu && categoryListForMenu.length) {
                setSelectedMenuCategoryId(categoryListForMenu[0].id);
            }
        }
    }, [sortedAndAvailableMenu, currentMenuId, dispatch]);

    useEffect(() => {
        if (isScrolling) {
            const element = document.getElementById(`category-${String(selectedMenuCategoryId)}`);
            const PADDING_OFFSET = 170;

            element &&
                window.scrollTo({
                    behavior: 'smooth',
                    top:
                        element.getBoundingClientRect().top -
                        document.body.getBoundingClientRect().top -
                        PADDING_OFFSET,
                });
        }
    }, [selectedMenuCategoryId, isScrolling]);

    useEffect(() => {
        setObserver(
            new IntersectionObserver(
                (entries) => {
                    if (entries && entries.length) {
                        const filteredEntries = entries
                            .filter(({ isIntersecting }) => !!isIntersecting)
                            .sort((a, b) => b.intersectionRatio - a.intersectionRatio);
                        const currentCategory = menuCategoryList.find(
                            ({ id }) => `category-${String(id)}` === filteredEntries[0]?.target?.id,
                        );
                        if (currentCategory) {
                            setObserverMenuCategoryId(currentCategory.id);
                        }
                    }
                },
                {
                    threshold: [0.1],
                    root: document.getElementById('scrollArea'),
                },
            ),
        );
    }, [menuCategoryList]);

    useEffect(() => {
        if (observerMenuCategoryId && !isScrolling && canObserve) {
            setSelectedMenuCategoryId(observerMenuCategoryId);
        }
    }, [observerMenuCategoryId, isScrolling, canObserve]);

    useEffect(() => {
        menuCategoryList.forEach(({ id }) => {
            const element = document.getElementById(`category-${String(id)}`);

            if (element && observer) {
                observer.observe(element);
            }
        });

        return () => {
            observer?.disconnect();
        };
    }, [menuCategoryList, observer]);

    return {
        categories: sortedAndAvailableMenu,
        menuCategoryList,
        selectedMenuCategoryId,
        currency: currency ?? 'EUR',
        navigateToProduct,
        handleChangeMenuCategory,
    };
};
