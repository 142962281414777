import { TranslationKey } from 'locales/fr';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBrandId } from 'state/brand';
import { getAccessToken, resetCustomer, updateCustomer } from 'state/customer/customer.slice';
import {
    useLoginMutation,
    useLogoutMutation,
} from 'services/authentication/authentication.endpoint';
import { LoginErrorPayload } from 'services/authentication/authentication.type';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { updateAppState } from 'state/app';
import { useAppDispatch } from 'state/store';
import { resetOrders } from 'state/orders';
import { computeLoginErrorMessage } from './useAuthenticate.utils';

export const useAuthenticate = () => {
    const brandId = useSelector(getBrandId);
    const token = useSelector(getAccessToken);

    const [error, setError] = useState<TranslationKey | undefined>();

    const { goRedirectOrNext, goHome } = useWorkflowStateMachine();
    const dispatch = useAppDispatch();
    const [loginQuery, { isLoading: isLoginLoading, error: loginError }] = useLoginMutation();
    const [logoutQuery, { isLoading: isLogoutLoading, error: isLogoutError }] = useLogoutMutation();

    const handleLogin = async (credentials: { username: string; password: string }) => {
        if (!brandId) return;

        if (token) {
            goRedirectOrNext();
            return;
        }

        setError(undefined);

        const loginResult = await loginQuery({
            ...credentials,
            brandId,
        });

        if ('error' in loginResult) {
            const {
                data: { code },
            } = loginResult.error as LoginErrorPayload;

            setError(computeLoginErrorMessage(code));
            return;
        }

        const { accessToken, customer } = loginResult.data;

        dispatch(
            updateCustomer({
                brandId: customer.brandId,
                accessToken,
                customerId: customer.customerId,
                email: customer.email,
                phone: customer.phone,
                customerName: `${customer.firstName} ${customer.lastName}`,
                accountPaymentType: customer.student?.accountPaymentType,
                ewalletOverdraftAmount: customer.ewalletOverdraftAmount,
            }),
        );

        goRedirectOrNext();
    };

    const handleLogout = useCallback(async () => {
        if (!token) return;
        await logoutQuery({
            token,
        });

        dispatch(resetCustomer(undefined));
        dispatch(resetOrders(undefined));
        dispatch(updateAppState({ shouldDisplayLogoutPopup: true }));
        goHome();
    }, [dispatch, goHome, logoutQuery, token]);

    const resetError = () => {
        setError(undefined);
    };

    return {
        login: handleLogin,
        isLoginLoading,
        loginError,
        error,
        resetError,
        logout: handleLogout,
        isLogoutLoading,
        isLogoutError,
    };
};
