import { getAccessToken } from 'state/customer/customer.slice';
import { useAppSelector } from 'state/store';
import { useGetOrderByIdQuery } from 'services/order/order.endpoints';

export const useGetOrderReceipt = (orderId: string) => {
    const accessToken = useAppSelector(getAccessToken);

    const { data, isLoading, error } = useGetOrderByIdQuery(
        {
            accessToken: accessToken!,
            orderId,
        },
        { skip: !accessToken },
    );

    return { data, isLoading, error };
};
