import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useState, useCallback, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getRestaurantId, getCurrentConsumptionModeType } from 'state/app';
import { getBrand } from 'state/brand';
import { useAppSelector } from 'state/store';
import { usePostForgottenPasswordMutation } from 'services/customer/customer.endpoints';
import { convertModeToUrl } from 'utils/consumptionModes';
import { isEmailValid } from 'utils/email';

export const useForgottenPasswordVM = () => {
    const { navigateBackToLogin } = useCustomNavigate();
    const { state } = useLocation();

    const brand = useSelector(getBrand);
    const restaurantId = useAppSelector(getRestaurantId);
    const consumptionMode = convertModeToUrl(useAppSelector(getCurrentConsumptionModeType));

    const brandId = brand?.brandId;
    const brandHash = brand?.brandHash;

    const [email, setEmail] = useState<string>(state?.email || '');

    const [postForgottenPassword, { isLoading, isSuccess, isError }] =
        usePostForgottenPasswordMutation();

    const handleUpdateEmail = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        },
        [setEmail],
    );

    const handleGoBack = () => {
        navigateBackToLogin();
    };

    const handleSubmit = useCallback(() => {
        if (!brandId || !email || isLoading) return;
        postForgottenPassword({
            brandId,
            email,
            redirectBaseUrl: `${window.location.origin}/${brandHash}/reset-password`,
            restaurantId,
            consumptionMode,
        });
    }, [
        brandId,
        email,
        isLoading,
        postForgottenPassword,
        brandHash,
        consumptionMode,
        restaurantId,
    ]);

    const areInputsValid = useCallback(() => isEmailValid(email), [email]);

    const checkEmailFormat = useCallback(() => !email || isEmailValid(email), [email]);

    return {
        email,
        handleUpdateEmail,
        isEmailValid: checkEmailFormat(),
        areInputsValid: areInputsValid(),
        handleSubmit,
        handleGoBack,
        isLoading,
        isSuccess,
        isError,
    };
};
