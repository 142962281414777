import { useRedirectToHome } from 'hooks/useRedirectHome/useRedirectHome';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { getIsFoodCourt, getRestaurantId } from 'state/app';
import { getBrand, getRestaurant } from 'state/brand';
import { useAppSelector } from 'state/store';

export const useWithHeaderVM = () => {
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const brand = useAppSelector(getBrand);
    const currentRestaurantId = useAppSelector(getRestaurantId);
    const currentRestaurant = useAppSelector(getRestaurant(currentRestaurantId));

    const { redirectToHome } = useRedirectToHome();
    const { goPrevious } = useWorkflowStateMachine();

    return {
        isFoodCourt,
        redirectToHome,
        goPrevious,
        restaurantLogo: currentRestaurant?.imageUrl,
        logoAriaLabel: isFoodCourt ? brand?.name : currentRestaurant?.name,
    };
};
