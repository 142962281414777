import { createSlice, PayloadAction, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { MultiOrderConfirm } from 'services/multi-order/multi-order.type';
import { SingleOrderConfirm } from 'services/order/order.type';
import { RootState } from '../store';

export const requiredActionSliceName = 'requiredAction';

export type RequiredActionState = SingleOrderConfirm | MultiOrderConfirm | null;

const initialState: RequiredActionState = null;

export const requiredActionSlice = createSlice<
    RequiredActionState,
    SliceCaseReducers<RequiredActionState>,
    string,
    SliceSelectors<RequiredActionState>
>({
    name: requiredActionSliceName,
    initialState,
    reducers: {
        updateRequiredAction: (state, action: PayloadAction<RequiredActionState>) => action.payload,
    },
});

export const getRequiredAction = (state: RootState): RequiredActionState => state.requiredAction;

export const { updateRequiredAction } = requiredActionSlice.actions;
