import styled from '@emotion/styled';
import { Button, Text, Title } from 'components';

export const MargedTitle = styled(Title)`
    margin-bottom: ${({ theme }) => theme.base.spacing[1]};
`;

export const MargedText = styled(Text)`
    display: block;
    margin-bottom: ${({ theme }) => theme.base.spacing[4]};
    color: ${({ theme }) => theme.base.colors.secondary};
`;

export const InputContainer = styled.div`
    display: block;
    width: 100%;

    span:has(input) {
        width: 100% !important;
        flex: 1;
        input,
        input::placeholder {
            width: 100%;
        }
        .ri-eye-line::before,
        .ri-eye-off-line::before {
            color: ${({ theme }) => theme.feature.secondaryTextColor};
        }
    }
`;

export const MargedInputContainer = styled(InputContainer)<{ hasError: boolean }>`
    margin-bottom: ${({ theme, hasError }) => (hasError ? theme.base.spacing[2] : '0')};
`;

export const ButtonSubmit = styled(Button)`
    width: 100%;
    margin-top: ${({ theme }) => theme.base.spacing[3]};
`;

export const BottomButton = styled(Button)`
    width: 100%;
    margin-top: ${({ theme }) => theme.base.spacing[1]};
`;

export const ForgotPasswordContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.base.spacing[2]};
`;

export const ForgotPassword = styled(Text)`
    color: ${({ theme }) => theme.base.colors.primary};
    text-decoration: underline;
`;
