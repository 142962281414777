import { SvyPassword } from '@innovorder/savory';
import { Input, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { RoutesPath } from 'core/routes.types';
import { Link } from 'react-router-dom';
import { useLoginVM } from './useLoginVM';
import {
    BottomButton,
    ButtonSubmit,
    MargedInputContainer,
    ForgotPassword,
    ForgotPasswordContainer,
    InputContainer,
    MargedText,
    MargedTitle,
} from './Login.style';

const Login: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        email,
        handleUpdateEmail,
        password,
        handleUpdatePassword,
        isEmailValid,
        isPasswordValid,
        areInputsValid,
        submit,
        submitContinue,
        error,
        resetError,
        shouldDisplayContinueButton,
    } = useLoginVM();

    return (
        <>
            <MargedTitle text={'login_page.title'} type={4}></MargedTitle>
            <MargedText text={'login_page.content'} type={2} color="secondaryTextColor" />

            <MargedInputContainer hasError={!isEmailValid}>
                <Input
                    type="email"
                    value={email}
                    hasError={!isEmailValid}
                    onChange={handleUpdateEmail}
                    onFocus={resetError}
                    label={t('login_page.inputs.email.label')}
                    labelDetail="*"
                    placeholder="login_page.inputs.email.placeholder"
                    autoFocus
                    error={!isEmailValid ? t('login_page.inputs.email.bad_format') : undefined}
                    required
                    data-testid="email-input"
                />
            </MargedInputContainer>

            <InputContainer>
                <SvyPassword
                    value={password}
                    hasError={!isPasswordValid}
                    onChange={handleUpdatePassword}
                    onFocus={resetError}
                    label={t('login_page.inputs.password.label')}
                    labelDetail="*"
                    placeholder={t('login_page.inputs.password.placeholder')}
                    required
                    data-testid="password-input"
                />
            </InputContainer>

            <ForgotPasswordContainer data-testid="forgotten-password-container">
                <Link to={`../${RoutesPath.ForgottenPassword}`} state={{ email }}>
                    <ForgotPassword text={'forgotten_password'} color="ctaColor" type={3} />
                </Link>
            </ForgotPasswordContainer>

            {error && <Text type={3} color="errorColor" text={error}></Text>}

            <ButtonSubmit
                onClick={submit}
                isLoading={false}
                disabled={!areInputsValid}
                data-testid="login-btn"
                size="m"
            >
                {t('login_page.inputs.submit')}
            </ButtonSubmit>

            {shouldDisplayContinueButton && (
                <BottomButton
                    onClick={submitContinue}
                    isLoading={false}
                    data-testid="continue-btn"
                    size="m"
                    variant="text"
                >
                    {t('login_page.inputs.continue')}
                </BottomButton>
            )}
        </>
    );
};

export default Login;
