import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    getCurrentConsumptionModeType,
    getCurrentFamilyFilter,
    getRestaurantId,
    updateAppState,
} from 'state/app';
import { useAppDispatch, useAppSelector } from 'state/store';
import { getBrandCurrency, getConsumptionModes } from 'state/brand';
import { getRestaurantSchedules } from 'state/restaurantSchedules';
import { getOrdersTotalInclTaxWithDiscount, getOrdersTotalQuantity } from 'state/orders';

import {
    useGetAllRestaurantFamiliesQuery,
    useGetAllWeborderingRestaurantsQuery,
} from 'services/brand/brand.endpoints';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useGetConsumptionModes } from 'hooks/useGetConsumptionModes';
import { computePrice } from 'utils/price';
import { useConsumptionModeHandler } from 'hooks/useConsumptionModeHandler/useConsumptionModeHandler';
import { computeRestaurantFamilies, computeWeborderingRestaurants } from './Restaurants.utils';

export const useRestaurantsVM = () => {
    const { goCart, goNext } = useWorkflowStateMachine();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const { brandHash } = useParams();

    useGetConsumptionModes();

    const dispatch = useAppDispatch();
    const restaurantSchedules = useAppSelector(getRestaurantSchedules);
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const consumptionModeList = useAppSelector(getConsumptionModes);
    const currentRestaurantId = useAppSelector(getRestaurantId);
    const currency = useAppSelector(getBrandCurrency);
    const totalQuantity = useAppSelector(getOrdersTotalQuantity);
    const totalInclTaxWithDiscount = useAppSelector(getOrdersTotalInclTaxWithDiscount);
    const currentFamilyFilter = useAppSelector(getCurrentFamilyFilter);

    useConsumptionModeHandler();

    const [restaurantSelected, setRestaurantSelected] = useState(false);

    const {
        data: restaurants,
        isLoading: isRestaurantsLoading,
        isError,
    } = useGetAllWeborderingRestaurantsQuery(
        {
            brandHash: brandHash as string,
        },
        { skip: !brandHash },
    );

    const { data: families, isLoading: isFamiliesLoading } = useGetAllRestaurantFamiliesQuery(
        {
            brandHash: brandHash as string,
        },
        { skip: !brandHash },
    );

    const { restaurants: filteredRestaurants, subtitle } = useMemo(
        () =>
            computeWeborderingRestaurants({
                t,
                restaurantSchedules,
                restaurants,
                currentFamilyFilter,
                consumptionMode: currentConsumptionModeType,
                consumptionModeList,
            }),
        [
            t,
            restaurantSchedules,
            restaurants,
            currentFamilyFilter,
            currentConsumptionModeType,
            consumptionModeList,
        ],
    );

    const emptinessSubtitle = subtitle ?? 'restaurants.subtitle.wrong_schedule';

    const handleSelectRestaurant = (restaurantId: number) => {
        const restaurant = filteredRestaurants.find((r) => r.restaurantId === restaurantId);
        if (restaurant) {
            dispatch(
                updateAppState({
                    restaurantId: restaurant.restaurantId,
                    restaurantName: restaurant.name,
                    restaurantTags: restaurant.restaurantTags,
                    currentMenuId: restaurant.menus[0].menuId,
                    currentFamilyFilter: null,
                }),
            );
            setRestaurantSelected(true);
        }
    };

    const handleSelectFamily = useCallback(
        (brandRestaurantFamilyId: number) => {
            const newFilter =
                currentFamilyFilter === brandRestaurantFamilyId ? null : brandRestaurantFamilyId;

            dispatch(
                updateAppState({
                    currentFamilyFilter: newFilter,
                }),
            );
        },
        [currentFamilyFilter, dispatch],
    );

    const handleGoCart = () => {
        dispatch(
            updateAppState({
                currentFamilyFilter: null,
            }),
        );

        goCart();
    };

    useEffect(() => {
        if (restaurantSelected && currentRestaurantId) {
            setRestaurantSelected(false);

            goNext();
        }
    }, [restaurantSelected, currentRestaurantId, goNext]);

    const computedFamilies = computeRestaurantFamilies({ families });

    const shouldDisplayCart = totalQuantity > 0;
    const shouldDisplayFamilies =
        (filteredRestaurants.length > 0 || currentFamilyFilter !== null) &&
        computedFamilies.length > 0;

    const amountWithCurrency = currency
        ? computePrice(i18n, totalInclTaxWithDiscount, currency)
        : '';

    const buttonText = t('button_show_basket', {
        amount: amountWithCurrency,
    });

    return {
        restaurants: filteredRestaurants,
        families: computedFamilies,
        isLoading: isRestaurantsLoading || isFamiliesLoading,
        isError,
        shouldDisplayCart,
        shouldDisplayFamilies,
        buttonText,
        currentFamilyFilter,
        emptinessSubtitle,
        handleSelectRestaurant,
        handleSelectFamily,
        handleGoCart,
    };
};
