import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'state/store';
import { getCustomerName } from 'state/customer/customer.slice';
import { useConfirmPayment } from '../../../../hooks/useConfirmPayment/useConfirmPayment';

export const usePaygreenVM = () => {
    const customerName = useAppSelector(getCustomerName);

    const [firstName, setFirstName] = useState<string | undefined>(customerName ?? undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const { handlePaymentSubmit } = useConfirmPayment('paygreen');
    const { t } = useTranslation();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!firstName || !lastName || !email) return;

        setLoading(true);

        handlePaymentSubmit({
            paygreenMetadata: {
                firstName,
                lastName,
                email,
            },
        });
    };

    const handleUpdateFirstName = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setFirstName(event.target.value);
        },
        [setFirstName],
    );

    const handleUpdateLastName = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setLastName(event.target.value);
        },
        [setLastName],
    );

    const handleUpdateEmail = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        },
        [setEmail],
    );

    const error = !firstName || !lastName || !email;

    return {
        buttonText: t('confirm_and_pay'),
        customerName,
        error,
        loading,
        handleUpdateFirstName,
        handleUpdateLastName,
        handleUpdateEmail,
        handleSubmit,
        firstName,
        lastName,
        email,
        setFirstName,
    };
};
