import { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { useAppSelector } from 'state/store';
import { getOrder, getOrders, getOrdersTotalPriceBreakdown } from 'state/orders';
import { AppState, getDebouncingQuantity, getIsFoodCourt, getRestaurantId } from 'state/app';
import { getAuthenticationType, getBrandCurrency } from 'state/brand';
import {
    Discount,
    OrderToVerify,
    OrderErrorPayload,
    PriceBreakdown,
    EntranceFee,
    Grant,
} from 'services/order/order.type';
import { UpdateProductQuantityInCartPayload, useCartHandler } from 'hooks/useCartHandler';
import { extractErrorsCode, extractFirstErrorMessage } from 'utils/errors';
import { useUpdateOrder } from 'hooks/useUpdateOrder/useUpdateOrder';
import { CartItemDisplayerProps } from 'components/CartItemDisplayer/CartItemDisplayer';
import { computeRestaurantOrdersForCart } from 'utils/order';
import { VerifiedOrder } from '@innovorder/order_detail';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useTranslation } from 'react-i18next';
import { useCustomFlush } from 'hooks/useCustomFlush';
import { getAccessToken } from 'state/customer/customer.slice';
import { useTableNumber } from 'hooks/useTableNumber/useTableNumber';
import {
    computeEntranceFeesFromOrderRecap,
    computeGrantsFromOrderRecap,
    computePromoCodeDiscountFromOrderRecap,
    computeShouldFlushCart,
} from './Cart.utils';

type CommonFnType = {
    goNext: () => void;
    goBack: () => void;
    handleGoToLogin: () => void;
    shouldDisplayLoginButton: boolean;
    discounts: Discount[];
    currency: string;
    buttonText: { login: string; pay: string };
    isFoodCourt: boolean;
    restaurantOrders: CartItemDisplayerProps['restaurantOrders'];
    tableNumber: number | null;
};

type QuantityManagementType = {
    handleUpdateProductQuantityInCart: (payload: UpdateProductQuantityInCartPayload) => void;
    isCartOperationInProgress: boolean;
    isLoading: boolean;
    debouncingQuantity: AppState['debouncingQuantity'];
};

type CommentModalType = {
    comment: string | undefined;
    handleCommentChange: (value: string) => void;
    isCommentModalOpen: boolean;
    hideCommentModal: () => void;
    showCommentModal: () => void;
    submitCommentModal: () => void;
    isLoading: boolean;
};

type PromocodeModalType = {
    promocode: string | undefined;
    handlePromocodeChange: (event: ChangeEvent<HTMLInputElement>) => void;
    isPromocodeModalOpen: boolean;
    promocodeError: string;
    hidePromocodeModal: () => void;
    showPromocodeModal: () => void;
    submitPromocodeModal: () => void;
};

type CartVMReturnType = CommonFnType &
    PromocodeModalType &
    CommentModalType &
    QuantityManagementType &
    (
        | {
              isEmpty: true;
              orders: null;
              ordersTotalPriceBreakdown: undefined;
              entranceFees: EntranceFee[];
              grants: Grant[];
          }
        | {
              isEmpty: false;
              orders: Record<number, VerifiedOrder>;
              ordersTotalPriceBreakdown: PriceBreakdown;
              entranceFees: EntranceFee[];
              grants: Grant[];
          }
    );

export const useCartVM = (): CartVMReturnType => {
    const { t } = useTranslation();
    const { updateOrders } = useUpdateOrder();
    const { flushCart } = useCustomFlush();
    const { tableNumber } = useTableNumber();

    const restaurantId = useAppSelector(getRestaurantId);
    const orders = useAppSelector(getOrders);
    const ordersTotalPriceBreakdown = useAppSelector(getOrdersTotalPriceBreakdown);
    const order = useAppSelector(getOrder(restaurantId));
    const currency = useAppSelector(getBrandCurrency);
    const isFoodCourt = useAppSelector(getIsFoodCourt) ?? false;
    const debouncingQuantity = useAppSelector(getDebouncingQuantity);
    const accessToken = useAppSelector(getAccessToken);
    const authenticationType = useAppSelector(getAuthenticationType);

    const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
    const [isPromocodeModalOpen, setIsPromocodeModalOpen] = useState<boolean>(false);
    const [comment, setComment] = useState<string>(order?.comment || '');
    const [promocode, setPromocode] = useState<string>(order?.promocode || '');
    const [promocodeError, setPromocodeError] = useState<string>('');

    const { goPrevious, goNext, reset, goLogin } = useWorkflowStateMachine();

    const handleGoToLogin = () => {
        goLogin({
            redirectToState: 'cart',
        });
    };

    const hideCommentModal = useCallback(() => setIsCommentModalOpen(false), []);
    const showCommentModal = useCallback(() => setIsCommentModalOpen(true), []);
    const handleCommentChange = useCallback((value: string) => setComment(value), []);
    const hidePromocodeModal = useCallback(() => {
        setIsPromocodeModalOpen(false);
        if (promocodeError) {
            setPromocode('');
            setPromocodeError('');
        }
    }, [promocodeError]);
    const showPromocodeModal = useCallback(() => setIsPromocodeModalOpen(true), []);
    const handlePromocodeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => setPromocode(event.target.value),
        [],
    );

    const submitCommentModal = useCallback(async () => {
        if (order) {
            updateOrders({ [order.restaurantId]: { ...order, comment } });
        }
        hideCommentModal();
    }, [order, comment, updateOrders, hideCommentModal]);

    const goBack = useCallback(() => {
        if (restaurantId) {
            goPrevious();
        } else {
            reset();
        }
    }, [goPrevious, reset, restaurantId]);

    const {
        isCartOperationInProgress,
        setIsCartOperationInProgress,
        handleQuantityChange,
        handlePromocodeInCart,
        data: dataAfterCartUpdate,
        isLoading,
    } = useCartHandler();

    const submitPromocodeModal = useCallback(async () => {
        if (order) {
            const result = await handlePromocodeInCart({ order, promocode });

            if ((result as unknown as { data: OrderToVerify }).data) {
                setPromocodeError('');
                hidePromocodeModal();
            } else {
                const resultError = (result as unknown as { error: OrderErrorPayload }).error;
                const error = extractFirstErrorMessage(resultError);
                const errorCodes = extractErrorsCode(resultError);
                if (errorCodes[0] !== 'invalid_cart') setPromocodeError(error);
                else {
                    setPromocodeError('');
                    setPromocode('');
                    hidePromocodeModal();
                }
            }
        }
    }, [order, promocode, hidePromocodeModal, handlePromocodeInCart]);

    const commonFn = {
        goNext,
        goBack,
        handleGoToLogin,
        currency: currency ?? 'EUR',
        isFoodCourt,
        buttonText: { login: t('customer_login'), pay: t('go_to_payment') },
        restaurantOrders: computeRestaurantOrdersForCart({ orders, isFoodCourt }),
        shouldDisplayLoginButton: authenticationType !== 'Guest' && !accessToken,
    };

    const quantityManagement = {
        isLoading,
        isCartOperationInProgress,
        handleQuantityChange,
        debouncingQuantity,
        handleUpdateProductQuantityInCart: handleQuantityChange,
    };

    useEffect(() => {
        if (dataAfterCartUpdate) {
            setIsCartOperationInProgress(false);
            const shouldFlushCart = computeShouldFlushCart(dataAfterCartUpdate);

            if (shouldFlushCart) {
                flushCart();
            } else {
                updateOrders(dataAfterCartUpdate);
            }
        }
    }, [dataAfterCartUpdate, flushCart, updateOrders, setIsCartOperationInProgress]);

    const commentModal = {
        comment,
        handleCommentChange,
        isCommentModalOpen,
        isLoading,
        hideCommentModal,
        showCommentModal,
        submitCommentModal,
        tableNumber,
    };

    const promocodeModal = {
        promocode,
        handlePromocodeChange,
        isPromocodeModalOpen,
        isLoading,
        hidePromocodeModal,
        showPromocodeModal,
        submitPromocodeModal,
        promocodeError,
    };

    return orders
        ? {
              isEmpty: false,
              orders,
              ordersTotalPriceBreakdown,
              discounts: computePromoCodeDiscountFromOrderRecap(order?.recap),
              entranceFees: computeEntranceFeesFromOrderRecap(order?.recap),
              grants: computeGrantsFromOrderRecap(order?.recap),
              ...commonFn,
              ...quantityManagement,
              ...commentModal,
              ...promocodeModal,
          }
        : {
              isEmpty: true,
              orders: null,
              ordersTotalPriceBreakdown: undefined,
              discounts: [],
              entranceFees: [],
              grants: [],
              ...commonFn,
              ...quantityManagement,
              ...commentModal,
              ...promocodeModal,
          };
};
