import { useAppSelector } from 'state/store';
import { getBrand } from 'state/brand';
import { getOrders } from 'state/orders';

import { useFindCrossSellingEnabledWithProductsMutation } from 'services/crossSelling/crossSelling.endpoints';
import { CrossSellingPayload } from 'services/crossSelling/crossSelling.type';
import { ChannelId } from 'services/order/order.type';
import { computeRestaurantCarts } from 'utils/cart';
import { useCallback } from 'react';
import { getCurrentConsumptionModeType } from 'state/app';

const DEFAULT_LIMIT = 6;

export const useCrossSelling = () => {
    const brand = useAppSelector(getBrand);
    const orders = useAppSelector(getOrders);
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);

    const [findCrossSellingEnabledQuery, { data, isLoading, error, isSuccess }] =
        useFindCrossSellingEnabledWithProductsMutation();

    const findCrossSellingEnabled = useCallback(async () => {
        if (!brand || !currentConsumptionModeType) {
            return;
        }

        const restaurantCarts = computeRestaurantCarts({ orders, ordersToUpdate: [] });

        const body: CrossSellingPayload = {
            limit: DEFAULT_LIMIT,
            brandId: brand.brandId,
            channelId: ChannelId.WEB,
            consumptionMode: currentConsumptionModeType,
            restaurantCarts,
        };

        await findCrossSellingEnabledQuery(body);
    }, [findCrossSellingEnabledQuery, brand, orders, currentConsumptionModeType]);

    return {
        findCrossSellingEnabled,
        data,
        isLoading,
        isSuccess,
        error,
    };
};
