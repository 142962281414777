import { useInterpret } from '@xstate/react';
import { routes } from 'core/routes';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { NavigateActionList } from 'hooks/useWorkflowStateMachine/types';
import { useLayoutEffect, useMemo } from 'react';
import { useAppSelector } from 'state/store';
import { useLocation } from 'react-router-dom';
import {
    getAuthenticationType,
    getBrandOptionStatus,
    getIsOrderFollowUpEnabled,
} from 'state/brand';
import { getRouteAttributesFromPath } from 'utils/routes';
import { getRestaurantId, getNeedManualTableId } from 'state/app';
import { getAccessToken } from 'state/customer/customer.slice';
import {
    computeWorkflowStateMachine,
    resetHasRedirectOnLogin,
} from './useWorkflowStateMachine.utils';

export const useInitWorkflowStateMachine = () => {
    const { pathname } = useLocation();
    const isFoodCourt = useAppSelector(getBrandOptionStatus('FOOD_COURT_MODE'));
    const restaurantId = useAppSelector(getRestaurantId);
    const isOrderFollowUpEnabled = useAppSelector(getIsOrderFollowUpEnabled(restaurantId));
    const authenticationType = useAppSelector(getAuthenticationType);
    const accessToken = useAppSelector(getAccessToken);
    const needManualTableId = useAppSelector(getNeedManualTableId);

    const {
        navigateToState,
        navigateToHome,
        navigateToRestaurants,
        navigateToMenuHome,
        navigateToLogin,
        navigateToCart,
        navigateToCrossSelling,
        navigateToAdditionalInformations,
        navigateToChoosePayment,
        navigateToOrderConfirmation,
        navigateToOrderFollowUp,
    } = useCustomNavigate();

    const navigateActions: NavigateActionList = {
        navigateToState,
        navigateToHome,
        navigateToRestaurants,
        navigateToMenuHome,
        navigateToLogin,
        navigateToCart,
        navigateToCrossSelling,
        navigateToAdditionalInformations,
        navigateToChoosePayment,
        navigateToOrderConfirmation,
        navigateToOrderFollowUp,
    };

    const shouldDisplayAdditionalInformations = useMemo(
        () => needManualTableId,
        [needManualTableId],
    );

    const workflowService = useInterpret(
        computeWorkflowStateMachine({
            isFoodCourt,
            isOrderFollowUpEnabled,
            canAuthenticate: authenticationType !== 'Guest',
            hasToAuthenticate: authenticationType === 'EmailOnly',
        }),
        {
            actions: {
                ...navigateActions,
                resetHasRedirectOnLogin,
            },
        },
    );

    useLayoutEffect(() => {
        const matchedRoute = getRouteAttributesFromPath(pathname, routes);
        if (matchedRoute?.state) {
            workflowService.send({
                type: 'INIT',
                state: matchedRoute.state,
            });
        }
    }, [pathname, workflowService]);

    useLayoutEffect(() => {
        workflowService.send({ type: 'UPDATE_AUTH', isAuthenticated: !!accessToken });
    }, [accessToken, workflowService]);

    useLayoutEffect(() => {
        workflowService.send({
            type: 'UPDATE_ADDITIONAL_INFORMATIONS',
            shouldDisplayAdditionalInformations,
        });
    }, [shouldDisplayAdditionalInformations, workflowService]);

    return workflowService;
};
