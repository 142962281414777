import React from 'react';
import {
    Button,
    Input,
    Title,
    PageWithBottomBarContainer,
    HeaderTitle,
    WithHeader,
    BottomBar,
    Caption,
} from 'components';
import { useTranslation } from 'react-i18next';
import { CartStepper } from 'components/CartStepper';
import { SvyIcon } from '@innovorder/savory';
import { useAdditionalInformationsVM } from './useAdditionalInformationsVM';
import {
    AdditionalInformationsContentContainer,
    ExternalCustomerContainer,
    InputContainer,
    SpacedInputContainer,
} from './AdditionalInformations.style';

const AdditionalInformations: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const {
        buttonText,
        customerName,
        needManualTableId,
        tableNumber,
        handleUpdateCustomerName,
        handleUpdateTableNumber,
        goNext,
        CUSTOMER_NAME_LENGTH_MIN,
        shouldDisplayExternalCustomer,
        isAuthenticated,
    } = useAdditionalInformationsVM();

    return (
        <WithHeader isSticky={true} hasAccountButton>
            <CartStepper />
            <PageWithBottomBarContainer>
                <HeaderTitle>
                    <Title text={'additional_informations.title'} type={5} />
                </HeaderTitle>
                <AdditionalInformationsContentContainer>
                    {!isAuthenticated && (
                        <SpacedInputContainer>
                            <Input
                                onChange={handleUpdateCustomerName}
                                autoFocus={false}
                                placeholder={'additional_informations.name_input_placeholder'}
                                value={customerName || ''}
                                label={t('additional_informations.name')}
                                labelDetail={t('additional_informations.mandatory')}
                                helperText={t('additional_informations.name_description')}
                                data-testid="customer-name-input"
                            />
                        </SpacedInputContainer>
                    )}

                    {shouldDisplayExternalCustomer && (
                        <ExternalCustomerContainer>
                            <SvyIcon icon="information-line" />
                            <Caption text={'additional_informations.external_customer'} />
                        </ExternalCustomerContainer>
                    )}

                    {needManualTableId && (
                        <InputContainer smallTopSpacing={!!isAuthenticated}>
                            <Input
                                onChange={handleUpdateTableNumber}
                                autoFocus={false}
                                type="number"
                                placeholder={'additional_informations.table_input_placeholder'}
                                value={tableNumber ? `${tableNumber}` : ''}
                                label={t('additional_informations.table')}
                                data-testid="table-number-input"
                                required={false}
                            />
                        </InputContainer>
                    )}
                </AdditionalInformationsContentContainer>

                <BottomBar withPadding>
                    <Button
                        fullWidth
                        onClick={goNext}
                        data-testid="validate-btn"
                        size="l"
                        disabled={!customerName || customerName.length < CUSTOMER_NAME_LENGTH_MIN}
                    >
                        {buttonText}
                    </Button>
                </BottomBar>
            </PageWithBottomBarContainer>
        </WithHeader>
    );
};

export default AdditionalInformations;
