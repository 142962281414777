import { useAppSelector } from 'state/store';
import { useTranslation } from 'react-i18next';
import { getOrdersTotalInclTaxWithDiscount } from 'state/orders';
import { getBrandCurrency } from 'state/brand';
import { computePrice } from 'utils/price';

export const useOrderPrice = () => {
    const { i18n } = useTranslation();
    const currency = useAppSelector(getBrandCurrency);
    const totalInclTaxWithDiscount = useAppSelector(getOrdersTotalInclTaxWithDiscount);

    return {
        currency,
        amount: totalInclTaxWithDiscount,
        amountWithCurrency: currency ? computePrice(i18n, totalInclTaxWithDiscount, currency) : '',
    };
};
