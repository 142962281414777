import { Button, Title, Text, GrowingContainer, Callout, WithHeader, BottomBar } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductErrorModal from 'components/ProductErrorModal';
import { HeaderRowTitle } from 'components/WithHeader/WithHeader.style';
import CategoryDisplayer from './CategoryDisplayer';

import {
    ButtonContainer,
    MenuHomeContainer,
    RestaurantTag,
    RestaurantTagsContainer,
    StyledIcon,
} from './MenuHome.style';
import { useMenuHomeVM } from './useMenuHomeVM';
import MenuHomeSkeleton from './MenuHomeSkeleton';

const MenuHome: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        isError,
        isLoading,
        buttonText,
        rushDelay,
        shouldDisplayRushDelay,
        shouldDisplayNextOpening,
        shouldDisplayCart,
        nextOpeningI18n,
        isFoodCourt,
        restaurantName,
        restaurantTags,
        handleClickOnRushDelay,
        goNext,
        redirectToHome,
    } = useMenuHomeVM();

    if (isError) {
        return <h1>{t('loading_menu_error')}...</h1>;
    }

    return (
        <WithHeader
            onClickBack={redirectToHome}
            disabledClickBack={!isFoodCourt}
            disabledClickLogo={!isFoodCourt}
            hasAccountButton
        >
            {isLoading ? (
                <MenuHomeSkeleton isFoodCourt={isFoodCourt} />
            ) : (
                <MenuHomeContainer>
                    <GrowingContainer>
                        {isFoodCourt && (
                            <>
                                <HeaderRowTitle>
                                    <Title
                                        text={restaurantName || ''}
                                        noTranslation={true}
                                        type={3}
                                    />
                                </HeaderRowTitle>

                                {restaurantTags && (
                                    <RestaurantTagsContainer>
                                        {restaurantTags.map(({ value }) => (
                                            <RestaurantTag key={value}>
                                                <Text
                                                    text={value}
                                                    noTranslation
                                                    type={3}
                                                    weight="Bold"
                                                />
                                            </RestaurantTag>
                                        ))}
                                    </RestaurantTagsContainer>
                                )}
                            </>
                        )}
                        <CategoryDisplayer />
                    </GrowingContainer>

                    <BottomBar>
                        {shouldDisplayNextOpening && (
                            <Callout>
                                <StyledIcon icon="time-line" data-testid="time-line" />
                                <Text type={3} weight="Bold" noTranslation text={nextOpeningI18n} />
                            </Callout>
                        )}
                        {shouldDisplayRushDelay && (
                            <Callout onClick={handleClickOnRushDelay}>
                                <StyledIcon icon="hourglass-line" data-testid="hourglass-line" />
                                <Text
                                    text={'rush_delay'}
                                    values={{ rushDelay }}
                                    type={3}
                                    weight="Bold"
                                />
                            </Callout>
                        )}
                        {shouldDisplayCart && (
                            <ButtonContainer>
                                <Button fullWidth onClick={goNext} size="l">
                                    {buttonText}
                                </Button>
                            </ButtonContainer>
                        )}
                    </BottomBar>
                    <ProductErrorModal />
                </MenuHomeContainer>
            )}
        </WithHeader>
    );
};

export default MenuHome;
