import { formatOrderDate } from 'pages/Order/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OmnichannelOrder, ChannelId } from 'services/order/order.type';
import { computePrice } from 'utils/price';
import { Text } from 'components';
import {
    ActionContainer,
    OrderDetailsContainer,
    OrderPrice,
    StyledDate,
    StyledOrderListItem,
    StyledSvyIcon,
    UnavailableDetails,
} from './OrderItem.style';

export const OrderItem: React.FunctionComponent<{ order: OmnichannelOrder }> = ({ order }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const isWebOrder = useMemo(() => {
        return order.channelId === ChannelId.WEB.toString();
    }, [order]);

    const linkProps = useMemo(() => {
        if (isWebOrder) return { role: 'link', onClick: () => navigate(order.omnichannelOrderId) };
        return {};
    }, [isWebOrder, order, navigate]);

    return (
        <li>
            <StyledOrderListItem {...linkProps}>
                <OrderDetailsContainer>
                    <Text
                        text={order.restaurant.name}
                        noTranslation
                        type={2}
                        weight="Bold"
                        data-testid="restaurant-name"
                    />
                    <br />
                    <StyledDate
                        noTranslation
                        text={formatOrderDate(i18n, order.startDate)}
                        type={3}
                        data-testid="order-date"
                    />
                    <OrderPrice
                        label={computePrice(
                            i18n,
                            order.totalPriceDiscountedWithTaxIncluded,
                            order.currency,
                        )}
                        data-testid="table-number"
                        color="primary"
                    />
                </OrderDetailsContainer>
                <ActionContainer>
                    {isWebOrder ? (
                        <StyledSvyIcon
                            icon="arrow-right-s-line"
                            data-testid="go-to-order-details"
                        />
                    ) : (
                        <UnavailableDetails
                            label={t('orders_history.details_unavailable')}
                            color="neutral"
                            data-testid="unavailable-details"
                        />
                    )}
                </ActionContainer>
            </StyledOrderListItem>
        </li>
    );
};
