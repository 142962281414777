import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export { innovorderApi } from './providers/innovorder';

/**
 * Happens when reloading during a call
 * cf documentation : https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
 */
export function isFetchError(error: unknown): error is FetchBaseQueryError {
    return (
        typeof error === 'object' &&
        error != null &&
        'data' in error &&
        'status' in error &&
        error.status === 'FETCH_ERROR'
    );
}
