import styled from '@emotion/styled';
import { SvyIcon } from '@innovorder/savory';
import { Button, Text } from 'components';

export const WaitingText = styled(Text)`
    text-align: center;
    padding: ${({ theme }) => theme.base.spacing[3]} ${({ theme }) => theme.base.spacing[5]};
`;

export const AccountContainer = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
`;

export const StyledList = styled.ul`
    list-style: none;
    width: 100%;
`;

export const StyledItem = styled.div`
    padding: ${({ theme }) => theme.base.spacing[2]};
    margin: ${({ theme }) => `${theme.base.spacing[2]} ${theme.base.spacing[3]}`};
    display: flex;
    align-items: center;
`;

export const IconContainer = styled.div`
    background-color: ${({ theme }) => theme.base.colors.neutral[100]};

    font-size: 24px;

    border-radius: 50%;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: ${({ theme }) => theme.base.spacing[2]};
`;

export const Filler = styled.div`
    flex: 1;
`;

export const Separator = styled.div`
    height: calc(${({ theme }) => theme.base.spacing[1]} / 2);
    width: 90%;
    margin: 0 auto;
    background-color: ${({ theme }): string => theme.base.colors.neutral[50]};
    border-radius: ${({ theme }): string => theme.base.radius.default};
`;

export const EwalletContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.base.spacing[3]};
    padding-top: ${({ theme }) => theme.base.spacing[1]};
    background-color: ${({ theme }) => theme.base.colors.secondary[100]};
    button {
        margin-top: ${({ theme }) => theme.base.spacing[3]};
    }
`;

export const EwalletReloaderButton = styled(Button)`
    margin: ${({ theme }) => theme.base.spacing[1]} 0px;
`;

export const StyledButton = styled(Button)`
    margin: ${({ theme }) => theme.base.spacing[2]};
    margin-top: ${({ theme }) => theme.base.spacing[1]};
`;

export const SizedSvyIcon = styled(SvyIcon)`
    font-size: 24px;
    font-weight: 100;
`;
