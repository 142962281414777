import { getIsFoodCourt } from 'state/app';
import { getConsumptionModeList } from 'state/consumptionModeList';
import { getOrders } from 'state/orders';
import { useAppSelector } from 'state/store';
import { PaymentMethodCode } from 'services/consumptionMode/consumptionMode.type';

export const usePostPayment = () => {
    const consumptionModeList = useAppSelector(getConsumptionModeList);
    const currentOrders = useAppSelector(getOrders);
    const isFoodCourt = useAppSelector(getIsFoodCourt);

    if (!currentOrders || !consumptionModeList) {
        return {
            canPayWithCash: false,
            canPayWithCreditCard: false,
        };
    }

    /**
     * No payment later for foodcourt for now
     */
    if (isFoodCourt) {
        return {
            canPayWithCash: false,
            canPayWithCreditCard: false,
        };
    }

    return Object.values(currentOrders).reduce(
        (result, currentOrder) => {
            const currentConsumptionMode = currentOrder.consumptionMode;
            const orderConsumptionMode = consumptionModeList[currentOrder.restaurantId];

            // TODO: To fix multi-order-paiements hydrate consumptionModeList for allRestaurants at once
            if (!orderConsumptionMode) {
                return {
                    canPayWithCash: false,
                    canPayWithCreditCard: false,
                };
            }

            const selectedConsumptionMode = orderConsumptionMode.find(
                ({ type }) => type === currentConsumptionMode,
            );

            const hasCashActive = !!selectedConsumptionMode?.paymentMethods.find(
                ({ code, active }) => active && code === PaymentMethodCode.Cash,
            );

            const hasCreditCardActive = !!selectedConsumptionMode?.paymentMethods.find(
                ({ code, active }) => active && code === PaymentMethodCode.CreditCard,
            );

            return {
                canPayWithCash: result.canPayWithCash && hasCashActive,
                canPayWithCreditCard: result.canPayWithCreditCard && hasCreditCardActive,
            };
        },
        {
            canPayWithCash: true,
            canPayWithCreditCard: true,
        },
    );
};
