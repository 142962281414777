import { ConsumptionModeType } from '@innovorder/order_detail';
import { useCallback, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'state/store';
import {
    getCurrentConsumptionModeType,
    getIsFoodCourt,
    getRestaurantId,
    updateAppState,
} from 'state/app';
import { HANDLED_CONSUMPTION_MODES } from 'hooks/useGetConsumptionModes';
import { getConsumptionModeList } from 'state/consumptionModeList';
import { useCustomFlush } from 'hooks/useCustomFlush';

const sitIn = ConsumptionModeType.MODE_SIT_IN;
const takeAway = ConsumptionModeType.MODE_TAKE_AWAY;

export const useConsumptionPickerVM = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const mode = useAppSelector(getCurrentConsumptionModeType);
    const restaurantId = useAppSelector(getRestaurantId);
    const consumptionModeList = useAppSelector(getConsumptionModeList);
    const isFoodCourt = useAppSelector(getIsFoodCourt);

    const { flushCart } = useCustomFlush();

    const switchMode = useCallback(() => {
        dispatch(
            updateAppState({
                currentConsumptionModeType: mode === sitIn ? takeAway : sitIn,
            }),
        );
    }, [mode, dispatch]);

    const handleOnClick = (pickedMode: ConsumptionModeType) => {
        if (pickedMode !== mode) setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleActionModal = () => {
        setIsOpen(false);
        flushCart();
        switchMode();
    };

    const shouldDisplayPicker = useMemo(() => {
        if (isFoodCourt) return true;
        if (!restaurantId) return false;

        const restaurantConsumptionModes = consumptionModeList?.[restaurantId];

        if (restaurantId && restaurantConsumptionModes) {
            const handledConsumptionModes = restaurantConsumptionModes.filter((consumptionMode) =>
                HANDLED_CONSUMPTION_MODES.includes(consumptionMode.type),
            );

            if (handledConsumptionModes.length >= 2) return true;
        }

        return false;
    }, [consumptionModeList, isFoodCourt, restaurantId]);

    const modal = {
        isOpen,
        handleCloseModal,
        handleActionModal,
    };

    return {
        mode,
        modal,
        handleOnClick,
        shouldDisplayPicker,
    };
};
