import { createSlice, PayloadAction, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const ordersStatusAcknowledgedSliceName = 'ordersStatusAcknowledged';

export type OrdersStatusAcknowledgedState = Record<string, boolean>;

const initialState: OrdersStatusAcknowledgedState = {};

export const ordersStatusAcknowledgedSlice = createSlice<
    OrdersStatusAcknowledgedState,
    SliceCaseReducers<OrdersStatusAcknowledgedState>,
    string,
    SliceSelectors<OrdersStatusAcknowledgedState>
>({
    name: ordersStatusAcknowledgedSliceName,
    initialState,
    reducers: {
        updateOrdersStatusAcknowledged: (
            state,
            action: PayloadAction<OrdersStatusAcknowledgedState>,
        ) => ({
            ...state,
            ...action.payload,
        }),
    },
});

export const getOrderStatusAcknowledged = (state: RootState): OrdersStatusAcknowledgedState =>
    state.ordersStatusAcknowledged;

export const { updateOrdersStatusAcknowledged } = ordersStatusAcknowledgedSlice.actions;
